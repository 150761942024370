import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DataMode, Instance, PagedResp } from '../../api/types';
import { FnaListRes, FnaSearch } from '../fna-types';
import { FnaService } from '../fna.service';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FnaDetailComponent } from '../fna-detail/fna-detail.component';
import { FnaClientComponent } from '../fna-client/fna-client.component';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {Observable} from 'rxjs/internal/Observable';
import {ContactService} from '../../contact/contact.service';

@Component({
  selector: 'app-fna-list',
  templateUrl: './fna-list.component.html',
  styleUrls: ['./fna-list.component.less']
})
export class FnaListComponent implements OnInit {

  loading = false;

  spinning = false;

  fnas: PagedResp<FnaListRes>;

  search: FnaSearch = new FnaSearch();

  filterPopoverVisible = false;

  deleting = false;
  accountNameOptions: PropertySelectOption[] = [];

  @Input()
  mode = DataMode.OWN;
  instance: Instance = new Instance();

  constructor(
    private fnaService: FnaService,
    private router: Router,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private contactService: ContactService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.instance = this.fnaService.mockInstance;
    this.fnaService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.fnas = data;
        },
        error => {
          this.loading = false;
        });

    this.getAccountList();
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.fnaService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.fnas = data;
        },
        error => {
          this.loading = false;
        });
  }

  onPageSizeChange(pageSize: number): void {
    this.search.pageSize = pageSize;
    this.loading = true;
    this.fnaService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.fnas = data;
        },
        error => {
          this.loading = false;
        });
  }


  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.fnaService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.fnas = data;
        },
        error => {
          this.loading = false;
        });
  }


  onDetail(id: number): void {
    this.spinning = true;
    this.fnaService.info(id)
      .subscribe(
        fna => {
          this.spinning = false;
          const drawerRef = this.drawerService.create<FnaDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: FnaDetailComponent,
            nzContentParams: {
              fna,
              mode: this.mode
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
            component.fnaReused.subscribe(
              fnaDraft => {
                this.onDetail(fnaDraft.id);
              }
            );
          });

          drawerRef.afterClose.subscribe(data => {
            this.onReload(false);
          });
        },
        error => {
          this.spinning = false;

        });

  }

  onResetSearch(): void {
    this.search = new FnaSearch();
    this.onReload();
  }

  onFnaCreate(): void {
    this.spinning = false;
    const drawerRef = this.drawerService.create<FnaClientComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaClientComponent,
      nzContentParams: {
        isCreate: true
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.fnaCreated.subscribe(
        fnaDraft => {
          drawerRef.close();
          this.onDetail(fnaDraft.id);
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onDelete(fna: FnaListRes, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: fna,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, fna: FnaListRes): void {
    this.deleting = true;
    this.fnaService.delete(fna.id)
      .subscribe(
        data => {
          this.deleting = false;
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.onSearch(this.search.pageNum);
          ref.close();
        },
        error => {
          this.deleting = false;
          ref.close();
        });
  }

  getAccountList(): void {
    let observable: Observable<any>;
    switch (this.mode) {
      case 'SUBORDINATE':
        observable = this.contactService.getSubList();
        break;
      case 'SALESGROUP':
        observable = this.contactService.getTeamList();
        break;
    }
    observable?.subscribe(
      data => {
        console.log(data);
        if (data.length) {
          data.map(item => {
            this.accountNameOptions.push(new PropertySelectOption(item.nickName + ' — ' + item.accountName, item.accountName));
          });
        }
      },
      error => {
      }
    );
  }

}
