import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NewFnaService } from '../new-fna.service';
import { NewFnaList, NewFnaSearch } from '../new-fna-types';
import {DataMode, Instance, PagedResp} from '../../api/types';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NewFnaCreateComponent } from '../new-fna-create/new-fna-create.component';
import { FnaDetailComponent } from '../../fna/fna-detail/fna-detail.component';
import { Router } from '@angular/router';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { formatDate } from '@angular/common';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';

@Component({
  selector: 'app-new-fna-list',
  templateUrl: './new-fna-list.component.html',
  styleUrls: ['./new-fna-list.component.less']
})
export class NewFnaListComponent implements OnInit {

  loading = false;
  spinning = false;
  search: NewFnaSearch = new NewFnaSearch();
  fnaData: PagedResp<NewFnaList>;
  filterPopoverVisible = false;
  deleting = false;
  @Input()
  mode = DataMode.OWN;
  workbench: Workbench;
  dates: number[];
  instance: Instance;

  constructor(private newFnaService: NewFnaService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private metadataService: MetadataService,
              private translate: TranslateService,
              private router: Router,
              private toastr: ToastrService,
              private workbenchService: WorkbenchService
  ) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.loading = true;
    this.newFnaService.list(this.search, this.mode)
      .subscribe(list => {
          console.log(list);
          this.fnaData = JSON.parse(JSON.stringify(list));
          this.loading = false;
      },
      error => {
        this.loading = false;
      });

    this.instance = this.workbenchService.mockInstance;
  }


  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.newFnaService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.fnaData = data;
        },
        error => {
          this.loading = false;
        });
  }

  onPageSizeChange(pageSize: number): void {
    this.search.pageSize = pageSize;
    this.loading = true;
    this.newFnaService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.fnaData = data;
        },
        error => {
          this.loading = false;
        });
  }


  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.newFnaService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.fnaData = data;
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new NewFnaSearch();
    this.dates = null;
    this.filterPopoverVisible = false;
    this.onReload();
  }

  onForms(): void {
    if (this.workbench.fnaVersion === 'CMGHK_FNA_02') {
      this.router.navigate(['/user/new-fna-template'], { queryParams: { viewForm: true } }).then();
    } else if (this.workbench.fnaVersion === 'ZUU_FNA_01') {
      this.router.navigate(['/user/zuu-fna-template'], { queryParams: { viewForm: true } }).then();
    } else if (this.workbench.fnaVersion === 'PIBA_FNA_01') {
      this.router.navigate(['/user/new-fna-piba'], { queryParams: { viewForm: true } }).then();
    }
  }

  onFnaCreate(): void {
    if (this.workbench.fnaVersion === 'CMGHK_FNA_02') {
      localStorage.setItem('NneFnaTabs', this.mode);
      this.router.navigate(['/user/new-fna-template']).then();
    } else if (this.workbench.fnaVersion === 'ZUU_FNA_01') {
      localStorage.setItem('NneFnaTabs', this.mode);
      this.router.navigate(['/user/zuu-fna-template']).then();
    } else if (this.workbench.fnaVersion === 'PIBA_FNA_01') {
      localStorage.setItem('NneFnaTabs', this.mode);
      this.router.navigate(['/user/new-fna-piba']).then();
    } else {
      const drawerRef = this.drawerService.create<NewFnaCreateComponent, { value: any }, string>({
        nzWidth: 800,
        nzMaskClosable: true,
        nzContent: NewFnaCreateComponent,
        nzContentParams: {
          mode: this.mode
        },
      });

      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.newFnaReused.subscribe(
          fnaDraft => {
            drawerRef.close();
            this.onDetail(fnaDraft);
          }
        );
      });

      drawerRef.afterClose.subscribe(data => {
        this.onReload(true);
      });
    }
  }

  onDetail(info): void {
    if (info.version && info.version === 'CMGHK_FNA_02') {
      localStorage.setItem('NneFnaTabs', this.mode);
      this.router.navigate(['/user/new-fna-view'], { queryParams: { id: info.id, mode: this.mode } }).then();
      return;
    } else if (info.version && info.version === 'ZUU_FNA_01') {
      localStorage.setItem('NneFnaTabs', this.mode);
      this.router.navigate(['/user/zuu-fna-view'], { queryParams: { id: info.id, mode: this.mode } }).then();
      return;
    } else if (this.workbench.fnaVersion === 'PIBA_FNA_01') {
      localStorage.setItem('NneFnaTabs', this.mode);
      this.router.navigate(['/user/piba-fna-view'], { queryParams: { id: info.id, mode: this.mode } }).then();
    }else {
      this.spinning = true;
      this.newFnaService.info(info.id)
        .subscribe(
          fna => {
            this.spinning = false;
            const drawerRef = this.drawerService.create<NewFnaCreateComponent, { value: any }, string>({
              nzWidth: 800,
              nzMaskClosable: true,
              nzContent: NewFnaCreateComponent,
              nzContentParams: {
                detailInfo: fna,
                id: fna.id,
                mode: this.mode
              },
            });

            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
              component.drawerRef = drawerRef;
              component.newFnaReused.subscribe(
                fnaDraft => {
                  this.onDetail(fnaDraft);
                }
              );
            });

            drawerRef.afterClose.subscribe(data => {
              this.onReload(false);
            });
          },
          error => {
            this.spinning = false;

          });
    }
  }

  onDelete(fna: NewFnaList, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: fna,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, fna: NewFnaList): void {
    this.deleting = true;
    this.newFnaService.deleteFna(fna.id)
      .subscribe(
        data => {
          this.deleting = false;
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.onSearch(this.search.pageNum);
          ref.close();
        },
        error => {
          this.deleting = false;
          ref.close();
        });
  }

  clientNameChange(value): void{
    this.search.orderColumn = 'customerNameEn';
    this.search.orderAspect = value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : null;
    this.onReload();
  }

  createTimeChange(value): void{
    this.search.orderColumn = 'createTime';
    this.search.orderAspect = value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : null;
    this.onReload();
  }

  statusChange(value): void{
    this.search.orderColumn = 'status';
    this.search.orderAspect = value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : null;
    this.onReload();
  }

  onInspectDateChange(time: number[]): void {
    if (time) {
      this.dates = time;
      this.search.createDateBegin = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.search.createDateEnd = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    }
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        isBasic: true
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.productCode = product.productCode;
            this.search.productName = product.productName;
            drawerRef.close();
            this.filterPopoverVisible = true;
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  getChartHeight(): string {
    const windowHeight = window.innerHeight;
    const minHeight = windowHeight - 410;
    return minHeight + 'px';
  }
}
