<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 30px">
    {{'Search' | translate}}
  </div>

  <app-property-input [displayMode]="'vertical'"
                      [title]="'UnderwritingNumber' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('UnderwritingNumber' | translate)"
                      [(value)]="search.likeUnderwritingNo"></app-property-input>

  <app-property-input [displayMode]="'vertical'"
                      [title]="'PolicyNo' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('PolicyNo' | translate)"
                      [(value)]="search.likePolicyNo"></app-property-input>

  <app-property-input [displayMode]="'vertical'"
                      [title]="'ClientName' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                      [(value)]="search.customerName"></app-property-input>

  <div *ngIf="mode !== 'OWN' && mode !== 'FOLLOWUP'" class="referee-select">
    <div style="color: #999999;font-size: 14px;">{{'Referee' | translate}}</div>
    <nz-select nzShowSearch nzAllowClear style="width: 100%;"
               [nzBorderless]="true" [nzDropdownClassName]="'referee-select-dropdown'"
               [nzPlaceHolder]="('PleaseSelect' | translate) + ('Referee' | translate)"
               [(ngModel)]="search.accountName"
               [nzFilterOption]="filterOption">
      <nz-option nzCustomContent *ngFor="let option of accountNameOptions"
                 [nzLabel]="option.name" [nzValue]="option.accountName">
        <nz-avatar [nzText]="option.name?.trim().slice(0, 1)"
                   style="background-color: #0078d4" [nzSize]="25"
                   [nzSrc]="option.avatar"></nz-avatar>
        <span style="margin-left: 8px;">{{option.name?.trim()}}</span>
        <span style="color: #999999;font-size: 12px;">({{option.accountName}})</span>
      </nz-option>
    </nz-select>
  </div>

  <!-- 产品 -->
  <app-property-pick [displayMode]="'vertical'"
                     [title]="'InsuranceProduct' | translate"
                     appThrottleClick (throttleClick)="onProductSelection()"
                     [placeholder]="'ProductSelect' | translate"
                     [(value)]="search.product.productName"></app-property-pick>

  <app-property-datetime-range [displayMode]="'vertical'"
                               [title]="'PolicyDate' | translate"
                               [value]="Dates"
                               (valueChange)="onInspectDateChange($event)">
  </app-property-datetime-range>


  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSearch()">
          <em nz-icon nzType="search" nzTheme="outline"></em>
          <span>{{'Search' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
