import { Component, Input, OnInit } from '@angular/core';
import {
  DataDesensitization,
  DataMode, Instance, MessageSubType, MessageType,
  PagedResp,
  PendingReply,
  Underwriting,
  UnderWritingListResp,
  UploadTokenReq,
  UploadType
} from '../../api/types';
import { MetadataService } from '../../shared/service/metadata.service';
import { EventService } from '../../shared/service/event.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { UnderwritingService } from '../underwriting.service';
import { UnderwritingSearchComponent } from '../underwriting-search/underwriting-search.component';
import { UnderwritingDetailComponent } from '../underwriting-detail/underwriting-detail.component';
import { MessageDialogComponent } from '../../shared/component/message-dialog/message-dialog.component';
import { UnderwritingSearch } from '../underwriting-types';
import { MessageService } from '../../message/message.service';
import { ContactService } from '../../contact/contact.service';
import { AccountService } from '../../account/account.service';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-underwritings',
  templateUrl: './underwritings.component.html',
  styleUrls: ['./underwritings.component.less']
})
export class UnderwritingsComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;
  loading = false;
  uploadTokenReq: UploadTokenReq = new UploadTokenReq();
  spinning = false;
  dialogList: PendingReply[];
  underwritings: PagedResp<UnderWritingListResp>;
  instance: Instance;
  workbench: Workbench;

  search: UnderwritingSearch = new UnderwritingSearch();
  dataDesensitization: DataDesensitization = new DataDesensitization();
  constructor(private underwritingService: UnderwritingService,
              private metadataService: MetadataService,
              private eventService: EventService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private workbenchService: WorkbenchService,
              private router: Router,
              private accountService: AccountService,
              private drawerService: NzDrawerService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.instance = this.underwritingService.mockInstance;
    this.workbench = this.workbenchService.workbench;
    this.dataDesensitization = this.accountService.dataDesensitization;

    this.underwritingService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.underwritings = data;
        },
        error => {
          this.loading = false;
        });
    this.eventService.underwritingsReload
      .subscribe(
        data => {
          this.onReload(false);
        });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.underwritingService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.underwritings = data;
        },
        error => {
          this.loading = false;
        });
  }

  onPageSizeChange(pageSize: number): void {
    this.search.pageSize = pageSize;
    this.loading = true;
    this.underwritingService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.underwritings = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.underwritingService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.underwritings = data;
        },
        error => {
          this.loading = false;
        });
  }

  openSearch(): void {
    const drawerRef = this.drawerService.create<UnderwritingSearchComponent, { value: UnderwritingSearch }, string>({
      nzWidth: 800,
      nzContent: UnderwritingSearchComponent,
      nzContentParams: {
        search: this.search,
        mode: this.mode,
        Dates: this.search.signDateStart ? [this.search.signDateStart, this.search.signDateEnd] : null
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.underwritingSearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(1);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onUnderwritingDetail(underWritingNo: string): void {
    this.spinning = true;
    this.underwritingService.detail(underWritingNo)
      .subscribe(
        underwritingDetail => {

          this.spinning = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: this.mode,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.onReload(false);
            this.messageService.messageUnreadEvent.emit('');
          });
        },
        error => {
          this.spinning = false;
        });
  }

  onUnderwritingDialog(underWriting: UnderWritingListResp): void {
    this.sendMesseage(underWriting.underwritingNo);
    this.uploadTokenReq.uploadType = UploadType.UNDERWRITING;
    this.uploadTokenReq.middleName = underWriting.underwritingNo;
    this.translate.get('UnderwritingDialog').subscribe(
      title => {
        this.underwritingService.dialogList(underWriting.underwritingNo)
          .subscribe(
            data => {
              this.dialogList = data;
              const drawerRef = this.drawerService.create<MessageDialogComponent, { value: Underwriting }, string>({
                nzWidth: 800,
                nzContent: MessageDialogComponent,
                nzContentParams: {
                  Title: title,
                  list: this.dialogList,
                  uploadTokenReq: this.uploadTokenReq,
                  moduleId: underWriting.underwritingNo,
                  moduleType: 'Underwriting',
                  replyContent: underWriting.isLeavingMessage
                }
              });
              drawerRef.afterOpen.subscribe(() => {
                const component = drawerRef.getContentComponent();
                component.dialogReplied.subscribe(
                  params => {
                    this.underwritingService.dialogReply(params).subscribe(
                      () => {
                        this.underwritingService.dialogList(underWriting.underwritingNo).subscribe(
                          list => {
                            this.dialogList = list;
                            component.loadList(this.dialogList);

                          });
                      });
                  });
                component.confirmParam.subscribe(
                  confirmData => {
                    this.underwritingService.putDialogConfirm(confirmData).subscribe(
                      resFlag => {
                        if (resFlag) {
                          this.underwritingService.dialogList(underWriting.underwritingNo).subscribe(
                            list => {
                              component.loadList(list);
                            }
                          );
                        }
                      });
                  });
              });

              drawerRef.afterClose.subscribe(() => {
                this.onReload(false);
                this.messageService.messageUnreadEvent.emit('');
              });
            }
          );
      }
    );

  }

  sendMesseage(num): void{
    const params = {
      key: num,
      type: MessageType.UNDERWRITING,
      subType: MessageSubType.DIALOG,
    };
    this.messageService.putMessageReads(params).subscribe();
  }

  // 加**
  dataMasking(str): string {
    if (!str) {
      return ;
    }
    if (str.toString().length === 1) {
      return '*';
    }
    str = str.toString();
    let frontLen = 0;
    let endLen = 0;
    switch (str.length) {
      case 2:
      case 3:
        frontLen = 1;
        endLen = 0;
        break;
      case 4:
      case 5:
        frontLen = 1;
        endLen = 1;
        break;
      case 6:
      case 7:
      case 8:
        frontLen = 2;
        endLen = 1;
        break;
      default:
        frontLen = 3;
        endLen = 3;
        break;
    }
    const len = str.length - frontLen - endLen;
    let xing = '';
    for (let i = 0; i < len; i++) {
      xing += '*';
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
  }

  dynamicEncryption(keyName, val): string {
    let isShow = val;
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = this.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = this.dataMasking(val);
        }
      });
    }
    return isShow;
  }

  get isShowBusiness(): boolean {
    return !(this.instance.key === 'fone' || this.instance.isBaylightOrDev() || this.instance.key === 'quanbao');
  }
}
