import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BvSearch } from '../bv-types';
import { DataMode } from '../../api/types';
import { BookingSearch } from '../../booking/booking-types';
import { formatDate } from '@angular/common';
import { NzOptionComponent } from 'ng-zorro-antd/select';
import { Tool } from '../../shared/utils/tool';
import { Observable } from 'rxjs/internal/Observable';
import { SubAccountListRes } from '../../contact/contact-types';
import { MetadataService } from '../../shared/service/metadata.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ContactService } from '../../contact/contact.service';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';

@Component({
  selector: 'app-bv-search',
  templateUrl: './bv-search.component.html',
  styleUrls: ['./bv-search.component.less']
})
export class BvSearchComponent implements OnInit {
  @Input()
  search: BvSearch;
  @Input()
  mode = DataMode.OWN;
  Dates: any[];
  accountNameOptions: SubAccountListRes[] = [];

  @Output()
  bvSearch: EventEmitter<BvSearch> = new EventEmitter<BvSearch>();
  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private contactService: ContactService) { }

  ngOnInit(): void {
    this.getAccountList();
  }

  onReset(): void {
    this.search = new BvSearch();
    this.Dates = null;
    this.bvSearch.emit(this.search);
  }

  onSearch(): void {
    this.bvSearch.emit(this.search);
  }

  onInspectDateChange(time: number[]): void {
    // console.log(time);
    if (time) {
      this.Dates = time;
      this.search.policyDateStart = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.search.policyDateEnd = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    } else {
      this.search.policyDateStart = null;
      this.search.policyDateEnd = null;
    }
  }

  filterOption(input?: string, option?: NzOptionComponent): boolean {
    return Tool.filterOption(input, option);
  }

  getAccountList(): void {
    let observable: Observable<any>;
    switch (this.mode) {
      case 'SUBORDINATE':
        observable = this.contactService.getSubList();
        break;
      case 'SALESGROUP':
        observable = this.contactService.getTeamList();
        break;
    }
    observable?.subscribe(
      data => {
        console.log(data);
        if (data.length) {
          this.accountNameOptions = JSON.parse(JSON.stringify(data));
        }
      },
      error => {
      }
    );
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        copyProduct: this.search.product,
        isBasic: true
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.product = product;
            this.search.productCode = this.search.product.productCode;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

}
