<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!contractList">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="contractList" style="margin-top: 30px;">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="false"
                [nzLoading]="spinning"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzTableLayout]="'fixed'"
                [nzData]="contractList"
                [nzTotal]="contractList.length"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1580'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'SmartContractList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_filter.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ contractList.length }}</span>
          </div>
        </ng-template>
        <thead>
        <tr>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'ContractName' | translate">{{'ContractName' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span>{{'ContractType' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span>{{'CalculationBasis' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span>{{'Insurer' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span>{{'EffectiveDate' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span>{{'EndDate' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span>{{'Remark' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of contractList; let i = index" appThrottleClick (throttleClick)="onDetail(list); $event.stopPropagation();">
            <td><span>{{ list.name }}</span></td>
            <td><span>{{ list.type | metadataTranslate:'contractType' }}</span></td>
            <td><span>{{ list.baseOn | metadataTranslate:'trFactor'}}</span></td>
            <td nzEllipsis><span nz-tooltip [nzTooltipTitle]="list.companyNames?.join('，')">{{ list.companyNames?.join('，') }}</span></td>
            <td><span>{{ list.effectiveDate | date:'yyyy-MM-dd' }}</span></td>
            <td><span>{{ list.invalidDate | date:'yyyy-MM-dd' }}</span></td>
            <td nzEllipsis>
              <span nz-tooltip [nzTooltipTitle]="remarkTemplate"
                    [nzTooltipOverlayClassName]="'class-tip'">
                {{ list.remark }}
              </span>
              <ng-template #remarkTemplate>
                <div style="height: 200px;overflow-y: scroll;white-space: pre-wrap;">{{list.remark}}</div>
              </ng-template>
            </td>
            <td nzRight>
              <div class="functions">
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                   appThrottleClick (throttleClick)="onDetail(list); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_details.svg">
                </a>

                <a nz-button *ngIf="workbench.salesDownloadContract" nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'ExportExcel' | translate"
                   appThrottleClick (throttleClick)="onExportExcel(list); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_export_excel.svg">
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
  <app-water-mark [xSpace]="60" [ySpace]="90"></app-water-mark>
</div>

<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;position: relative;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <app-property-input [title]="'ContractName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ContractName' | translate)"
                        [(value)]="search.name">
    </app-property-input>
    <app-property-select [title]="'ContractType' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Type' | translate)"
                         [options]="typeOptions"
                         [allowClear]="false"
                         [(value)]="search.type"></app-property-select>
    <!-- 公司 -->
    <app-property-select [title]="'Insurer' | translate"
                         [mode]="'multiple'"
                         [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                         [(value)]="search.companyCodes"
                         [options]="companyOptions"></app-property-select>
    <app-property-datetime-range [title]="'EffectiveDate' | translate"
                                 [value]="EffectDates"
                                 (valueChange)="onInspectDateChange($event)"></app-property-datetime-range>
    <app-property-datetime-range [title]="'EndDate' | translate"
                                 [value]="deadline"
                                 (valueChange)="onSearchDateChange($event)"></app-property-datetime-range>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
<!--    <app-water-mark [xSpace]="20" [ySpace]="20"></app-water-mark>-->
  </div>
</ng-template>
