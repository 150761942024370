import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { en_US, NzI18nService, zh_CN, zh_HK, ja_JP, ko_KR } from 'ng-zorro-antd/i18n';
import {Locale} from '../api/types';
import {Constant} from '../base/constant';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  localeChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private i18n: NzI18nService) {
  }

  get locale(): Locale {
    return (localStorage.getItem(Constant.LOCAL_STORAGE_KEY_LOCALE) as Locale) ?? this.noLanguage();
  }

  noLanguage(): Locale {
    const supportedLanguages = ['en', 'zh-CN', 'zh-HK', 'ko', 'ja'];
    const browserLanguages = navigator.languages ? navigator.languages : [navigator.language];
    for (let i = 0; i < browserLanguages.length; i++) {
      const res = browserLanguages[i];
      let lang = Locale.hk;
      if (supportedLanguages.includes(res)) {
        switch (res) {
          case 'en':
            lang = Locale.en;
            break;
          case 'zh-CN':
            lang = Locale.cn;
            break;
          case 'zh-HK':
            lang = Locale.hk;
            break;
          case 'ko':
            lang = Locale.ko;
            break;
          case 'ja':
            lang = Locale.jp;
            break;
          default:
            lang = Locale.hk;
            break;
        }
        return lang;
      }
    }
    return Locale.hk;
  }

  setLocale(val: Locale): Observable<any> {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_LOCALE, val);
    this.localeChanged.emit(val);

    switch (val) {
      case Locale.cn:
        this.i18n.setLocale(zh_CN);
        break;
      case Locale.hk:
        this.i18n.setLocale(zh_HK);
        break;
      case Locale.en:
        this.i18n.setLocale(en_US);
        break;
      case Locale.jp:
        this.i18n.setLocale(ja_JP);
        break;
      case Locale.ko:
        this.i18n.setLocale(ko_KR);
        break;
    }

    return this.translate.use(val);
  }

  get locales(): Locale[] {
    return environment.supportedLocale;
  }
}
