import {Expose, plainToInstance, Transform, Type} from 'class-transformer';
import {
  Beneficiary,
  BookingProgress,
  BookingVip,
  ContingentOwner,
  Currency,
  Customer,
  CustomerCorporate,
  CustomerType, DomainMetadataVOS, Forms,
  Material,
  MedicalPlan, OpenAccountInfo,
  PagedReq, Phone,
  ProductPlan,
  Relationship,
  Rider, VIP
} from '../api/types';
import {Product} from '../product/product-types';
import { plainToArray } from '../shared/utils/class-transform';
import { SignatureInformation } from '../new-fna/new-fna-types';
import { FnaSigner } from '../fna/fna-types';

export enum BookingStatus {
  DRAFT = 'DRAFT',
  APPLIED = 'APPLIED',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED'
}

export class BookingListResp {

  @Expose() id: number; // 主键
  @Expose() bookingNo: string; // 预约编号
  @Expose() groupNo: string; // 分组编号
  @Expose() accountName: string; // 销售账户名
  @Expose() secondAccountName: string; // 销售账户
  @Expose() secondSalesName: string; // 销售账户名
  @Expose() salesName: string; // 销售姓名
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() appointmentTime: number; // 预约时间
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() trAccountName: string; // TR1
  @Expose() trName: string; // TR1姓名
  @Expose() trAreaCode: string; // TR1电话区号
  @Expose() trPhone: string; // TR1电话
  @Expose() tr2AccountName: string; // TR2
  @Expose() tr2Name: string; // TR2姓名
  @Expose() tr3AccountName: string; // TR3
  @Expose() tr3Name: string; // TR3姓名
  @Expose() custom: boolean; // 是否是资管产品

  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() companyName: string; // 保险公司
  @Expose() region: string; // 地区
  @Expose() productYear: string; // 年期规格
  @Expose() name: string; // 姓名
  @Expose() namePinYin: string; // 姓名-拼音
  @Expose() insuredName: string; // 受保人姓名
  @Expose() insuredPinyin: string; // 受保人姓名-拼音
  @Expose() status: BookingStatus; // 预约状态: DRAFT-草稿;APPLIED-申请;CONFIRMED-确认;COMPLETED-完成;CANCELLED-取消;DELETED-删除
  @Expose() addTime: number; // 预约创建时间
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() messageCount: number; // 留言数
  @Expose() create: boolean; // 新建提醒标识
  @Expose() reminder: boolean; // 其他消息提醒标识

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称
  @Expose() unReadCount: number; // 留言数未读数
  @Expose() unConfirmCount: number; // 留言数未确认数
  @Expose() hideTr: boolean; // 是否隐藏签单员\服务代表
}

export class BookingGroupListResp {
  @Expose() groupNo: string; // 分组编号
  @Expose() expand: boolean; // 是否展开
  @Expose() appBookingListVOS: BookingListResp[]; // 组内预约
}

export class BookingConcurrentlyGroupListResp {
  @Expose() groupNo: string; // 分组编号
  @Expose() appBookingListVOS: BookingListResp[]; // 组内预约
}

export class MeetingAddress {
  @Expose() id: string;
  @Expose() address: string;
  @Expose() addressType: string;
  @Expose() shortName: string;
}

export class BookingSearch extends PagedReq {
  @Expose() status: BookingStatus; // 预约状态: DRAFT-草稿;APPLIED-申请;CONFIRMED-确认;COMPLETED-完成;CANCELLED-取消;DELETED-删除
  @Expose() accountName: string; // 账户名
  @Expose() accountNames: string[]; // 账户名s
  @Expose() groupNo: string; // 分组编号
  @Expose() name: string; // 客户姓名
  @Expose() customCode: string;
  @Expose() appointmentTimeConfirmStart: number; // 预约开始时间
  @Expose() appointmentTimeConfirmEnd: number; // 预约结束时间
  @Expose() temporaryTimeStart: number; // 确定预约开始时间
  @Expose() temporaryTimeEnd: number; // 确定预约结束时间
  @Expose() bookingNo: string; // 预约编号
  @Expose() productCode: string; // 保险产品码
  @Expose() organizer: string; // 发起人
  @Expose() trAccountName: string; // 参与人
  @Expose() orderColumn: string;
  @Expose() orderAspect: string;
  @Expose() product: Product = new Product(); // 產品
}

export class Booking {
  @Expose() id: number; // 主键
  @Expose() bookingNo: string; // 预约编号
  @Expose() groupNo: string; // 分组编号
  @Expose() productCode: string; // 产品码
  @Expose() companyName: string;
  @Expose() companyCode: string;
  @Expose() enjoyPolicyDiscount: boolean;
  @Expose() discountEventsDeadlinesDate: string;
  @Expose() accountName: string; // 销售账户
  @Expose() nickName: string; // 销售账户名
  @Expose() secondAccountName: string; // 销售账户
  @Expose() secondNickName: string; // 销售账户名
  @Expose() salesName: string; // 销售姓名
  @Expose() serviceAgent: string; // 服务代表
  @Expose() serviceAgent2: string; // 服务代表
  @Expose() accompanyName: string; // 陪同姓名
  @Expose() accompanyAreaCode: string; // 陪同手机号区号
  @Expose() accompanyPhone: string; // 陪同手机号
  @Expose() accompanyEmail: string; // 陪同email
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() appointmentTime: number; // 预约时间
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() backtrack: boolean; // 保单日期回溯
  @Expose() preUnderwriting: boolean; // 是否预核保
  @Expose() policyDate: number; // 保单日期
  @Expose() prepay: boolean; // 预缴保费: 预缴-1;不预缴-0
  @Expose() prepayPremium: number; // 预付额
  @Expose() relationship: Relationship; // 投保人与被保人关系
  @Type(() => SignatureInformation)
  @Expose() signatureInformation: SignatureInformation[]; // 签名信息
  @Type(() => ProductPlan)
  @Expose() productPlan: ProductPlan = new ProductPlan();
  @Type(() => MedicalPlan)
  @Expose() medicalPlan: MedicalPlan = new MedicalPlan();
  @Type(() => Rider)
  @Transform((value) => plainToArray(Rider, value.value))
  @Expose() riders: Rider[];
  @Expose() ownerType: CustomerType = CustomerType.INDIVIDUAL; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Transform((value) => plainToInstance(Customer, Object.assign(new Customer(), value.value)))
  @Expose() owner: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyOwner: CustomerCorporate = new CustomerCorporate();
  @Expose() insuredType: CustomerType = CustomerType.INDIVIDUAL; // 受保人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() insured: Customer = new Customer(); // 准受保人快照
  @Type(() => CustomerCorporate)
  @Expose() companyInsured: CustomerCorporate = new CustomerCorporate(); // 公司受保人快照
  @Type(() => Beneficiary)
  @Expose() beneficiaries: Beneficiary[]; // 受益人列表快照
  @Type(() => ContingentOwner)
  @Expose() contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  @Expose() contingentInsured: ContingentOwner = new ContingentOwner(); // 第二受保人快照
  @Expose() applicationType: string; // 申请类型:ILAS_PRODUCT_OR_TOP_UP_APPLICATION-投資相連壽險計劃或額外供款申請;NON_ILAS_PRODUCT_APPLICATION-非投資相連壽險計劃申請;FUND_SWITCHING-基金轉換;MPF_APPLICATION-強積金計劃申請;GENERAL_INSURANCE_APPLICATION-一般保險申請;
  @Expose() fnaId: number; // 关联FNA
  @Expose() fnaRelationship: string; // 关联FNA投保人受保人信息 OWNER INSURED
  @Expose() proposalId: string; // 关联計劃書
  @Expose() status: BookingStatus; // 预约状态: DRAFT-草稿;APPLIED-申请;CONFIRMED-确认;COMPLETED-完成;CANCELLED-取消;DELETED-删除
  @Expose() custom: boolean; // 是否是资管产品
  @Expose() attachments: Material[]; // 预约附件
  @Type(() => Other)
  @Expose() other: Other = new Other(); // 其他
  @Expose() replacementDeclaration: string; // 转保声明
  @Expose() showReplacementDeclaration: boolean; // 转保说明版本 false旧版本，true新版本

  @Expose() arriveTime: number; // 到港時間
  @Expose() leaveTime: number; // 離港時間
  @Expose() meetingPlace: string; // 會面地點

  @Expose() hongkongContactNumberAreaCode: string;
  @Expose() hongkongContactNumber: string;
  @Expose() hongKongInsurance: boolean; // 是否购买过香港保险

  @Expose() openBankAccount: boolean; // 是否开户
  @Transform((value) => plainToInstance(OpenAccountInfo, Object.assign(new OpenAccountInfo(), value.value)))
  @Expose() openAccountInfo: OpenAccountInfo = new OpenAccountInfo(); // 开户
  @Expose() healthCheck: boolean; // 是否体检
  @Expose() guarantee: boolean; // BPO

  @Expose() addTime: number; // 预约创建时间

  @Type(() => BookingVip)
  // @Transform((value) => plainToInstance(BookingVip, Object.assign(new BookingVip(), value.value)))
  @Expose() vip: BookingVip; // VIP客戶

  @Expose() financing: boolean; // 是否融资
  @Expose() financingPremium: number; // 融资金额
  @Expose() financingCurrency: Currency; // 融资金额货币
  @Expose() financingBank: string; // 融资银行名称
  @Expose() financingBankPrincipal: string; // 融资银行负责人
  @Expose() financingBankContactPhone: string; // 融资银行联系电话

  @Expose() trAccountName: string; // TR账号
  @Expose() trName: string; // TR姓名
  @Expose() trAreaCode: string; // TR电话区号
  @Expose() trPhone: string; // TR电话
  @Expose() trEmail: string; // TR邮箱
  @Expose() tr2AccountName: string; // TR2
  @Expose() tr2Name: string; // TR姓名
  @Expose() tr2AreaCode: string; // TR电话区号
  @Expose() tr2Phone: string; // TR电话
  @Expose() tr2Email: string; // TR邮箱
  @Expose() tr3AccountName: string; // TR3
  @Expose() tr3Name: string; // TR姓名
  @Expose() tr3AreaCode: string; // TR电话区号
  @Expose() tr3Phone: string; // TR电话
  @Expose() tr3Email: string; // TR邮箱

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Expose() special: string; // 预约单备注

  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称
  @Type(() => DomainMetadataVOS)
  @Expose() domainMetadataVOS: DomainMetadataVOS[]; // 动态字段
  @Expose() hideTr: boolean; // 是否隐藏签单员\服务代表
  @Expose() isPaperContracts: boolean; // 是否要求纸质合同
  @Expose() authorizeCollect: boolean; // 是否授权由我们为客户领取保单

  ownerNameString(): string {
    let result = '';
    switch (this.ownerType) {
      case CustomerType.INDIVIDUAL:
        result = (this.owner.lastName ?? '') + ' ' + (this.owner.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyOwner.companyName ?? '';
        break;
    }
    return result;
  }

  insuredNameString(): string {
    if (this.relationship === Relationship.OWN) {
      return this.ownerNameString();
    }
    let result = '';
    switch (this.insuredType) {
      case CustomerType.INDIVIDUAL:
        result = (this.insured.lastName ?? '') + ' ' + (this.insured.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyInsured.companyName ?? '';
        break;
    }
    return result;
  }

  firstBeneficiaryNameString(): string {
    if (!this.beneficiaries || this.beneficiaries.length === 0) {
      return '';
    }
    const beneficiary = this.beneficiaries[0];
    let result;
    result = (beneficiary.lastName ?? '') + ' ' + (beneficiary.firstName ?? '');
    return result;
  }

  consultantPhoneString(): string {
    return (this.accompanyAreaCode ? '+' + this.accompanyAreaCode + ' ' : '') + (this.accompanyPhone ?? '');
  }

  clientContactNoInHongKong(): string {
    return (this.hongkongContactNumberAreaCode ? '+' + this.hongkongContactNumberAreaCode + ' ' : '') + (this.hongkongContactNumber ?? '');
  }
}

export class Other {
  @Expose() insurance: any; // 已持有/申请中的保险
  @Expose() transferPast12: boolean; // 过去12个月取代
  @Expose() transferFuture12: boolean; // 未来12个月取代
}

export class BookingDraftResp {
  @Expose() id: number; // 主键
  @Expose() bookingNo: string; // 主键
}

export class BookingSignatureUpdateReq {
  @Expose() bookingNo: string; //
  @Expose() imageBase64: string; // imageBase64
  @Expose() signatureType: string; // 新FNA PDF签名的对象
}

export class BookingDetailResp {

  @Expose() id: number; // 主键
  @Expose() bookingNo: string; // 预约编号

  @Expose() name: string; // 客户姓名
  @Expose() pinyin: string; // 客户英文名

  @Expose() groupNo: string; // 分组编号
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() companyName: string; // 保险公司名称
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() accountName: string; // 销售账户
  @Expose() nickName: string; // 销售账户名
  @Expose() salesName: string; // 销售姓名
  @Expose() accompanyName: string; // 陪同姓名
  @Expose() accompanyAreaCode: string; // 陪同手机号区号
  @Expose() accompanyPhone: string; // 陪同手机号
  @Expose() accompanyEmail: string; // 陪同email
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() appointmentTime: number; // 预约时间
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() backtrack: boolean; // 保单日期回溯
  @Expose() policyDate: number; // 保单日期
  @Type(() => SignatureInformation)
  @Expose() signatureInformation: SignatureInformation[]; // 签名信息
  @Expose() relationship: Relationship; // 投保人与被保人关系
  @Type(() => ProductPlan)
  @Transform((value) => plainToInstance(ProductPlan, Object.assign(new ProductPlan(), value.value)))
  @Expose() productPlan: ProductPlan = new ProductPlan();
  @Transform((value) => plainToInstance(MedicalPlan, Object.assign(new MedicalPlan(), value.value)))
  @Type(() => MedicalPlan)
  @Expose() medicalPlan: MedicalPlan = new MedicalPlan();

  @Expose() rider: Rider[];
  @Expose() ownerType: CustomerType = CustomerType.INDIVIDUAL; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() owner: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyOwner: CustomerCorporate = new CustomerCorporate();
  @Expose() insuredType: CustomerType = CustomerType.INDIVIDUAL; // 受保人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() insured: Customer = new Customer(); // 准受保人快照
  @Type(() => CustomerCorporate)
  @Expose() companyInsured: CustomerCorporate = new CustomerCorporate(); // 公司受保人快照
  @Type(() => Beneficiary)
  @Expose() beneficiaries: Beneficiary[]; // 受益人列表快照
  @Type(() => ContingentOwner)
  @Expose() contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  @Expose() applicationType: string; // 申请类型:ILAS_PRODUCT_OR_TOP_UP_APPLICATION-投資相連壽險計劃或額外供款申請;NON_ILAS_PRODUCT_APPLICATION-非投資相連壽險計劃申請;FUND_SWITCHING-基金轉換;MPF_APPLICATION-強積金計劃申請;GENERAL_INSURANCE_APPLICATION-一般保險申請;
  @Expose() fnaId: number; // 关联FNA
  @Expose() fnaRelationship: string; // 关联FNA投保人受保人信息 OWNER INSURED
  @Expose() proposalId: string; // 关联FNA
  @Expose() status: BookingStatus; // 预约状态: DRAFT-草稿;APPLIED-申请;CONFIRMED-确认;COMPLETED-完成;CANCELLED-取消;DELETED-删除

  @Expose() arriveTime: number; // 到港時間
  @Expose() leaveTime: number; // 離港時間
  @Expose() meetingPlace: string; // 會面地點
  @Type(() => Other)
  @Expose() other: Other = new Other(); // 其他
  @Expose() replacementDeclaration: string; // 转保声明
  @Expose() showReplacementDeclaration: boolean; // 转保说明版本 false旧版本，true新版本

  @Expose() hongkongContactNumberAreaCode: string;
  @Expose() hongkongContactNumber: string;

  @Expose() openBankAccount: boolean; // 是否开户
  @Expose() edit: boolean; // 是否编辑
  @Transform((value) => plainToInstance(OpenAccountInfo, Object.assign(new OpenAccountInfo(), value.value)))
  @Expose() openAccountInfo: OpenAccountInfo; // 开户
  @Expose() healthCheck: boolean; // 是否体检
  @Expose() guarantee: boolean; // BPO

  @Expose() addTime: number; // 预约创建时间

  @Type(() => BookingVip)
  @Expose() vip: BookingVip; // VIP客戶

  @Expose() financing: boolean; // 是否融资
  @Expose() financingPremium: number; // 融资金额
  @Expose() financingCurrency: Currency; // 融资金额货币
  @Expose() financingBank: string; // 融资银行名称
  @Expose() financingBankPrincipal: string; // 融资银行负责人
  @Expose() financingBankContactPhone: string; // 融资银行联系电话

  @Expose() trAccountName: string; // TR
  @Expose() tr2AccountName: string; // TR
  @Expose() tr3AccountName: string; // TR
  @Expose() trName: string; // TR姓名
  @Expose() tr2Name: string; // TR2姓名
  @Expose() tr3Name: string; // TR2姓名
  @Expose() trAreaCode: string; // TR电话区号
  @Expose() tr2AreaCode: string; // TR2电话区号
  @Expose() tr3AreaCode: string; // TR2电话区号
  @Expose() trPhone: string; // TR2电话区号
  @Expose() tr2Phone: string; // TR2电话
  @Expose() tr3Phone: string; // TR2电话
  @Expose() trEmail: string; // TR邮箱
  @Expose() tr2Email: string; // TR2邮箱
  @Expose() tr3Email: string; // TR2邮箱

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称
  @Expose() bookingForms: Forms[]; // 表单

  @Type(() => BookingProgress)
  @Expose() bookingProgress: BookingProgress; // 预约进度
  @Expose() custom: boolean;
  @Type(() => DomainMetadataVOS)
  @Expose() domainMetadataVOS: DomainMetadataVOS[]; // 动态字段
  @Expose() hideTr: boolean; // 是否隐藏签单员\服务代表
  @Expose() isPaperContracts: boolean; // 是否要求纸质合同
  @Expose() authorizeCollect: boolean; // 是否授权由我们为客户领取保单

  ownerNameString(): string {
    let result = '';
    switch (this.ownerType) {
      case CustomerType.INDIVIDUAL:
        result = (this.owner.lastName ?? '') + ' ' + (this.owner.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyOwner.companyName ?? '';
        break;
    }
    return result;
  }

  insuredNameString(): string {
    let result = '';
    switch (this.insuredType) {
      case CustomerType.INDIVIDUAL:
        result = (this.insured.lastName ?? '') + ' ' + (this.insured.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyInsured.companyName ?? '';
        break;
    }
    return result;
  }

  consultantPhoneString(): string {
    return (this.accompanyAreaCode ? '+' + this.accompanyAreaCode + ' ' : '') + (this.accompanyPhone ?? '');
  }

  clientContactNoInHongKong(): string {
    return (this.hongkongContactNumberAreaCode ? '+' + this.hongkongContactNumberAreaCode + ' ' : '') + (this.hongkongContactNumber ?? '');
  }

  toBooking(): Booking {
    return plainToInstance(Booking, this, {excludeExtraneousValues: true});
  }
}

export  class  DownloadSearch {
  @Expose() addEndDate: string;
  @Expose() addStartDate: string;
  @Expose() type: number;
}

export  class  ImportResTips {
  @Expose() totalRows: number; // 识别到的总行数
  @Expose() validRows: number; // 有效行数
  @Expose() storageRecords: number; // 存储记录数
  @Expose() errorRecords: number; // 解析出错行数
  @Expose() logicErrorRecords: number; // 逻辑出错行数
  @Type(() => ErrorDetails)
  @Expose() errorDetails: ErrorDetails[]; // 错误详情

  @Expose() message: string; // 错误信息

}

export class  ErrorDetails {
  @Expose() row: number; // 行
  @Expose() column: string; // 列
  @Expose() data: string; // 识别到的数据
  @Expose() cellDataType: string; // 识别到的excel格式
  @Expose() message: string; // 错误提示
  @Expose() exception: string; // Exception
  @Expose() sheetName: string; // sheetName
}

export class SignedParam {
  @Expose() bookingNo: string; // 预约编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyId: string; // 保单ID
  @Expose() policyNo: string; // 保单编号
  @Expose() bookingFormId: string; // 表单唯一标识
  @Expose() signedImageBase: string; // 图片Base64
  @Expose() signer: string; // 预约签单签名的对象
  @Expose() signedDate: number; // 签名日期
}

export class BookingCheckMerge {
  @Expose() merge: boolean; // 是否可合并
  @Expose() bookingNo: string; // 预约编号
  @Expose() mergeBookingNo: string; // 合并预约（分组）编号
  @Expose() ownerName: string; // 投保人名字
  @Expose() ownerNameEn: string; // 投保人名字（英文）
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() temporaryTime: number; // 意向预约时间
  @Expose() appointmentTime: number; // 预约时间
}

export class GroupDialog {
  @Expose() groupNo: string; // 预约分组编号
  @Expose() content: string; // 对话内容
  @Expose() attachments: Material[]; // 对话附件
}
