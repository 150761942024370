<div class="login-container">

  <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
    <div nz-col [nzSpan]="12" class="login-column">
      <form #loginForm="ngForm" style="width: 100%" class="login-column">
        <img width="150" src="assets/images/logo.png" alt="">
        <div class="changePwd-title">{{ "RetrievePassword" | translate }}</div>
        <div *ngIf="changePwdType == 'PHONE'" class="tips_text">
          <span>{{'PhoneTips' | translate}}</span>
          <span>{{changeInfo.phone}}</span>
        </div>
        <div *ngIf="changePwdType == 'EMAIL'" class="tips_text">
          <span>{{'EmailTips' | translate}}</span>
          <span>{{changeInfo.email}}</span>
        </div>
<!--        验证账号-->
        <div *ngIf="changePwdType == 'DEFAULT'" style="width: 50%; margin-top: 20px;">
          <nz-input-group [nzSuffix]="suffixAccount" class="input-group">
            <input required [(ngModel)]="changePwd.accountName" id="account" name="account" autofocus="autofocus"
                   [placeholder]="('PleaseEnter' | translate) + ('Account' | translate)" class="login-input"/>
          </nz-input-group>
          <ng-template #suffixAccount>
<!--            <i nz-tooltip [nzTooltipTitle]="'DeviceLimitDescription' | translate" style="margin-top: 10px; font-size: 13px" nz-icon nzType="info-circle"></i>-->
          </ng-template>
        </div>

<!--        电话号码修改-->
        <div *ngIf="changePwdType != 'DEFAULT'" style="width: 50%; margin-top: 20px;">
          <nz-input-group [nzSuffix]="suffixPassword" class="input-group">
            <input required [(ngModel)]="changePwd.newPassword" id="phonePwd" name="phonePwd" [type]="passwordVisible ? 'text' : 'password'"
                   [placeholder]="'NewPassword' | translate" class="login-input-change"/>
          </nz-input-group>
          <ng-template #suffixPassword>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="passwordVisible = !passwordVisible"></i>
          </ng-template>
          <div *ngIf="pwdConfig.complexPasswordConfig"
               style="margin-top: 4px;font-size: 12px;color: #BF7000;line-height: 16px;white-space: pre-line;">
            {{'PwdLength' | translate: {length: pwdConfig.passwordLengthConfig} }}
          </div>
          <nz-input-group class="input-group-code">
            <input required [(ngModel)]="changePwd.code" id="code" name="code"
                   style="width: 60%;"
                   [placeholder]="('PleaseEnter' | translate) + ('VerificationCode' | translate)" class="login-input-change"/>
<!--            <div class="code-button" appThrottleClick (throttleClick)="onSend()">{{'SendVerificationCode' | translate}}</div>-->
            <div [class]="isOk ? 'code-button-disabled' : 'code-button'" appThrottleClick (throttleClick)="onSend()">
              {{isOk ? timeVal : ('SendVerificationCode' | translate)}}
            </div>
          </nz-input-group>
<!--          换邮箱验证-->
          <div *ngIf="changePwdType == 'PHONE'" style="margin: 10px 0">
            {{'ToggleTips' | translate}}
            <span style="color: #1D64C4; cursor: pointer" appThrottleClick (throttleClick)="toggleEmail()">{{'MailboxVerification' | translate}}</span>
          </div>
        </div>


        <div *ngIf="changePwdType == 'DEFAULT'" style="margin-top: 30px;">
          <button style="width: 60px; height: 60px; color: #13263f" [nzLoading]="logging"
                  [disabled]="!changePwd.accountName"
                  appThrottleClick (throttleClick)="verifyAccount()"
                  nz-tooltip [nzTooltipTitle]="'Next' | translate"
                  nz-button nzType="default" nzShape="circle">
            <i nz-icon nzType="login"></i>
          </button>
        </div>
        <div *ngIf="changePwdType != 'DEFAULT'" style="margin-top: 30px;">
          <button style="width: 120px; height: 60px; color: #13263f; font-size: 16px"
                  [nzLoading]="logging"
                  [disabled]="isValue()"
                  appThrottleClick (throttleClick)="onPhoneChange()"
                  nz-button nzType="default" nzShape="round">
            {{'Confirm' | translate}}
          </button>
        </div>
        <div style="width: 40%;">
          <nz-divider></nz-divider>
        </div>
        <!--  上一步-->
        <div>
          <button style="width: 40px; height: 40px;" type="button"
                  nz-tooltip [nzTooltipTitle]="'Back' | translate" [disabled]="logging"
                  nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onBack()">
            <i nz-icon nzType="arrow-left"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
