import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DataDesensitization, DataMode, Locale, PagedResp } from '../../api/types';
import { InvitationService } from '../invitation.service';
import { NewFnaDetail, NewFnaList, NewFnaSearch } from '../../new-fna/new-fna-types';
import { InviteInfoRes, InviteListRes, InviteSearch } from '../invitation-types';
import { ToastrService } from 'ngx-toastr';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NewFnaCreateComponent } from '../../new-fna/new-fna-create/new-fna-create.component';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';
import { formatDate } from '@angular/common';
import { InvitationCreateComponent } from '../invitation-create/invitation-create.component';
import { Expose, plainToInstance } from 'class-transformer';
import { AccountService } from '../../account/account.service';
import { Tool } from '../../shared/utils/tool';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { LocaleService } from '../../core/locale.service';

@Component({
  selector: 'app-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.less']
})
export class InvitationListComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;
  search: InviteSearch = new InviteSearch();
  spinning: boolean;
  loading: boolean;
  filterPopoverVisible: boolean;
  deleting: boolean;
  invitationData: PagedResp<InviteListRes>;
  workbench: Workbench;
  dates: number[];
  dataDesensitization: DataDesensitization = new DataDesensitization();
  statusOptions: PropertySelectOption[] = [];

  constructor(private invitationService: InvitationService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private localeService: LocaleService,
              private modalService: NzModalService,
              private accountService: AccountService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.dataDesensitization = this.accountService.dataDesensitization;
    this.statusOptions.push(new PropertySelectOption(this.translate.instant('ProposalStatusPending'), 'PENDING-PROCESSING'));
    this.statusOptions.push(new PropertySelectOption(this.translate.instant('Processed'), 'PROCESSED'));
    this.loading = true;
    this.search.pageSize = 20;
    this.invitationService.list(this.search, this.mode)
      .subscribe(list => {
          this.loading = false;
          this.invitationData = JSON.parse(JSON.stringify(list));
        },
        error => {
          this.loading = false;
        });

    this.workbenchService.switchLocaleReload
      .subscribe(locale => {
        this.onReload();
      });
  }

  language(): boolean {
    let str = true;
    switch (this.localeService.locale) {
      case Locale.cn:
      case Locale.hk:
        str = true;
        break;
      case Locale.en:
      case Locale.jp:
      case Locale.ko:
        str = false;
        break;
    }
    return str;
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.search.pageSize = 20;
    this.invitationService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.invitationData = data;
        },
        error => {
          this.loading = false;
        });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.search.pageSize = 20;
    this.filterPopoverVisible = false;
    this.loading = true;
    this.invitationService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.invitationData = data;
        },
        error => {
          this.loading = false;
        });
  }

  createTimeChange(value: any): void {
    this.search.sortField = 'createTime';
    this.search.sortOrder = value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : null;
    this.onReload();
  }

  reuseInfo(info: InviteListRes): void {
    console.log('reuse');
    this.loading = true;
    this.invitationService.info(info.id)
      .subscribe(
        dataInfo => {
          this.loading = false;
          const formParams = plainToInstance(InviteInfoRes, dataInfo);
          formParams.name = formParams.name ? formParams.name : this.accountService.passport.name;
          formParams.meetingTime = null;
          formParams.id = null;
          formParams.productCodes = [];
          formParams.companyNames = [];
          formParams.companyCodes = [];
          formParams.status = null;
          formParams.budget = null;
          const drawerRef = this.drawerService.create<InvitationCreateComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: InvitationCreateComponent,
            nzContentParams: {
              mode: this.mode,
              type: 'create',
              formParams
            },
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.invitationDrawerClose.subscribe(
              data => {
                drawerRef?.close();
                this.onReload(true);
              }
            );
          });
          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  onDetail(info: InviteListRes): void {
    this.invitationService.info(info.id)
      .subscribe(
        dataInfo => {
          this.loading = false;
          console.log(dataInfo);
          dataInfo.meetingTime = dataInfo.meetingTime ? formatDate(dataInfo.meetingTime, 'yyyy-MM-dd HH:mm:ss', 'en-US') : dataInfo.meetingTime;

          const formParams = plainToInstance(InviteInfoRes, dataInfo);
          if (this.mode !== 'OWN') {
            formParams.status = 'PROCESSED';
          }
          const drawerRef = this.drawerService.create<InvitationCreateComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: InvitationCreateComponent,
            nzContentParams: {
              mode: this.mode,
              type: 'detail',
              formParams
            },
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.invitationDrawerClose.subscribe(
              data => {
                drawerRef?.close();
                this.onReload(true);
              }
            );
          });
          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });

  }


  onCreate(): void {
    console.log('onFnaCreate');
    const drawerRef = this.drawerService.create<InvitationCreateComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: InvitationCreateComponent,
      nzContentParams: {
        mode: this.mode,
        type: 'create'
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.invitationDrawerClose.subscribe(
        data => {
          drawerRef?.close();
          this.onReload(true);
        }
      );
    });
    drawerRef.afterClose.subscribe(data => {
    });
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        isBasic: true
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.productCode = product.productCode;
            this.search.productName = product.productName;
            drawerRef.close();
            this.filterPopoverVisible = true;
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onDelete(invite: InviteListRes, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: invite,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, invite: InviteListRes): void {
    this.deleting = true;
    this.invitationService.delete(invite.id)
      .subscribe(
        data => {
          this.deleting = false;
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.onSearch(this.search.pageNum);
          ref.close();
        },
        error => {
          this.deleting = false;
          ref.close();
        });
  }

  onDateChange(time: number[]): void {
    if (time) {
      this.dates = time;
      this.search.startMeetingDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.search.endMeetingDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    }
  }

  onResetSearch(): void {
    this.search = new InviteSearch();
    this.dates = null;
    this.filterPopoverVisible = false;
    this.onReload();
  }

  getChartHeight(): string {
    const windowHeight = window.innerHeight;
    const minHeight = windowHeight - 410;
    return minHeight + 'px';
  }

  dynamicEncryption(keyName, val): string {
    let isShow = val;
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
    }
    return isShow;
  }
}
