import {Expose, plainToInstance, Transform, Type} from 'class-transformer';
import {
  Address,
  Gender,
  MarriageStatus, Material,
  PagedReq,
  Phone, Relationship,
  SmokeCondition,
  Title, WorkExperience
} from '../api/types';

export enum ContactsType {
  Create = 'Create',
  Edit = 'Edit'
}
export enum CreateContactsType {
  CONTACT = 'CONTACT',
  PRE_SALES = 'PRE_SALES'
}

export class ContactSearch extends PagedReq {
  @Expose() name: string; // 姓名/姓名拼音
  @Expose() familyId: number; // 自定义编号
  @Expose() excludedFamilyId: number; // 排除家庭编号
  @Expose() type: string; // 客户类型
}

export class ContactTable extends PagedReq {
  @Expose() type: string; // 人脉类型:ONE-一度人脉;TWO-二度人脉
  @Expose() nickname: string; // 姓名/姓名拼音 我的人脉
  @Expose() subordinateName: string; // 姓名/姓名拼音 我的下属
}

export class ConnectionsListRes {
  @Expose() id: number; // 人脉编号
  @Expose() gender: Gender; // 用户性别:FEMALE-女;MALE-男;NULL-未知
  @Expose() type: string; // 人脉类型:ONE-一度人脉;TWO-二度人脉
  @Expose() nickname: string; // 用户昵称
  @Expose() headImgUrl: string; // 头像地址
}

export class SubordinateListRes {
  @Expose() id: number; // 账号编号
  @Expose() gender: Gender; // 用户性别:FEMALE-女;MALE-男;NULL-未知
  @Expose() accountName: string; // 账户名 我的下线数据
  @Expose() firstName: string; // 名 我的下线数据
  @Expose() lastName: string; // 姓 我的下线数据
  @Expose() expanded: string; // 是被拓展者:是-1;不是-0 我的下线数据
  @Expose() avatar: string; // 用户头像 我的下线数据
  @Expose() birthday: number; // 出生日期 我的下线数据
  @Expose() name: string;
  @Expose() salesRank: string;
  @Expose() phone: string;
  @Expose() email: string;
  @Expose() administrationEmail: string;
  @Expose() lastOnlineTime: string;
  @Expose() addTime: string;
  @Expose() areaCode: string;
  @Expose() nickName: string;
}

export class SubAccountListRes {
  @Expose() id: number; // 账号编号
  @Expose() gender: Gender; // 用户性别:FEMALE-女;MALE-男;NULL-未知
  @Expose() accountName: string; // 账户名 我的下线数据
  @Expose() firstName: string; // 名 我的下线数据
  @Expose() lastName: string; // 姓 我的下线数据
  @Expose() expanded: string; // 是被拓展者:是-1;不是-0 我的下线数据
  @Expose() avatar: string; // 用户头像 我的下线数据
  @Expose() birthday: number; // 出生日期 我的下线数据
  @Expose() name: string;
  @Expose() phone: string;
  @Expose() email: string;
  @Expose() administrationEmail: string;
  @Expose() areaCode: string;
  @Expose() nickName: string;
  @Expose() institutionName: string;
  @Expose() institutionCode: string;
}

export class ContactListRes {
  @Expose() birthday: number; // 出生日期
  @Expose() lastName: string; // 姓
  @Expose() country: string; // 国家地区
  @Expose() gender: Gender; // 性别
  @Expose() accountName: string; // 账户名
  @Expose() smoke: SmokeCondition; // 是否吸烟:S-吸烟;NS-不吸烟
  @Expose() avatar: string; // 头像
  @Expose() familyId: number; // 自定义编号
  @Expose() firstName: string; // 名
  @Expose() areaCode: string; // 区号
  @Expose() pinyin: string; // 拼音
  @Expose() phone: string; // 手机号码
  @Expose() marriage: string; // 婚姻状况: SINGLE-单身;MARRIED-已婚;DIVORCED-离婚;WIDOWED-丧偶
  @Expose() name: string; // 姓名
  @Expose() id: number; // 编号
  @Expose() relationship: string; // 與戶主關係（成為家庭成員時）
  @Expose() age: number; // 年龄
  @Expose() type: string; // 联系人类型
  @Expose() followStatus: string; // 状态
}

// 介绍人
export class Introducer {
  companyName: string; // 公司名称
  organizationCode: string; // 组织代码
  salesDirector: string; // 销售主管
  businessPeople: string; // 业务人员
  salesLeader: string; // Sales Leader
  subSalesLeader: string; // Sub Sales Leader
  teamHead: string; // Team Head
  relationshipManager: string; // Relationship Manager
}

export class ContactInfoRes {
  @Expose() country: string; // 国家地区
  @Expose() education: string; // 教育程度
  @Expose() movie: string[]; // 电影: Action-动作/Animation-动画/Horror-恐怖/Comedy-喜剧/Crime-犯罪/Drama-戏剧/Fantasy-幻想/Historical-历史/Mystery-神秘/Romance-浪漫/Science-Fiction-科幻/Others-其他
  @Expose() movieOther: string; // 电影为其他时的描述
  @Expose() idCard: string; // 身份证
  @Expose() companyName: string; // 公司名称
  @Expose() salary: number; // 月薪
  @Expose() id: number; // 编号
  @Expose() passport: string; // 护照
  @Expose() games: string[]; // 游戏: Action-动作/Adventure-冒险/Fighting-战争/Puzzle-拼图/Racing-赛车/Role-playing-角色扮演/Stimulation-刺激/Strategy-策略/Sports-体育/Others-其他
  @Expose() gamesOther: string; // 游戏为其他时的描述
  @Expose() group: string; // 名字首字母
  @Expose() height: number; // 身高cm
  @Expose() weight: number; // 体重kg
  @Expose() noOfDependents: number; // 受供養人數目
  @Expose() eepCode: string; // 港澳通行证
  @Expose() longtimeAbroad: boolean; // 是否长期在国外
  @Expose() citizenship: boolean; // 是否拥有其他国家公民身份
  @Expose() totalLiabilities: number; // 總債務
  @Expose() totalLiquidAssets: number; // 總流動資產
  @Expose() averageMonthlyExpenses: number; // 平均每月支出
  @Expose() residenceTelephone: string; // 住宅电话
  @Expose() residenceAreaCode: string; // 住宅电话区号
  @Expose() companyAreaCode: string; // 公司电话区号
  @Expose() firstName: string; // 名
  @Expose() lastName: string; // 姓
  @Expose() lastNamePinyin: string; // 姓-拼音
  @Expose() firstNamePinyin: string; // 名-拼音
  @Expose() accountName: string; // 账户名
  @Expose() phones: Phone[]; // 手机号码列表|| 其他手机号
  @Expose() gender: Gender; // 性别
  @Expose() areaCode: string; // 区号
  @Expose() riskAppetite: string; // 风险偏好: High-高/Medium-中/Low-低
  @Expose() pinyin: string; // 拼音
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() postalAddress: Address = new Address(); // 通讯地址
  @Expose() birthplace: string; // 出生地
  @Expose() phone: string; // 手机号码
  @Expose() email: string; // 电子邮箱
  @Expose() name: string; // 姓名
  @Expose() position: string; // 职位
  @Expose() companyTelephone: string; // 公司电话
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() residenceAddress: Address; // 户籍地址
  @Expose() sports: string[]; // 体育: Football-足球/NBA-NBA/Tennis-网球/Badminton-羽毛球/Golf-高尔夫/Volleyball-排球/Swimming-游泳/Cycling-自行车/Gym-健身/Yoga-瑜伽/Others-其他
  @Expose() sportsOther: string; // 体育为其他时的描述
  @Expose() birthday: number; // 出生日期
  @Expose() birthdate: string;
  @Expose() whatsapp: string; // whatsapp
  @Expose() title: Title; // 称谓: Mr-先生;Mrs-夫人;Miss-小姐;Ms-女士;Dr-博士
  @Expose() marriage: MarriageStatus; // 婚姻状况: SINGLE-单身;MARRIED-已婚;DIVORCED-离婚;WIDOWED-丧偶
  @Expose() relationship: string; // 與戶主關係（成為家庭成員時）
  @Expose() yearInCurrentJob: number; // 受僱於現職年期
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() address: Address; // 地址
  @Expose() companyType: string; // 公司行业
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() companyAddress: Address; // 公司地址
  @Expose() facebook: string; // facebook
  @Expose() wechat: string; // wechat
  @Expose() smoke: SmokeCondition; // 吸烟: S-吸烟;NS-不吸烟
  @Expose() smokeQuantity: string; // 吸烟量
  @Expose() cuisine: string[]; // 美食: Chinese-中国/Japanese-日本/Korean-韩国/Singaporean-新加坡/Taiwanese-台湾/Malaysian-马来西亚/European-欧洲/Others-其他
  @Expose() cuisineOther: string; // 美食为其他时的描述
  @Expose() avatar: string; // 客户头像
  @Expose() familyId: number; // 家庭編號
  @Expose() workYear: number; // 工作年期
  @Expose() travel: string; // 旅行
  @Expose() averageMonthlyIncome: number; // 平均每月收入
  @Expose() age: number; // 年龄
  @Expose() remark: string; // 备注
  @Type(() => VIP)
  @Transform((value) => plainToInstance(VIP, Object.assign(new VIP(), value.value)))
  @Expose() vip: VIP; // 备注
  @Expose() type: string; // 类型
  @Type(() => Introducer)
  @Transform((value) => plainToInstance(Introducer, Object.assign(new Introducer(), value.value)))
  @Expose() introducer: Introducer; // 介绍人信息
  @Expose() businessSource: string; // 业务来源

  phoneString(): string {
    return (this.areaCode ? '+' + this.areaCode + ' ' : '') + (this.phone ?? '');
  }
  officePhoneNumberString(): string {
    return (this.companyAreaCode ? '+' + this.companyAreaCode + ' ' : '') + (this.companyTelephone ?? '');
  }

  residentialPhoneNumberString(): string {
    return (this.residenceAreaCode ? '+' + this.residenceAreaCode + ' ' : '') + (this.residenceTelephone ?? '');
  }
}

export class VIP {
  vip: boolean; // 是否是VIP
  remark: string; // VIP备注，只有当vip为true才显示
}

export class MemberList {
  @Expose() familyId: number; // 家庭編號
}

export class MemberListRes {
  @Expose() id: number; // 编号
  @Expose() contactCode: string; // 客户编号
  @Expose() accountName: string; // 账户名
  @Expose() familyId: string; // 自定义编号
  @Expose() name: string; // 姓名
  @Expose() lastName: string; // 姓
  @Expose() firstName: string; // 名
  @Expose() pinyin: string; // 拼音
  @Expose() gender: Gender; // 性别
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 手机号码
  @Expose() birthday: number; // 出生日期
  @Expose() age: number; // 年龄
  @Expose() avatar: string; // 头像
  @Expose() country: string; // 国家地区
  @Expose() smoke: SmokeCondition; // 吸烟: S-吸烟;NS-不吸烟
  @Expose() marriage: MarriageStatus; // 婚姻状况: SINGLE-单身;MARRIED-已婚;DIVORCED-离婚;WIDOWED-丧偶
  @Expose() relationship: Relationship; // 與戶主關係（成為家庭成員時）
  @Expose() type: string; // 类型
}

export class Tree {
  @Expose() id: number; // 账号编号
  @Expose() accountName: string; // 账户名
  @Expose() salesChannel: string; // 销售渠道 账号类型
  @Expose() firstName: string; // 名
  @Expose() lastName: string; // 姓
  @Expose() bankName: string; // 银行名称
  @Expose() bankCode: string; // 银行代码
  @Expose() institutionName: string; // 机构名称
  @Expose() institutionCode: string; // 机构代码
  @Expose() personName: string; // 姓名
  @Expose() phone: string; // 手机号码
  @Expose() email: string; // 邮箱
  @Expose() parentAccountName: string;
  @Expose() avatar: string; // 头像
  @Expose() salesRank: string;
  @Expose() status: string; // NORMAL 正常; FORBID - 禁用
  @Type(() => Tree)
  @Expose() children: Tree[]; // 下线
  @Expose() high: number; // 数据深度
  @Expose() emails: Emails = new Emails(); // 数据深度
  @Expose() registerNumber: string; // 理财顾问注册编号
  @Expose() consultantNumber: string; // 理财顾问编码
  @Expose() name: string; // 显示的姓名
  @Expose() branchCode: string; // branchCode
  @Expose() lastOnlineTime: number; // 最后一次上线时间
  @Expose() customCode: string; // 自定义编号
  @Expose() registrationCode: string; // 注册码
  @Expose() referrer: string; // Referrer    Referrer、Non-Referrer
}

export class Emails {
  type: string; // ADMINISTRATIVE,FINANCE,OTHER
  email: string;
}

// 客户记录
export class FollowListRes {
  @Expose() id: number; // ID
  @Expose() name: string; // 姓名
  @Expose() pinyin: string; // 拼音
  @Expose() avatar: string; // 客户头像
  @Expose() followCount: string; // 记录次数
  @Expose() followStatus: string; // 当前跟进状态
  @Expose() followListVOS: FollowListVOS[]; // 记录列表
}

export class FollowListVOS {
  id: number; // 主键
  followDate: string; // 记录日期
  place: string; // 地点
  matter: string; // 事项
}

// 添加客户记录
export class PreSaleFollow {
  @Expose() id: number; // 跟进记录ID
  @Expose() preSalesId: number; // 联系人ID
  @Expose() followDate: string; // 记录日期
  @Expose() place: string; // 地点
  @Expose() matter: string; // 事项
  @Expose() status: string; // 状态
  @Expose() attachments: Material[]; // 附件
  @Expose() productCodes: string[]; // 产品码列表
  @Expose() productNames: string[]; // 产品名称列表
  @Expose() remark: string; // 备注
}
// 记录详情
export class FollowReplyRes {
  @Expose() id: number; // ID
  @Expose() name: string; // 姓名
  @Expose() pinyin: string; // 拼音
  @Expose() status: string; // 状态
  @Expose() followDate: string; // 记录日期
  @Expose() place: string; // 地点
  @Expose() matter: string; // 事项
  @Expose() accountName: string; // 销售渠道
  @Expose() nickName: string; // 销售渠道姓名
  @Expose() remark: string; // 备注
  @Expose() attachments: Material[]; // 附件
  @Expose() productCodes: string[]; // 产品码列表
  @Expose() productNames: string[]; // 产品名称列表
  @Expose() replyListVOS: ReplyListVOS[]; // 回复记录
}

export class ReplyListVOS {
  @Expose() id: number; // ID
  @Expose() accountName: string; // 当前登录回复账户
  @Expose() content: string; // 回复内容
  @Expose() attachments: Material[]; // 附件
  @Expose() time: string; // 回复时间
  @Expose() mySelf: boolean; // 是否是自己的记录
}
// 添加回复记录
export class InsertReply {
  @Expose() followId: number; // 客户记录ID
  @Expose() content: number; // 回复内容
  @Expose() attachments: Material[]; // 对话附件
}

export class DeleteFollow {
  @Expose() followId: number; // 客户记录表ID
}
// 修改记录
export class UpdateFollow {
  @Expose() id: number; // 跟进记录ID
  @Expose() preSalesId: number; // 联系人ID
  @Expose() followDate: string; // 记录日期
  @Expose() place: string; // 地点
  @Expose() matter: string; // 事项
  @Expose() status: string; // 状态
  @Expose() attachments: Material[]; // 附件
  @Expose() remark: string; // 备注
}
// 业务来源列表
export class BusinessSourceListRes {
  @Expose() id: number; // ID
  @Expose() businessSource: string; // 业务来源
  @Expose() deletable: boolean; // 是否可删除
}
