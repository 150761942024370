import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AnswerInfo,
  BankInfoReq, BookingOwnerReq, ContingentOwner,
  Customer,
  CustomerCorporate,
  CustomerType, DataDesensitization, DataMode,
  HealthInfoAnswer,
  HealthType,
  HeathInfo, Instance,
  QuestionInfo, Relationship,
  Underwriting,
  UnderwritingBeneficiaryReq,
  UnderwritingDetailResp
} from '../../api/types';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { UnderwritingProductPlanComponent } from '../components/underwriting-product-plan/underwriting-product-plan.component';
import { CustomerEditComponent } from '../../shared/component/customer-edit/customer-edit.component';
import { BeneficiariesEditComponent } from '../../shared/component/beneficiaries-edit/beneficiaries-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { CustomerReadComponent } from '../../shared/component/customer-read/customer-read.component';
import { plainToClass } from 'class-transformer';
import { UnderwritingService } from '../underwriting.service';
import { EventService } from '../../shared/service/event.service';
import { BeneficiariesReadComponent } from '../../shared/component/beneficiaries-read/beneficiaries-read.component';
import { UnderwritingInformationComponent } from '../components/underwriting-information/underwriting-information.component';
import { BankDetailComponent } from '../../shared/component/bank-detail/bank-detail.component';
import { HealthInfoComponent } from '../../shared/component/health-info/health-info.component';
import { Product } from '../../product/product-types';
import {DomainMetadataReadComponent} from '../../shared/component/domain-metadata-read/domain-metadata-read.component';
import { SecondHolderEditComponent } from '../../shared/component/second-holder-edit/second-holder-edit.component';
import { SecondHolderReadComponent } from '../../shared/component/second-holder-read/second-holder-read.component';
import { BusinessFormReadComponent } from '../../shared/component/business-form-read/business-form-read.component';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-underwriting-overview',
  templateUrl: './underwriting-overview.component.html',
  styleUrls: ['./underwriting-overview.component.less']
})
export class UnderwritingOverviewComponent implements OnInit {
  @Input()
  underwritingDetail: UnderwritingDetailResp;

  @Output()
  underwritingChange: EventEmitter<UnderwritingDetailResp> = new EventEmitter<UnderwritingDetailResp>();

  @Input()
  underwriting: Underwriting;
  product: Product;
  policyVisible = false;
  loading = false;
  remarkVisible = false;
  instance: Instance;

  heathInformation: HeathInfo;
  @Input()
  dataDesensitization: DataDesensitization = new DataDesensitization();
  @Input()
  mode = DataMode.OWN;
  workbench: Workbench;
  constructor(private drawerService: NzDrawerService,
              private translate: TranslateService,
              private eventService: EventService,
              private underwritingService: UnderwritingService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.instance = this.underwritingService.mockInstance;
    this.loadingHeathInformation(this.underwriting.underwritingNo);
    this.workbench = this.workbenchService.workbench;

  }

  // 产品计划
  onProductPlan(): void {
    const drawerRef = this.drawerService.create<UnderwritingProductPlanComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: UnderwritingProductPlanComponent,
      nzContentParams: {
        underwriting: this.underwriting,
        product: this.product
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      // const component = drawerRef.getContentComponent();
      // component.productPlanSaved
      //   .subscribe(
      //     productPlanEntity => {
      //       this.underwriting = productPlanEntity.underwriting;
      //       this.product = productPlanEntity.product;
      //       this.saveProductPlan(this.underwriting, drawerRef);
      //       // drawerRef.close();
      //     });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  // 投保人
  onUnderwritingOwner(): void {
    this.translate.get('Holder').subscribe(
      (holder: any) => {
        if (false) {
          // const drawerRef = this.drawerService.create<CustomerEditComponent, { value: any }, string>({
          //   nzWidth: 800,
          //   nzMaskClosable: true,
          //   nzContent: CustomerEditComponent,
          //   nzContentParams: {
          //     title: holder,
          //     mode: 'holder',
          //     modeType: 'underwriting',
          //     customerType: this.underwriting.ownerType,
          //     customer: this.underwriting.owner ?? new Customer(),
          //     customerCorporate: this.underwriting.companyOwner ?? new CustomerCorporate(),
          //     otherCustomer: this.underwriting.insured,
          //     otherCustomerCorporate: this.underwriting.companyInsured,
          //   },
          // });
          //
          // drawerRef.afterOpen.subscribe(() => {
          //   const component = drawerRef.getContentComponent();
          //   component.customerSaved
          //     .subscribe(
          //       customerEntity => {
          //         this.underwriting.ownerType = customerEntity.customerType;
          //         switch (customerEntity.customerType) {
          //           case CustomerType.INDIVIDUAL:
          //             this.underwriting.owner = customerEntity.customer;
          //             break;
          //           case CustomerType.CORPORATE:
          //             this.underwriting.companyOwner = customerEntity.corporateCustomer;
          //             break;
          //         }
          //         this.saveOwner(this.underwriting, drawerRef);
          //       });
          // });
          // drawerRef.afterClose.subscribe(data => {
          //   if (typeof data === 'string') {
          //   }
          // });
        } else {
          const drawerRef = this.drawerService.create<CustomerReadComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: CustomerReadComponent,
            nzContentParams: {
              title: holder,
              mode: 'holder',
              moduleSource: 'underwriting',
              underwriting: this.underwriting,
              underwritingDetail: this.underwritingDetail,
              customerType: this.underwriting.ownerType,
              customer: this.underwritingDetail.owner ?? new Customer(),
              customerCorporate: this.underwriting.companyOwner ?? new CustomerCorporate(),
              otherCustomer: this.underwriting.insured,
              otherCustomerCorporate: this.underwriting.companyInsured,
              contingentOwner: this.underwriting.contingentOwner ? this.underwriting.contingentOwner : new ContingentOwner(),
              dataDesensitization: this.dataDesensitization,
              modeType: this.mode,
              isEdit: this.underwriting.edit
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.customerSaved
              .subscribe(
                customerEntity => {
                  this.underwriting.ownerType = customerEntity.customerType;
                  switch (customerEntity.customerType) {
                    case CustomerType.INDIVIDUAL:
                      this.underwriting.owner = customerEntity.customer;
                      break;
                    case CustomerType.CORPORATE:
                      this.underwriting.companyOwner = customerEntity.corporateCustomer;
                      break;
                  }
                  this.saveOwner(this.underwriting, drawerRef);
                });
            // component.customerSaved
            //   .subscribe(
            //     customerEntity => {
            //       this.underwriting.ownerType = customerEntity.customerType;
            //       switch (customerEntity.customerType) {
            //         case CustomerType.INDIVIDUAL:
            //           this.underwriting.owner = customerEntity.customer;
            //           break;
            //         case CustomerType.CORPORATE:
            //           this.underwriting.companyOwner = customerEntity.corporateCustomer;
            //           break;
            //       }
            //       // this.saveOwner(this.underwriting, drawerRef);
            //     });
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        }

      });
  }

  onBookingInsured(): void {
    this.translate.get('Insured').subscribe(
      (insured: any) => {

          const drawerRef = this.drawerService.create<CustomerReadComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: CustomerReadComponent,
            nzContentParams: {
              title: insured,
              mode: 'insured',
              moduleSource: 'underwriting',
              underwriting: this.underwriting,
              underwritingDetail: this.underwritingDetail,
              isEdit: this.underwriting.edit,
              customerType: this.underwriting.insuredType,
              relationship: this.underwriting.relationship,
              customer: this.underwriting.insured ?? new Customer(),
              customerCorporate: this.underwriting.companyInsured ?? new CustomerCorporate(),
              otherCustomer: this.underwriting.owner,
              otherCustomerCorporate: this.underwriting.companyOwner,
              dataDesensitization: this.dataDesensitization,
              modeType: this.mode
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.customerSaved
              .subscribe(
                customerEntity => {
                  this.underwriting.ownerType = customerEntity.customerType;
                  switch (customerEntity.customerType) {
                    case CustomerType.INDIVIDUAL:
                      this.underwriting.owner = customerEntity.customer;
                      break;
                    case CustomerType.CORPORATE:
                      this.underwriting.companyOwner = customerEntity.corporateCustomer;
                      break;
                  }
                  this.saveInsured(this.underwriting, drawerRef);
                });
            // component.customerSaved
            //   .subscribe(
            //     customerEntity => {
            //       this.underwriting.insuredType = customerEntity.customerType;
            //       this.underwriting.relationship = customerEntity.relationship;
            //       switch (customerEntity.customerType) {
            //         case CustomerType.INDIVIDUAL:
            //           this.underwriting.insured = customerEntity.customer;
            //           break;
            //         case CustomerType.CORPORATE:
            //           this.underwriting.companyInsured = customerEntity.corporateCustomer;
            //           break;
            //       }
            //     });
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });

      });
  }
  onUnderwritingBeneficiaries(): void {
    this.translate.get('Beneficiaries').subscribe(
      (title: any) => {
          const drawerRef = this.drawerService.create<BeneficiariesReadComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: BeneficiariesReadComponent,
            nzContentParams: {
              title,
              beneficiaries: this.underwriting.beneficiaries,
              insured: this.underwriting.insured,
              dataDesensitization: this.dataDesensitization,
              modeType: this.mode
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.beneficiariesSaved
              .subscribe(
                data => {
                  this.underwriting.beneficiaries = data;
                  // this.saveBeneficiaries(this.underwriting, drawerRef);
                });
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
      });
  }

  onSecondHolder(): void {
    this.translate.get('SecondHolder').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderReadComponent,
          nzContentParams: {
            title,
            dataDesensitization: this.dataDesensitization,
            modeType: this.mode,
            contingentOwner: this.underwriting.contingentOwner ? this.underwriting.contingentOwner : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onSecondInsured(): void {
    this.translate.get('SecondInsured').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderReadComponent,
          nzContentParams: {
            title,
            dataDesensitization: this.dataDesensitization,
            modeType: this.mode,
            contingentOwner: this.underwriting.contingentInsured ? this.underwriting.contingentInsured : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  saveInsured(underwriting: Underwriting, drawerRef: NzDrawerRef<CustomerEditComponent | CustomerReadComponent, string>): void {
    drawerRef.close();
    this.loadBookingDetail(underwriting.underwritingNo);
    // const underwritingInsuredReq = plainToClass(UnderwritingDetailResp, underwriting, {excludeExtraneousValues: true});
    // this.loading = true;
    // this.underwritingService.insured(underwritingInsuredReq)
    //   .subscribe(
    //     progress => {
    //       drawerRef.close();
    //       this.loading = false;
    //       this.loadBookingDetail(underwriting.underwritingNo);
    //     },
    //     error => {
    //       this.loading = false;
    //     });
  }

  saveOwner(underwriting: Underwriting, drawerRef: NzDrawerRef<CustomerEditComponent | CustomerReadComponent, string>): void {
    drawerRef.close();
    // this.loadBookingDetail(underwriting.underwritingNo);
    const underwritingInsuredReq = plainToClass(UnderwritingDetailResp, underwriting, {excludeExtraneousValues: true});
    this.loading = true;
    this.underwritingService.Owner(underwritingInsuredReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(underwriting.underwritingNo);
        },
        error => {
          this.loading = false;
        });
  }

  loadBookingDetail(underwritingNo: string): void {
    this.loading = true;
    this.underwritingService.detail(underwritingNo)
      .subscribe(
        underwritingDetail => {
          this.loading = false;
          const underwriting = underwritingDetail.toUnderwriting();
          this.underwritingDetail = underwritingDetail;
          this.underwritingChange.emit(underwritingDetail);
          this.underwriting = underwriting;
          // 通知列表頁面刷新
          this.eventService.bookingsReload.next();
        },
        error => {
          this.loading = false;
        });
  }

  loadingHeathInformation(underwritingNo: string): void {
    this.loading = true;
    this.underwritingService.question(underwritingNo)
      .subscribe(
        health => {
          this.loading = false;
          const that = this;
          const healthDetail = plainToClass(HeathInfo, health, {excludeExtraneousValues: true});
          // 初始化
          that.heathInformation = new HeathInfo();
          // 基础信息
          that.heathInformation = Object.assign(that.heathInformation, {
            id: healthDetail.id,
            answerOwnerId: healthDetail.answerOwnerId,
            answerInsuredId: healthDetail.answerInsuredId,
            ownerTotal: 0,
            insuredTotal: 0
          });
          if (healthDetail.questionOwner && healthDetail.questionOwner.length > 0) {
            healthDetail.questionOwner.forEach(question => {
              that.heathInformation.ownerTotal += question.questionnaire.length;
            });
            that.heathInformation.questionOwner = that.getHealthInformationQuestion(healthDetail.questionOwner, healthDetail.answerOwner);
          }
          if (healthDetail.questionInsured && healthDetail.questionInsured.length > 0) {
            healthDetail.questionInsured.forEach((item) => {
              that.heathInformation.insuredTotal = that.heathInformation.insuredTotal + item.questionnaire.length;
            });
            // 处理受保人问题答案数据
            that.heathInformation.questionInsured = that.getHealthInformationQuestion(healthDetail.questionInsured, healthDetail.answerInsured);
          }
        },
        error => {
          this.loading = false;
        }
      );

  }

  onUnderwritingInformation(): void {
    const drawerRef = this.drawerService.create<UnderwritingInformationComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: UnderwritingInformationComponent,
      nzContentParams: {
        underwriting: this.underwriting,
      },
    });

    drawerRef.afterOpen.subscribe(() => {


    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onBankDetail(): void {
    const drawerRef = this.drawerService.create<BankDetailComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BankDetailComponent,
      nzContentParams: {
        bankInfo: this.underwriting.bank,
        edit: this.underwriting.edit
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.bankSaved
        .subscribe(data => {
          this.underwriting.bank = data;
          this.saveBank(this.underwriting, drawerRef);
        });

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  saveBeneficiaries(underwriting: Underwriting, drawerRef: NzDrawerRef<BeneficiariesEditComponent, string>): void {
    const beneficiaryReq = plainToClass(UnderwritingBeneficiaryReq, underwriting, {excludeExtraneousValues: true});
    this.loading = true;
    this.underwritingService.beneficiary(beneficiaryReq).subscribe(
      progress => {
        drawerRef.close();
        this.loading = false;
        this.loadBookingDetail(underwriting.underwritingNo);
      },
      error => {
        this.loading = false;
      });
  }

  private saveBank(underwriting: Underwriting, drawerRef: NzDrawerRef<BankDetailComponent, string>): void {
    const bankInfoReq = plainToClass(BankInfoReq, underwriting);
    this.loading = true;
    this.underwritingService.bank(bankInfoReq).subscribe(
      progress => {
        drawerRef.close();
        this.loading = false;
        this.loadBookingDetail(underwriting.underwritingNo);
      },
      error => {
        this.loading = false;
      });
  }

  close(): void {
    this.policyVisible = false;
  }

  onPolicyDelivery(): void {

    this.policyVisible = true;
  }

  onRemark(): void {
    this.remarkVisible = true;
  }

  remarkClose(): void {
    this.remarkVisible = false;
  }

  onOther(): void {
    const drawerRef = this.drawerService.create<DomainMetadataReadComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: DomainMetadataReadComponent,
      nzContentParams: {
        domainMetadata: this.underwriting.domainMetadataVOS,
      },
    });
  }

  onHealthInfo(): void {
    this.translate.get('HealthInformation').subscribe(
      (title: any) => {
        const healthType: HealthType = {
          ownerType: this.underwriting.ownerType,
          relationship: this.underwriting.relationship,
          edit: this.underwriting.edit
        };
        const drawerRef = this.drawerService.create<HealthInfoComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: HealthInfoComponent,
          nzContentParams: {
            heathInformation: this.heathInformation,
            healthType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.healthSaved.subscribe(
            param => {
              let folist = [];
              if (healthType.ownerType === CustomerType.CORPORATE) {
                folist = [{
                  id: param.answerInsuredId,
                  role: 'INSURED',
                  answer: JSON.stringify(param.questionInsured)
                }];
              }
              if (healthType.relationship === Relationship.OWN) {
                folist = [{
                  id: param.answerOwnerId,
                  role: 'OWNER',
                  answer: JSON.stringify(param.questionOwner)
                }];
              }
              let params: HealthInfoAnswer = new HealthInfoAnswer();
              params = {
                bookingNo: undefined,
                policyNo: undefined,
                policyId: undefined,
                underwritingNo: this.underwriting.underwritingNo,
                questionId: this.heathInformation.id,
                foList: folist.length > 0 ? folist : [{
                  id: this.heathInformation.answerOwnerId,
                  role: 'OWNER',
                  answer: JSON.stringify(param.questionOwner)
                },
                  {
                    id: this.heathInformation.answerInsuredId,
                    role: 'INSURED',
                    answer: JSON.stringify(param.questionInsured)
                  }
                ]
              };
              this.underwritingService.underwritingAnswer(params).subscribe(
                data => {
                  drawerRef.close();
                  this.loading = false;
                  this.loadBookingDetail(this.underwriting.underwritingNo);
                  this.loadingHeathInformation(this.underwriting.underwritingNo);
                }, error => {
                  this.loading = false;
                }
              );

            }
          );
        });


        drawerRef.afterClose.subscribe(() => {

        });
      },
      error => {
      }
    );


  }

  getHealthInformationQuestion(qusetionEntity: QuestionInfo[], answerEntity: AnswerInfo[]): QuestionInfo[] {
    const that = this;
    if (!answerEntity) {
      answerEntity = [];
    }
    let questionTotal = 0;
    // 循环问题种类
    qusetionEntity.forEach((item) => {
      // 循环具体问题
      item.questionnaire.forEach((questionnaireItem) => {
        // 记录当前问题条数
        questionnaireItem.index = ++questionTotal;
        // 查找问题数组和答案数组，根据id关联
        // const answerItem = _.find(answerEntity, function(o) {
        //   return o.questionId == questionnaireItem.questionId;
        // });

        const answerItem = answerEntity.find(o => {
          return o.questionId === questionnaireItem.questionId;
        });
        // 判空
        questionnaireItem.value = answerItem ? answerItem.value : null;
        questionnaireItem.answer.forEach((answer) => {
          if (questionnaireItem.value === answer.answerId && answer.attachment != null) {
            if (answer.attachment.type === 'form') {
              const headers = [];
              answer.attachment.headers.forEach((headerItem) => {
                // const inItem = _.find(answer.attachment.headers, function(o) {
                //   return o.attachmentId == headerItem.attachmentId;
                // });
                const inItem = answerItem.attachment.headers.find(o => {
                  return o.attachmentId === headerItem.attachmentId;
                });
                if (!!inItem) {
                  const params = that.getParams(headerItem, inItem);
                  headers.push(params);
                }
              });
              answer.attachment.headers = headers;
            } else {
              const inValue = [];
              answerItem.attachment.values.forEach((valueItem) => {
                const inValueSub = [];
                valueItem.forEach((vItem) => {
                  const headerItem = answer.attachment.headers.find(o => {
                    return o.attachmentId === vItem.attachmentId;
                  });
                  if (headerItem) {
                    const params = that.getParams(headerItem, vItem);
                    inValueSub.push(params);
                  }
                });
                inValue.push(inValueSub);
              });
              if (inValue.length < 1) {
                inValue.push(answer.attachment.headers);
              }
              answer.attachment.values = inValue;
            }
          } else {
            if (answer.attachment != null && answer.attachment.type === 'table') {
              answer.attachment.values = [];
              answer.attachment.values.push(answer.attachment.headers);
            }
          }
        });
      });
    });
    return qusetionEntity;
  }


  getParams(header, item): { inputValue: any; startTime: any; attachmentId: any; label: any; endTime: any; type: any; value: any } {
    const params = {
      attachmentId: header.attachmentId || null,
      label: header.label || null,
      value: header.value || null,
      type: header.type || null,
      required: header.required || false,
      inputValue: item.inputValue || null,
      startTime: item.startTime || null,
      endTime: item.endTime || null
    };

    // if (header.type === 'date' && header.value === 'range' && !!item.startTime && !!item.endTime) {
    //   params.inputValue = [item.startTime, item.endTime];
    // }
    return params;
  }


  get isShowBeneficiary(): boolean {
    const instance = this.underwritingService.instance;
    return !(instance && instance.key === 'fone');
  }


  openBusinessForm(type: string): void {
    const drawerRef = this.drawerService.create<BusinessFormReadComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BusinessFormReadComponent,
      nzContentParams: {
        policyNo: this.underwriting.underwritingNo,
        formType: type,
        moduleType: 'underwriting',
        isRead: this.underwriting.edit
      },
    });

  }

  get isShowItem(): boolean {
    const instance = this.underwritingService.instance;
    return (instance?.key === 'cash' || instance?.key === 'dev') && (this.underwritingDetail.ownerType !== 'CORPORATE' && this.underwritingDetail.insuredType !== 'CORPORATE');
  }

}
