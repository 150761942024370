import {Component, OnInit} from '@angular/core';
import { ContractDetails, ContractList, ContractSearch, ExcelDownloadQuery } from '../contract-type';
import {ContractService} from '../contract.service';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import {formatDate} from '@angular/common';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ContractDetailComponent} from '../contract-detail/contract-detail.component';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { ProductCompany } from '../../product/product-types';
import { ProductService } from '../../product/product.service';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.less']
})
export class ContractListComponent implements OnInit {
  spinning = false;
  search: ContractSearch = new ContractSearch();
  contractList: ContractList[];
  filterPopoverVisible = false;
  typeOptions: PropertySelectOption[] = [];
  EffectDates: number[];
  deadline: number[];
  workbench: Workbench = new Workbench();
  companyOptions: PropertySelectOption[] = [];
  companies: ProductCompany[];

  constructor(private contractService: ContractService,
              private metadataService: MetadataService,
              private nz: NzDrawerService,
              private productService: ProductService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.typeOptions = [];
    const contractType = this.metadataService.values('contractType');

    for (const contract of contractType) {
      this.typeOptions.push(new PropertySelectOption(contract.value, contract.key));
    }
    this.spinning = true;
    this.contractService.list(this.search).subscribe(
      data => {
        console.log(data);
        this.contractList = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });

  }

  onSearch($event: number): void {
    this.filterPopoverVisible = false;
    this.openSearch();
  }

  onReload(): void {
    this.search = new ContractSearch();
    this.EffectDates = undefined;
    this.deadline = undefined;
    this.spinning = true;
    this.contractService.list(this.search).subscribe(
      data => {
        this.contractList = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );
  }

  openSearch(): void {
    this.spinning = true;
    this.contractService.list(this.search).subscribe(
      data => {
        this.contractList = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );
  }

  onSearchDateChange(time: number[]): void {
    console.log(time);
    if (time) {
      this.deadline = time;
      this.search.effectiveStartDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.search.effectiveEndDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    }

  }

  onInspectDateChange(time: number[]): void {
    if (time) {
      this.EffectDates = time;
      this.search.invalidStartDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.search.invalidEndDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    }
  }

  onDetail(list: ContractList): void {
    console.log(list.contractMode);
    const drawerRef = this.nz.create<ContractDetailComponent, { value: ContractDetails }, string>({
      nzWidth: 1000,
      nzContent: ContractDetailComponent,
      nzContentParams: {
        contractList: list,
        contractMode: list.contractMode,
        title: list.name
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      // component.underwritingSearch
      //   .subscribe(
      //     search => {
      //       this.search = search;
      //       this.onSearch(this.search.pageNum);
      //       drawerRef.close();
      //     }
      //   );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onExportExcel(list: ContractList): void {
    const param = new ExcelDownloadQuery();
    if (list.salesContractViewId) {
      param.salesContractViewId = list.salesContractViewId;
    } else {
      param.contractMasterRateId = list.contractMasterRateId;
      param.id = list.id;
      param.contractSalesAllocationId = list.contractSalesAllocationId;
    }
    this.spinning = true;
    this.contractService.downloadExcel(param)
      .subscribe(
        data => {
          console.log(data);
          const link = document.createElement('a');
          const blob = new Blob([data.body], {type: 'application/vnd.ms-excel'});
          let fileName = data.headers.get('Content-Disposition').split(';')[1].split('filename=')[1];
          // const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
          // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
          if (fileName) {
            fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
          }
          console.log(fileName);

          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // const url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
          // window.open(url);
          this.spinning = false;
        }, error => {
          this.spinning = false;
        });
  }
}
