<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'ProductPlan' | translate}}
  </div>

  <div class="section-wrapper">

    <!-- 产品 -->
    <app-property-pick [title]="'InsuranceProduct' | translate"
                       [required]="true"
                       appThrottleClick (throttleClick)="onProductSelection()"
                       [value]="product?.productName"></app-property-pick>
    <!-- 地区 -->
    <app-property-text *ngIf="instance.isHarvest()" [title]="'CompanyName' | translate"
                       style="cursor: no-drop;"
                       [value]="product?.companyName"></app-property-text>
      <!-- 地区 -->
    <app-property-text [title]="'Region' | translate"
                       style="cursor: no-drop;"
                       [value]="product?.region"></app-property-text>
    <!-- 货币 -->
    <app-property-select [title]="'Currency' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Currency' | translate)"
                         [required]="true"
                         [options]="currencyOptions"
                         [(value)]="booking.productPlan.currency"></app-property-select>

    <!-- 供款年期 -->
    <app-property-select *ngIf="!booking.custom"
                          [title]="'PaymentTerm' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('PaymentTerm' | translate)"
                         [required]="true"
                         [options]="paymentTermOptions"
                         [(value)]="booking.productPlan.productYear"></app-property-select>
    <!-- 资管产品供款年期 -->
    <app-property-select *ngIf="booking.custom"
                         [title]="'CustomPaymentTerm' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('CustomPaymentTerm' | translate)"
                         [required]="true"
                         [options]="customPaymentTermOptions"
                         [(value)]="booking.productPlan.customPaymentTerm"></app-property-select>
    <!-- 付款頻率 -->
    <app-property-select [title]="'PaymentFrequency' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('PaymentFrequency' | translate)"
                         [required]="!instance.isBetterOrDev()"
                         [options]="paymentFrequencyOptions"
                         [(value)]="booking.productPlan.frequency"></app-property-select>

    <!-- 保費 -->
    <app-property-input-number [title]="'ModalPremium' | translate"
                               [required]="true"
                               [placeholder]="'ModalPremium' | translate"
                               [(value)]="booking.productPlan.premium"></app-property-input-number>

    <!-- 保額 -->
    <app-property-input-number [title]="'SumAssured' | translate"
                               [required]="!instance.isBetterOrDev()"
                               [placeholder]="'SumAssured' | translate"
                               [bottomLineVisible]="false"
                               [(value)]="booking.productPlan.sumAssured"></app-property-input-number>
  </div>

  <div class="section-wrapper">
    <app-product-riders-edit [currency]="booking.productPlan.currency" [primaryProduct]="product" [(riders)]="booking.riders"></app-product-riders-edit>
  </div>
  <div *ngIf="isDetail ? isDetail : true">
<!--    instance.isBaylightOrDev() || instance.isHarvest() || instance.isIAM()-->
    <div class="section-wrapper">
        <!-- 预缴保费 -->
        <app-property-select [title]="'Prepay' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('Prepay' | translate)"
                             [options]="prepayOptions"
                             [required]="instance.isHarvest() || instance.isIAM() || instance.isFone()"
                             [(value)]="booking.prepay"></app-property-select>

        <!-- 预付额 -->
        <app-property-input-number *ngIf="booking.prepay" [title]="'PrepayPremium' | translate"
                                   [placeholder]="'PrepayPremium' | translate"
                                   [(value)]="booking.prepayPremium">
        </app-property-input-number>

        <!-- 首期付款方式 -->
        <app-property-select [title]="'InitialPaymentMethod' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('InitialPaymentMethod' | translate)"
                             [options]="paymentTypeOptions"
                             [(value)]="booking.productPlan.firstPaymentType">
        </app-property-select>

        <!-- 续期付款方式 -->
        <app-property-select [title]="'RenewalPaymentMethod' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('RenewalPaymentMethod' | translate)"
                             [options]="paymentTypeOptions"
                             [(value)]="booking.productPlan.restPaymentType">
        </app-property-select>

        <!-- 红利分配方式 -->
        <app-property-select [title]="'DividendDistributionMethod' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('DividendDistributionMethod' | translate)"
                             [options]="dividendOptions"
                             [(value)]="booking.productPlan.dividends">
        </app-property-select>
        <!-- 是否需提前保单生效日 -->
        <app-property-yes-or-not [title]="'AccelerateEffectiveDate' | translate"
                                 [required]="instance.isIAM()"
                                 [(value)]="booking.backtrack">
        </app-property-yes-or-not>

        <!-- 保单生效日 -->
        <app-property-datetime *ngIf="booking.backtrack"
                               [title]="'PolicyEffectiveDate' | translate"
                               [(value)]="booking.policyDate">
        </app-property-datetime>

        <!-- BPO -->
        <app-property-yes-or-not [title]="'BPO' | translate"
                                 [(value)]="booking.guarantee">
        </app-property-yes-or-not>

      <app-property-yes-or-not *ngIf="!instance.isFone()"
                               [title]="'EnjoyPolicyDiscounts' | translate"
                               [(value)]="booking.enjoyPolicyDiscount">
      </app-property-yes-or-not>


      <app-property-datetime-string *ngIf="!instance.isFone()"
                                    [title]="'PromotionExpirationDate' | translate"
                                    [formatter]="'yyyy-MM-dd'"
                                    [showTime]="null"
                                    [bottomLineVisible]="false"
                                    [(value)]="booking.discountEventsDeadlinesDate">
      </app-property-datetime-string>
    </div>
  </div>


  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
