<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div [class]="disabled ? 'property-row property-disable' : 'property-row'">
      <div class="property-title-row" [style]="titleColor ? ('color:' + titleColor) : ''" *ngIf="title">
        {{ title }}
      </div>
      <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      <div class="property-value-row">
        <nz-select style="display: block;" [(ngModel)]="value" class="select" nzAllowClear nzBorderless
                   [disabled]="disabled"
                   [nzMode]="mode"
                   [nzMaxTagCount]="maxTag"
                   (nzScrollToBottom)="onScrollToButton()"
                   [nzLoading]="loading"
                   [nzPlaceHolder]="placeholder"
                   [nzAllowClear]="allowClear"
                   [nzShowSearch]="supportSearch"
                   (nzOnSearch)="onSearch($event)"
                   [nzDropdownRender]="dropdownRender">
          <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
        <ng-template #dropdownRender>
          <nz-spin *ngIf="loading"></nz-spin>
        </ng-template>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
  <div *ngSwitchCase="'vertical'">
    <div [class]="disabled ? 'property-column property-disable' : 'property-column'">
      <div class="property-title-column" [style]="titleColor ? ('color:' + titleColor) : ''" *ngIf="title">
        {{ title }}
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      </div>
      <div class="property-value-column">
        <nz-select style="display: block;" [(ngModel)]="value" nzAllowClear nzBorderless
                   [disabled]="disabled"
                   [nzMode]="mode"
                   [nzMaxTagCount]="maxTag"
                   (nzScrollToBottom)="onScrollToButton()"
                   [nzLoading]="loading"
                   [nzPlaceHolder]="placeholder"
                   [nzShowSearch]="supportSearch"
                   (nzOnSearch)="onSearch($event)"
                   [nzDropdownRender]="dropdownRender">
          <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
        <ng-template #dropdownRender>
          <nz-spin *ngIf="loading"></nz-spin>
        </ng-template>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
</div>
