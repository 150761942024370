import { Expose } from 'class-transformer';
import { Material, PagedReq } from '../api/types';
import { Product } from '../product/product-types';

export enum InformationType {
  ADMINISTRATION_NOTICE = 'ADMINISTRATION_NOTICE',
  PRODUCT_NEWS = 'PRODUCT_NEWS',
  GENERAL_INFO = 'GENERAL_INFO',
  PRODUCT_PROMOTION = 'PRODUCT_PROMOTION',
  ORIGINAL = 'ORIGINAL',
  IMPORTANT_NOTICE = 'IMPORTANT_NOTICE',
  TRAINING_NOTICE = 'TRAINING_NOTICE',
  MARKET_INFORMATION = 'MARKET_INFORMATION'
}

export class Information {
  @Expose() id: number; // 资讯编号
  @Expose() title: string; // 消息标题
  @Expose() type: InformationType; // 资讯类型: ADMINISTRATION_NOTICE-行政通知;PRODUCT_NEWS-产品动态;GENERAL_INFO-一般资讯
  @Expose() time: number; // 时间
  @Expose() productCodes: string[]; // 产品码
  @Expose() productNames: string[]; // 产品名
  @Expose() companyCode: string; // 保险公司
  @Expose() companyName: string; // 保险公司名
  @Expose() tags: string[]; // 保险公司名
  @Expose() attachments: Material[]; // 附件
  @Expose() content: string; // 內容
  @Expose() startEffectiveDate: number; // 开始生效日
  @Expose() endEffectiveDate: number; // 结束生效日
  @Expose() shareCount: number; // 分享次数
  @Expose() browseNumber: number; // 浏览次数
  @Expose() browseCount: number; // 总浏览次数
  @Expose() effective: boolean; // 是否生效
}

export class InformationSearch extends PagedReq {
  @Expose() title: string; // 消息标题
  @Expose() type: InformationType; // 资讯类型: ADMINISTRATION_NOTICE-行政通知;PRODUCT_NEWS-产品动态;GENERAL_INFO-一般资讯
  @Expose() startTime: number; // 开始时间
  @Expose() endTime: number; // 结束时间
  @Expose() tag: string; // 标签
  @Expose() effective: boolean; // 是否生效
  @Expose() companyCode: string; // 公司码
  @Expose() productCode: string; // 产品码
  @Expose() regions: [];
  @Expose() product: Product = new Product(); // 保险产品
}

export class InformationListResp {
  @Expose() id: number; // 资讯编号
  @Expose() title: string; // 消息标题
  @Expose() type: InformationType; // 资讯类型: ADMINISTRATION_NOTICE-行政通知;PRODUCT_NEWS-产品动态;GENERAL_INFO-一般资讯
  @Expose() time: number; // 时间
  @Expose() tags: string[]; // 标签
  @Expose() productCodes: string[]; // 产品码
  @Expose() productNames: string[]; // 产品名
  @Expose() companyCode: string; // 保险公司
  @Expose() companyName: string; // 保险公司名
  @Expose() browseCount: number; // 浏览次数
  @Expose() shareCount: number; // 分享次数
  @Expose() startEffectiveDate: number; // 开始生效日
  @Expose() endEffectiveDate: number; // 结束生效日
  @Expose() effective: boolean; // 是否生效
  @Expose() top: boolean; // 是否置顶
}
