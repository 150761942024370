import { Component, Input, OnInit } from '@angular/core';
import { DataMode, Locale, PagedResp } from '../../api/types';
import { BvList, BvSearch } from '../bv-types';
import { NewFnaService } from '../../new-fna/new-fna.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkbenchService } from '../../workbench/workbench.service';
import { BvService } from '../bv.service';
import { BookingSearchComponent } from '../../booking/booking-search/booking-search.component';
import { BookingSearch } from '../../booking/booking-types';
import { BvSearchComponent } from '../bv-search/bv-search.component';
import { LocaleService } from '../../core/locale.service';

@Component({
  selector: 'app-bv-module-list',
  templateUrl: './bv-module-list.component.html',
  styleUrls: ['./bv-module-list.component.less']
})
export class BvModuleListComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;
  loading = false;
  spinning = false;
  search: BvSearch = new BvSearch();
  bvListData: PagedResp<BvList>;
  filterPopoverVisible = false;
  constructor(private bvService: BvService,
              private drawerService: NzDrawerService,
              private localeService: LocaleService,
              private modalService: NzModalService,
              private metadataService: MetadataService,
              private translate: TranslateService,
              private router: Router,
              private toastr: ToastrService,
              private workbenchService: WorkbenchService) { }

  ngOnInit(): void {
    this.onSearch(null);
  }

  onReload(): void {
    this.search = new BvSearch();
    this.onSearch(null);
  }

  onSearch(pageNum: number): void {
    if (pageNum) {
      this.search.pageNum = pageNum;
    }
    this.loading = true;
    this.search.mySelf = this.mode === DataMode.OWN ? '1' : null;
    this.bvService.list(this.search)
      .subscribe(list => {
          console.log(list);
          this.bvListData = JSON.parse(JSON.stringify(list));
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onBVSearch(): void {
    console.log('onBVSearch');
    const drawerRef = this.drawerService.create<BvSearchComponent, { value: BookingSearch }, string>({
      nzWidth: 800,
      nzContent: BvSearchComponent,
      nzContentParams: {
        search: this.search,
        mode: this.mode,
        Dates: this.search.policyDateStart ? [this.search.policyDateStart, this.search.policyDateEnd] : null
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.bvSearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(1);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  language(): boolean {
    let str = true;
    switch (this.localeService.locale) {
      case Locale.cn:
      case Locale.hk:
        str = true;
        break;
      case Locale.en:
      case Locale.jp:
      case Locale.ko:
        str = false;
        break;
    }
    return str;
  }
}
