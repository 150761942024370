import {Injectable} from '@angular/core';
import {HttpService} from '../core/http.service';
import {BaseService} from '../base/base.service';
import {
  ContractDetail,
  ContractDetails,
  ContractList,
  ContractQuery,
  ContractSearch,
  ExcelDownloadQuery
} from './contract-type';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse, PagedResp} from '../api/types';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { Fna } from '../fna/fna-types';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * 获取销售的合约列表
   * @param search Query
   */
  list(search: ContractSearch): Observable<ContractList[]> {
    let httpParams = new HttpParams();

    if (search.effectiveEndDate != null) {
      httpParams = httpParams.set('effectiveEndDate', search.effectiveEndDate.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    if (search.effectiveStartDate != null) {
      httpParams = httpParams.set('effectiveStartDate', search.effectiveStartDate.toString());
    }
    if (search.companyCodes != null && search.companyCodes.length) {
      httpParams = httpParams.set('companyCodes', search.companyCodes.toString());
    }
    if (search.invalidEndDate != null) {
      httpParams = httpParams.set('invalidEndDate', search.invalidEndDate.toString());
    }
    if (search.invalidStartDate != null) {
      httpParams = httpParams.set('invalidStartDate', search.invalidStartDate.toString());
    }
    return this.http.get<ApiResponse<ContractList[]>>(API.contractV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  detail(search: ContractQuery): Observable<ContractDetails> {
    let httpParams = new HttpParams();
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    if (search.contractMasterRateId != null) {
      httpParams = httpParams.set('contractMasterRateId', search.contractMasterRateId.toString());
    }
    if (search.contractSalesAllocationId != null) {
      httpParams = httpParams.set('contractSalesAllocationId', search.contractSalesAllocationId.toString());
    }
    if (search.salesContractViewId != null) {
      httpParams = httpParams.set('salesContractViewId', search.salesContractViewId.toString());
    }
    if (search.baseOn != null) {
      httpParams = httpParams.set('baseOn', search.baseOn.toString());
    }
    if (search.id != null) {
      httpParams = httpParams.set('id', search.id.toString());
    }
    return this.http.get<ApiResponse<ContractDetails>>(API.contractV3 + '/detail', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(ContractDetails, data.body.data, {exposeDefaultValues: true});
    }));
  }

  /**
   * 导出Excel
   * @param search Query
   */

  downloadExcel(search: ExcelDownloadQuery): Observable<any> {
    let httpParams = new HttpParams();
    // if (search.productCode != null) {
    //   httpParams = httpParams.set('productCode', search.productCode.toString());
    // }
    if (search.contractMasterRateId != null) {
      httpParams = httpParams.set('contractMasterRateId', search.contractMasterRateId.toString());
    }
    if (search.contractSalesAllocationId != null) {
      httpParams = httpParams.set('contractSalesAllocationId', search.contractSalesAllocationId.toString());
    }
    if (search.salesContractViewId != null) {
      httpParams = httpParams.set('salesContractViewId', search.salesContractViewId.toString());
    }
    if (search.id != null) {
      httpParams = httpParams.set('id', search.id.toString());
    }
    return this.httpClient.get(API.contractV3 + '/sales-download', {
      params: httpParams,
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((date: any) => {
        console.log(date);
        return date;
      }));
  }

  // exportModal(paramMap): void {
  //   // responseType: 'blob' 获取了文件的二进制流
  //   this.http.post('api/s01cross/b04/exportmonfinana', paramMap,
  //     {responseType: 'blob', observe: 'response'}).subscribe(data => {
  //     const link = document.createElement('a');
  //     const blob = new Blob([data.body], {type: 'application/vnd.ms-excel'});
  //     let fileName = data.headers.get('Content-Disposition').split(';')[1].split('filename=')[1];
  //     const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
  //     // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
  //     if (fileNameUnicode) {
  //       fileName.replace( /\+/g, '%20' )
  //       fileName = decodeURIComponent(fileNameUnicode.split('\'\'')[1]);
  //     }
  //     link.setAttribute('href', window.URL.createObjectURL(blob));
  //     link.setAttribute('download', fileName);
  //     link.style.visibility = 'hidden';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   });
  // }
}
