import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse, PagedResp } from '../api/types';
import { HttpParams } from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import {
  PremiumFinancing,
  PremiumFinancingCreate,
  PremiumFinancingProposalSearch,
  PremiumFinancingSearch
} from './premium-financing-types';
import { SelectListQuery } from '../proposal-custom/proposal-custom-types';
import { Proposal } from '../proposal/proposal-types';

@Injectable({
  providedIn: 'root'
})
export class PremiumFinancingService {

  constructor(private http: HttpService) {
  }

  /**
   * 保费融资列表
   * @param search PremiumFinancingSearch
   */
  list(search: PremiumFinancingSearch): Observable<PagedResp<PremiumFinancing>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    return this.http.get<ApiResponse<PagedResp<PremiumFinancing>>>(API.premiumFinancingV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(PagedResp, data.body.data) as PagedResp<PremiumFinancing>;
    }));
  }

  proposalList(search: PremiumFinancingProposalSearch): Observable<PagedResp<Proposal>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    return this.http.get<ApiResponse<PagedResp<Proposal>>>(API.premiumFinancingV3 + `/proposal-list`, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  generate(premiumFinancing: PremiumFinancing): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.premiumFinancingV3 + `/create`, premiumFinancing, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  // /app/v3/premium-financing/create-mercer
  created(premiumFinancing: PremiumFinancingCreate): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.premiumFinancingV3 + `/create-mercer`, premiumFinancing, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  retry(id: any): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.premiumFinancingV3 + `/retry/` + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  delete(id: any): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.premiumFinancingV3 + `/delete/` + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
