<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{'PolicyDetail' | translate}}
  </div>

  <div style="display: flex; flex-direction: row; align-items: end">
    <div style="flex: 1; font-weight: bold; font-size: 18px; color: #404040">
      #{{policyDetail.policyNo}}</div>
    <div>
      <div [ngSwitch]="policyDetail.status">
        <div>
          <span *ngSwitchCase="'INFORCE'" class="text-success">●</span>
          <span *ngSwitchCase="'LAPSED'" class="text-warning">●</span>
          <span *ngSwitchCase="'MATURED'" class="text-warning">●</span>
          <span *ngSwitchCase="'TRANSFERRED'" class="text-warning">●</span>
          <span *ngSwitchCase="'SURRENDERED'" class="text-cancel">●</span>
          <span *ngSwitchCase="'DECEASED'" class="text-cancel">●</span>
          <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
          <span *ngSwitchCase="'CLOSED'" class="text-cancel">●</span>
          <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
          <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
          <span *ngSwitchCase="'POSTPONED'" class="text-warning">●</span>
          <span *ngSwitchCase="'TERMINATED'" class="text-cancel">●</span>
          <span *ngSwitchCase="'COOLOFF_SURRENDERING'" class="text-warning">●</span>
          <span *ngSwitchCase="'COOLOFF_SURRENDERED'" class="text-warning">●</span>
          <span *ngSwitchCase="'SURRENDERING'" class="text-warning">●</span>
          <span *ngSwitchCase="'EXPIRATION'" class="text-cancel">●</span>
          <span *ngSwitchCase="'TRANSFER_IN'" class="text-warning">●</span>
          <span *ngSwitchCase="'TRANSFER_IN_FAIL'" class="text-cancel">●</span>
          <span *ngSwitchCase="'REDUCED_PAID_UP'" class="text-warning">●</span>
          <span *ngSwitchCase="'POLICY_LOANS'" class="text-warning">●</span>
          <span *ngSwitchCase="'PREMIUM_HOLIDAY'" class="text-warning">●</span>
          <span *ngSwitchCase="'DORMANT'" class="text-warning">●</span>
          <span *ngSwitchCase="'RIDER_LAPSED'" class="text-warning">●</span>
          <span> {{ policyDetail.status | metadataTranslate: 'policyStatus' }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="booking-detail-header">
    <div>
      <img width="60" style="border-radius: 3px; margin-right: 5px" [src]="policyDetail.companyLogo" alt=""/>
    </div>
    <div style="flex: 1">
      <!-- 客戶姓名 -->
      <app-property-text [title]="'ClientName' | translate"
                         [isEncryption]="isDynamicEncryption('name')"
                         [value]="(policyDetail.ownerName + ' ' + policyDetail.ownerPinyin)"></app-property-text>
      <!-- 产品名称 -->
      <app-property-text [title]="'ProductName' | translate"
                         [isEncryption]="isDynamicEncryption('name')"
                         [value]="policyDetail.productName"></app-property-text>
      <!-- 供款年期 -->
      <app-property-text  *ngIf="!policyDetail.custom" [title]="'PaymentTerm' | translate"
                         [value]="policyDetail.productPlan?.productYear"></app-property-text>
      <!-- 资管产品供款年期 -->
      <app-property-text *ngIf="policyDetail.custom" [title]="'CustomPaymentTerm' | translate"
                         [value]="policyDetail.productPlan?.customPaymentTerm | yearConversion"></app-property-text>
      <!-- 首期保费 -->
      <app-property-text [title]="'FirstYearPremium' | translate"
                         [value]="premiumAndLevy"></app-property-text>
    </div>
  </div>

  <nz-tabset [nzSize]="'large'" [nzCentered]="false" (nzSelectChange)="tabsIndex($event)">
    <nz-tab [nzTitle]=policyStatus>
      <app-time-line [timeLineVOS]="policy.policyTimeLineVOS" [uploadTokenReq]="uploadTokenReq"
                     [pendingDetail]="penddingDetail"
                     [pendingReply]='pendingReply'
                     [showAttachments]="attachmentAuth.display"
                     (pendingChange)="onPendingDetail($event)"
                     (underPendingChange)="onUnderwritingPendingDetail($event)"
                     (pendingReplyChange)="onPenddingReplyDetail($event)"
                     (underwritingPendingReply)="underwritingReply($event)"
                     [modeType]="'policy'"
                     [mode]="mode"
                     [type]="'timeLine'" #timeLint></app-time-line>
    </nz-tab>
    <ng-template #policyStatus>
      <nz-badge nzDot [nzShowDot]="messageRemind.status > 0">
        <span>
          {{'PolicyStatus' | translate }}
        </span>
      </nz-badge>
    </ng-template>
    <nz-tab [nzTitle]=PolicyInfo>
      <app-policy-overview [policyDetail]="policyDetail"
                           [mode]="mode"
                           [dataDesensitization]="dataDesensitization"
                           [policy]="policy"></app-policy-overview>
    </nz-tab>
    <ng-template #PolicyInfo>
      <nz-badge nzDot [nzShowDot]="messageRemind.info > 0">
        <span>
          {{'PolicyInfo' | translate}}
        </span>
      </nz-badge>
    </ng-template>
    <nz-tab *ngIf="attachmentAuth.display" [nzTitle]=PolicyAttachments>
      <app-attachments [attachments]="policy.attachments"
                       [uploadTokenReq]="uploadTokenReq"
                       [readonly]="attachmentAuth.readonly"
                       [isGroup]="true"
                       [isPreview]="true"
                       [showEmpty]="false"></app-attachments>
    </nz-tab>
    <ng-template #PolicyAttachments>
      <nz-badge nzDot [nzShowDot]="messageRemind.attachment > 0">
        <span>
          {{ isShowBeneficiary ? ('PolicyAttachments' | translate)  : ('PolicyNotice' | translate)}}
        </span>
      </nz-badge>
    </ng-template>

<!--    <nz-tab *ngIf="isShowBeneficiary" [nzTitle]=PolicyFollowUp>-->
<!--      <app-time-line [pendings]="policy.policyServiceVOS" [uploadTokenReq]="uploadTokenReq"-->
<!--                     [pendingReply]='pendingReply'-->
<!--                     [pendingDetail]="penddingDetail"-->
<!--                     (pendingChange)="onPendingDetail($event)"-->
<!--                     (pendingReplyChange)="onPendReplyDetail($event)"-->
<!--                     [type]="'service'" #pendTimeLint></app-time-line>-->
<!--    </nz-tab>-->
<!--    <ng-template #PolicyFollowUp>-->
<!--      {{'PolicyFollowUp'| translate}}-->
<!--    </ng-template>-->

    <nz-tab *ngIf="isShowBeneficiary" [nzTitle]=PolicyFollowUp>
      <app-policy-pending [policy]="policy" [mode]="mode" (putSendMessage)="getMessageRemind()"></app-policy-pending>
    </nz-tab>
    <ng-template #PolicyFollowUp>
      <nz-badge nzDot [nzShowDot]="messageRemind.service > 0 || messageRemind.serviceReply > 0">
        <span>
          {{instance.isFone() ? ('PolicyFollowUp'| translate) : ('PolicyService' | translate)}}
        </span>
      </nz-badge>
    </ng-template>

    <nz-tab [nzTitle]=underwritingForm *ngIf="isShowItem && policy.forms && policy.forms.length > 0">
      <div class="booking-section-wrapper" *ngFor="let i of policy.forms" appThrottleClick (throttleClick)="onFormDetail(i)">
        <div class="property-row">
          <div class="property-title-row">{{ i.name }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>
    </nz-tab>
    <ng-template #underwritingForm>
      <nz-badge nzDot [nzShowDot]="false">
        {{'Forms'| translate}}
      </nz-badge>
    </ng-template>
  </nz-tabset>


  <!--  form下载-->
  <nz-drawer [nzWidth]="800" [nzMaskClosable]="true" [nzVisible]="formVisible"
             (nzOnClose)="formClose()">
    <ng-container *nzDrawerContent>
      <div class="drawer-container" *ngIf="formItem">
        <div class="common-title" style="margin-bottom: 40px">
          {{formItem.bookingFormName}}
        </div>
        <div class="flex">
          <div>{{formItem.signedDate ? formItem.signedDate : time | date:'yyyy-MM-dd'}}</div>
          <div *ngIf="formItem.status === 'UNCOMPLETED'">{{'NotSigned' | translate}}</div>
          <div *ngIf="formItem.status === 'COMPLETED'">{{'Signed' | translate}}</div>
        </div>
        <div class="section-wrapper">
          <div
            style="padding: 15px 0 15px 0;display: flex; flex-direction: row; align-items: center; justify-content: start;">
            <div style="flex: 1; display: flex; flex-direction: row; align-items: center;">
              <span style="margin-left: 5px; font-weight: bold; font-size: 16px"> {{formItem.bookingFormName}}</span>
            </div>
            <a *ngIf="formPdf" nz-button nzType="link" nzSize="small" nz-tooltip
               [nzTooltipTitle]="'Preview' | translate"
               appThrottleClick (throttleClick)="onPreviewNormalResult(); $event.stopPropagation();">
              <img alt="" width="20" src="../../../../assets/images/ic_view.svg">
            </a>
            <a *ngIf="formPdf" nz-button nzType="link" nzSize="small" nz-tooltip
               [nzTooltipTitle]="'Download' | translate"
               appThrottleClick (throttleClick)="onDownloadNormalResult(); $event.stopPropagation();">
              <img alt="" width="20" src="../../../../assets/images/ic_download.svg">
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</div>
