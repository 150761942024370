<div class="table-section">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!fnaData">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="fnaData" style="margin-top: 20px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="false"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="fnaData.list || []"
                [nzTitle]="tableHeader"
                [nzScroll]="{ x: '1110px', y: getChartHeight()}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">
                <span style="font-size: 16px;">{{('FnaList' | translate)}}</span>
<!--                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate" style="margin-left: 5px"-->
<!--                   appThrottleClick (throttleClick)="onReload()">-->
<!--                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>-->
<!--                </a>-->
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        class="icon-center"
                        nz-tooltip [nzTooltipTitle]="'Reload' | translate"
                        appThrottleClick (throttleClick)="onReload()">
                  <img alt="" width="24" src="assets/images/fna_reload.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-tooltip [nzTooltipTitle]="'Search' | translate"
                        class="icon-center"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

                <button *ngIf="mode === 'OWN'"
                        class="icon-center"
                        appThrottleClick (throttleClick)="onForms()"
                        nz-tooltip [nzTooltipTitle]="'BlankForm' | translate"
                        nz-button nzType="default" nzSize="large" nzShape="circle">
                  <img alt="" width="22" src="assets/images/fna_forms.svg">
                </button>

                <button *ngIf="mode === 'OWN'"
                        class="icon-center"
                        nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'FnaCreate' | translate"
                        style="background: #1D64C4;"
                        appThrottleClick (throttleClick)="onFnaCreate()">
                  <img alt="" width="22" src="assets/images/fna_plus.svg">
                </button>

                <!--                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip-->
                <!--                        [nzTooltipTitle]="'ProposalSearch' | translate" style=" margin-left: 10px;"-->
                <!--                        appThrottleClick (throttleClick)="onProposalSearch()">-->
                <!--                  <img alt="" width="22" src="assets/images/ic_search.svg">-->
                <!--                </button>-->

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
<!--          <div class="table-title">-->
<!--            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ fnaData.total }}</span>-->
<!--          </div>-->
        </ng-template>

        <thead>
        <tr>
          <th nzWidth="190px"><span>{{'PolicyNo' | translate}}</span></th>
<!--          <th nzWidth="150px"><span>{{'FnaId' | translate}}</span></th>-->
          <th nzWidth="160px"
              [nzShowSort]="true"
              [nzSortFn]="true"
              (nzSortOrderChange)="clientNameChange($event)">
            <span>{{'ClientName' | translate}} (EN)</span>
          </th>
          <th nzWidth="120px">
            <span>{{'ClientName' | translate}}</span>
          </th>
<!--          <th><span>{{'Referee' | translate}}{{'FullName' | translate}}</span></th>-->
          <th nzWidth="300px"><span>{{'ProductName' | translate}}</span></th>
          <th nzWidth="180px"
              [nzShowSort]="true"
              [nzSortFn]="true"
              (nzSortOrderChange)="createTimeChange($event)">
            <span>{{'CreatedTime' | translate}}</span>
          </th>
          <th nzWidth="130px"
              [nzShowSort]="true"
              [nzSortFn]="true"
              (nzSortOrderChange)="statusChange($event)">
            <span>{{'Status' | translate}}</span>
          </th>
<!--          <th nzWidth="150px"><span>{{'BookingNo' | translate}}</span></th>-->
<!--          <th nzWidth="150px"><span>{{'UnderwritingNumber' | translate}}</span></th>-->
          <th nzAlign="left" nzRight nzWidth="140px"><span>{{'Functions' | translate}}</span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let fna of fnaData.list; let i = index" appThrottleClick (throttleClick)="onDetail(fna)">
          <td nzEllipsis>#{{ fna.policyNo || 'N/A' }}</td>
<!--          <td>-->
<!--            <span style="font-weight: bold; font-size: 12px">-->
<!--              {{ fna.id }}-->
<!--            </span>-->
<!--          </td>-->
          <td nzEllipsis><span>{{ fna.customerNameEn }}</span></td>
          <td nzEllipsis><span>{{ fna.customerName }}</span></td>
<!--          <td><span>{{ fna.accountName }}</span></td>-->
          <td>
            <span>{{ fna?.productList && fna?.productList.length ? fna?.productList[0]?.productName : 'N/A' }}</span>
          </td>
          <td><span>{{ fna.createTime | date:'yyyy-MM-dd HH:mm' }}</span></td>
          <td>
            <div [ngSwitch]="fna.status">
              <div *ngSwitchCase="'UNCOMPLETED'" class="fna-text-warning">
                <span> {{ fna.status | metadataTranslate:'fnaStatus'}}</span>
              </div>
              <div *ngSwitchCase="'COMPLETED'" class="fna-text-success">
                <span> {{ fna.status | metadataTranslate:'fnaStatus'}}</span>
              </div>
            </div>
          </td>
<!--          <td><span>{{ fna.bookingNo || 'N/A' }}</span></td>-->
<!--          <td><span>{{ fna.underwritingNo || 'N/A' }}</span></td>-->
          <td nzRight nzAlign="left">
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 appThrottleClick (throttleClick)="onDetail(fna); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
              <a *ngIf="!fna.policyNo && !fna.underwritingNo && fna.signatureStatus !== 'SIGNED'" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick (throttleClick)="onDelete(fna, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete_red.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>

      <div style="margin: 15px 0;text-align: right;padding-right: 25px;">
        <nz-pagination [nzPageIndex]="search.pageNum"
                       [nzPageSize]="search.pageSize"
                       [nzTotal]="fnaData.total"
                       [nzShowSizeChanger]="true"
                       [nzPageSizeOptions]="search.pageSizeOptions"
                       [nzShowTotal]="totalTemplate"
                       (nzPageSizeChange)="onPageSizeChange($event)"
                       (nzPageIndexChange)="onSearch($event)">
        </nz-pagination>
        <ng-template #totalTemplate let-total>{{'HedgeProposalTotal' | translate}}: {{ total }} </ng-template>
      </div>
    </div>
  </nz-spin>
</div>


<!-- 气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 名字 -->
    <app-property-input [title]="'ClientName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                        [(value)]="search.customerName"></app-property-input>
    <!-- 预约 -->
<!--    <app-property-input [title]="'BookingNo' | translate"-->
<!--                        [placeholder]="('PleaseEnter' | translate) + ('BookingNo' | translate)"-->
<!--                        [(value)]="search.likeBookingNo"></app-property-input>-->
    <!-- 新单 -->
<!--    <app-property-input [title]="'UnderwritingNumber' | translate"-->
<!--                        [placeholder]="('PleaseEnter' | translate) + ('UnderwritingNumber' | translate)"-->
<!--                        [(value)]="search.likeUnderwritingNo"></app-property-input>-->
    <!-- 保单编号 -->
    <app-property-input [title]="'PolicyNo' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('PolicyNo' | translate)"
                        [(value)]="search.likePolicyNo"></app-property-input>

    <!-- 产品 -->
    <app-property-pick [title]="'Product' | translate"
                       appThrottleClick (throttleClick)="onProductSelection()"
                       [placeholder]="'ProductSelect' | translate"
                       [(value)]="search.productName"></app-property-pick>

    <app-property-datetime-range [title]="'CreatedTime' | translate"
                                 [value]="dates"
                                 (valueChange)="onInspectDateChange($event)"></app-property-datetime-range>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>

<ng-template #tplTitle>
  <span>{{'DeleteFna' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToDeleteFna' | translate}} :「{{ params.id }} ({{params.customerName}} {{ params.customerNameEn }}) {{params.createTime | date:'yyyy-MM-dd HH:mm'}}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()" [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)" [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>
