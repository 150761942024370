import { Component, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { Instance, Material, PagedResp } from '../../api/types';
import { PremiumFinancing, PremiumFinancingSearch } from '../premium-financing-types';
import { PremiumFinancingService } from '../premium-financing.service';
import { StorageService } from '../../shared/service/storage.service';
import { DownloadService } from '../../shared/service/download.service';
import { ProposalCustomCreateComponent } from '../../proposal-custom/proposal-custom-create/proposal-custom-create.component';
import { ProposalSearch } from '../../proposal/proposal-types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PremiumFinancingGenerateComponent } from '../premium-financing-generate/premium-financing-generate.component';
import { FinancingExcelComponent } from '../financing-excel/financing-excel.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FnaListRes } from '../../fna/fna-types';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-premium-financing-list',
  templateUrl: './premium-financing-list.component.html',
  styleUrls: ['./premium-financing-list.component.less']
})
export class PremiumFinancingListComponent implements OnInit {

  spinning = false;
  loading = false;
  deleting = false;

  listArr: PagedResp<PremiumFinancing>;
  search: PremiumFinancingSearch = new PremiumFinancingSearch();
  searchPopoverVisible = false;

  instance: Instance;

  constructor(private premiumFinancingService: PremiumFinancingService,
              private storageService: StorageService,
              private downloadService: DownloadService,
              private modalService: NzModalService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private drawerService: NzDrawerService
  ) {
  }

  ngOnInit(): void {
    this.instance = this.downloadService.mockInstance;
    this.onSearch(0);
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.spinning = true;
    this.premiumFinancingService.list(this.search).subscribe(
      data => {
        data.list.forEach(e => {
        });
        this.listArr = data;
        this.spinning = false;
        console.log(this.listArr);
      }, error => {
        this.spinning = false;
      }
    );
  }

  onReload(): void {
    this.search = new PremiumFinancingSearch();
    this.spinning = true;
    this.premiumFinancingService.list(this.search).subscribe(
      data => {
        this.listArr = data;
        this.spinning = false;
      }, e => {
        this.spinning = false;
      }
    );
  }

  onCreate(): void {
    if (this.instance.isMercer()) {
      const drawerRef = this.drawerService.create<FinancingExcelComponent, { value: string }, string>({
        nzWidth: 800,
        nzContent: FinancingExcelComponent,
        nzContentParams: {}
      });
      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.premiumFinancingCreate.subscribe(data => {
          this.onReload();
          drawerRef.close();
        });
      });
      drawerRef.afterClose.subscribe(data => {
        if (typeof data === 'string') {
        }
      });
    } else {
      const drawerRef = this.drawerService.create<PremiumFinancingGenerateComponent, { value: string }, string>({
        nzWidth: 1000,
        nzContent: PremiumFinancingGenerateComponent,
        nzContentParams: {}
      });
      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.premiumFinancingGenerated.subscribe(data => {
          this.onReload();
          drawerRef.close();
        });
      });
      drawerRef.afterClose.subscribe(data => {
        if (typeof data === 'string') {
        }
      });
    }
  }

  onDetail(premiumFinancing: PremiumFinancing): void {

  }

  onDownload(url: Material): void {
    this.storageService.accessToken(url.filePath)
      .subscribe(
        data => {
          this.downloadService.download(data.accessToken, url.fileName);
        },
        err => {
        });
  }

  onRetry(item: PremiumFinancing): void {
    this.loading = true;
    this.premiumFinancingService.retry(item.id).subscribe(
      data => {
        this.onSearch(this.search.pageNum);
        this.loading = false;
      }, e => {
        this.loading = false;
      });
  }

  onDelete(item: PremiumFinancing, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: item,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, param: FnaListRes): void {
    this.deleting = true;
    this.premiumFinancingService.delete(param.id)
      .subscribe(
        data => {
          this.deleting = false;
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.onSearch(this.search.pageNum);
          ref.close();
        },
        error => {
          this.deleting = false;
          ref.close();
        });
  }
}
