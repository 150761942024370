import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { CustomerEditMode } from '../../shared';
import {
  BookingInsuredReq,
  BookingOwnerReq,
  Customer,
  CustomerCorporate,
  CustomerType,
  DataDesensitization, DataMode,
  Instance,
  Relationship
} from '../../../api/types';
import { Workbench } from '../../../workbench/workbench-types';
import { MetadataService } from '../../service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { ContactService } from '../../../contact/contact.service';
import { AccountService } from '../../../account/account.service';
import { CustomerEditComponent, CustomerEntity } from '../customer-edit/customer-edit.component';
import { Booking, BookingDetailResp } from '../../../booking/booking-types';
import { plainToClass } from 'class-transformer';
import { BookingService } from '../../../booking/booking.service';
import { EventService } from '../../service/event.service';
import { ProductService } from '../../../product/product.service';

@Component({
  selector: 'app-booking-customer-read',
  templateUrl: './booking-customer-read.component.html',
  styleUrls: ['./booking-customer-read.component.less']
})
export class BookingCustomerReadComponent implements OnInit {
  drawerRef: NzDrawerRef<BookingCustomerReadComponent, string>;
  loading = false;
  @Input()
  booking: Booking;

  @Input()
  listMode = DataMode.OWN;
  @Input()
  mode: CustomerEditMode;
  @Input()
  modeType: string;
  @Input()
  isCreate: boolean; // 是否创建

  @Input()
  bookingDetail: BookingDetailResp;
  title: string;
  customerType: CustomerType = CustomerType.INDIVIDUAL;
  customer: Customer = new Customer(); // 个人客户 - 投保人/持有人
  customerCorporate: CustomerCorporate = new CustomerCorporate(); // 企业客户 - 投保人/受保人
  relationship: Relationship; // 与投保人关系
  isOpenBankAccountRequired = false; // 是否开户必填字段确认

  // 快捷地址和电话其他数据源
  otherCustomer: Customer;
  otherCustomerCorporate: CustomerCorporate;
  birthdayDate: number;
  workbench: Workbench;
  showBtn = false;
  instance: Instance;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  @Output()
  bookingDetailChange: EventEmitter<BookingDetailResp> = new EventEmitter<BookingDetailResp>();

  @Output()
  customerSaved: EventEmitter<CustomerEntity> = new EventEmitter<CustomerEntity>();

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private productService: ProductService,
              private accountService: AccountService,
              private bookingService: BookingService,
              private workbenchService: WorkbenchService,
              private eventService: EventService,
              private contactService: ContactService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.metadataService.mockInstance;
    this.dataDesensitization = this.accountService.dataDesensitization;
    this.birthdayDateToAge();
  }


  onEdit(): void {
    console.log('Edit clicked');
    if (this.mode === 'holder') {
      this.onBookingOwner();
    } else {
      this.onBookingInsured();
    }
  }

  // Owner
  onBookingOwner(): void {
    const isOpenBankAccountRequired = this.booking.ownerType === CustomerType.INDIVIDUAL && this.instance.isWanson();
    this.translate.get('Holder').subscribe(
      (holder: any) => {
        const drawerRef = this.drawerService.create<CustomerEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: CustomerEditComponent,
          nzContentParams: {
            title: holder,
            mode: this.mode,
            modeType: 'booking',
            booking: this.booking,
            customerType: this.booking.ownerType,
            customer: plainToClass(Customer, this.booking.owner) ?? new Customer(),
            customerCorporate: this.booking.companyOwner ?? new CustomerCorporate(),
            otherCustomer: this.booking.insured,
            otherCustomerCorporate: this.booking.companyInsured,
            isOpenBankAccountRequired
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.booking.ownerType = customerEntity.customerType;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.booking.owner = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.booking.companyOwner = customerEntity.corporateCustomer;
                    break;
                }
                this.saveOwner(this.booking, drawerRef);
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
          this.birthdayDateToAge();
        });
      });
  }

  onBookingInsured(): void {
    this.translate.get('Insured').subscribe(
      (insured: any) => {

        const drawerRef = this.drawerService.create<CustomerEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: CustomerEditComponent,
          nzContentParams: {
            title: insured,
            mode: this.mode,
            modeType: 'booking',
            booking: this.booking,
            customerType: this.booking.insuredType,
            relationship: this.booking.relationship,
            customer: plainToClass(Customer, this.booking.insured) ?? new Customer(),
            customerCorporate: this.booking.companyInsured ?? new CustomerCorporate(),
            otherCustomer: this.booking.owner,
            otherCustomerCorporate: this.booking.companyOwner
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.booking.insuredType = customerEntity.customerType;
                this.booking.relationship = customerEntity.relationship;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.booking.insured = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.booking.companyInsured = customerEntity.corporateCustomer;
                    break;
                }
                this.saveInsured(this.booking, drawerRef);
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
          this.birthdayDateToAge();
        });
      });
  }

  saveOwner(booking: Booking, drawerRef: NzDrawerRef<CustomerEditComponent, string>): void {
    const bookingOwnerReq = plainToClass(BookingOwnerReq, booking, {excludeExtraneousValues: true});
    this.loading = true;
    this.bookingService.owner(bookingOwnerReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  saveInsured(booking: Booking, drawerRef: NzDrawerRef<CustomerEditComponent, string>): void {
    const bookingInsuredReq = plainToClass(BookingInsuredReq, booking, {excludeExtraneousValues: true});
    this.loading = true;
    this.bookingService.insured(bookingInsuredReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  loadBookingDetail(bookingNo: string): void {
    this.loading = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          const booking = bookingDetail.toBooking();
          this.bookingDetail = bookingDetail;
          this.bookingDetailChange.emit(bookingDetail);
          this.booking = booking;
          if (this.mode === 'holder') {
            this.customer = this.booking.owner;
            this.customerCorporate = this.booking.companyOwner;
            this.relationship = this.booking.relationship;
          } else {
            this.customer = this.booking.insured;
            this.customerCorporate = this.booking.companyInsured;
            this.relationship = this.booking.relationship;
          }
          // 通知列表頁面刷新
          // this.eventService.bookingsReload.next();
        },
        error => {
          this.loading = false;
        });
  }

  dynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.modeType) {
      return isShow;
    }
    if (this.modeType === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

  isVIP(val): string {
    let str = '';
    if (typeof val !== 'boolean' && !val) {
      return str;
    }
    if (val) {
      this.translate.get('Yes').subscribe(
        (res) => {
          str = res;
        }
      );
    } else {
      this.translate.get('No').subscribe(
        (res) => {
          str = res;
        }
      );
    }
    return str;
  }

  isHKIdentity(val): string {
    let str = '';
    if (typeof val !== 'boolean' && !val) {
      return str;
    }
    if (val) {
      this.translate.get('Permanent').subscribe(
        (res) => {
          str = res;
        }
      );
    } else {
      this.translate.get('NonPermanent').subscribe(
        (res) => {
          str = res;
        }
      );
    }
    return str;
  }

  get isBetterOrWDF(): boolean {
    let flag = true;
    if (this.instance.key === 'better') {
      flag =  false;
    }
    if (this.modeType === 'underwriting' && (this.instance.key === 'waterdrop' || this.instance.key === 'dev')) {
      flag =  false;
    }
    return flag;
  }

  containsOther(options: string[]): boolean {
    if (options) {
      return options.includes('OTHERS');
    }
  }

  birthdayDateToAge(): void {
    if (!this.customer.birthdate) {
      return;
    }
    const timestamp = new Date(this.customer.birthdate).getTime();
    if (!this.booking.companyCode) {
      if (timestamp > new Date().getTime()) {
        this.birthdayDate = 0;
        return ;
      }
      // 出生时间 毫秒
      const birthDayTime = new Date(parseInt(String(timestamp), 0)).getTime();
      // 当前时间 毫秒
      const nowTime = new Date().getTime();
      // 一年毫秒数(365 * 86400000 = 31536000000)
      this.birthdayDate = Math.floor((nowTime - birthDayTime) / 31536000000);
      return;
    }
    this.productService.age(timestamp, this.booking.companyCode)
      .subscribe(
        age => {
          this.birthdayDate = age;
        },
        error => {
        }
      );
  }

}
