<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 30px">
    {{title}}
  </div>

  <div class="section-wrapper">
    <!-- 客户类型 -->

    <app-property-text [title]="'TypeOfCustomer' | translate"
                       [bottomLineVisible]="!instance.isFone() && mode === 'holder'"
                       [value]="customerType | metadataTranslate:'ownerType'"></app-property-text>


    <app-property-text *ngIf="!instance.isFone() && mode === 'holder'" [title]="'BusinessSource' | translate"
                       [bottomLineVisible]="false"
                       [value]="customer.businessSource"></app-property-text>

  </div>
  <div class="section-wrapper" *ngIf="mode === 'insured'">
    <!-- 与投保人关系 -->
    <app-property-text [title]="'RelationshipToHolder' | translate"
                       [bottomLineVisible]="false"
                       [value]="relationship |metadataTranslate:'relationship'"></app-property-text>

  </div>

  <div *ngIf="mode === 'holder' || (mode === 'insured' && relationship != 'OWN')">
    <div [ngSwitch]="customerType">
      <!-- 個人 -->
      <div *ngSwitchCase="'INDIVIDUAL'">
        <div class="section-wrapper">
          <!-- 姓氏 -->
          <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                             [isEncryption]="dynamicEncryption('lastName')"
                             [value]="customer.lastName"></app-property-text>

          <!-- 名字 -->
          <app-property-text [title]="'FirstName' | translate"
                             [isEncryption]="dynamicEncryption('firstName')"
                             [value]="customer.firstName"></app-property-text>

          <!-- 姓氏-英文 -->
          <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                             [isEncryption]="dynamicEncryption('lastNamePinyin')"
                             [value]="customer.lastNamePinyin"></app-property-text>
          <!-- 名字-英文 -->
          <app-property-text [title]="'FirstNameInEnglish' | translate"
                             [isEncryption]="dynamicEncryption('firstNamePinyin')"
                             [value]="customer.firstNamePinyin"></app-property-text>

          <div *ngIf="!((instance.isWDF() ? workbench.groupDesensitization : true) && modeType === 'underwriting')">
            <!-- 性别 -->
            <app-property-text [title]="'Gender' | translate"
                               [isEncryption]="dynamicEncryption('gender')"
                               [value]="customer.gender | metadataTranslate:'gender'"></app-property-text>
            <!-- 身份證號 -->
            <app-property-text [title]="(instance.isIAM() && modeType === 'booking' ? ('IDNo' | translate) : ('IdentityCardNumber' | translate))"
                               [isEncryption]="dynamicEncryption('idCard')"
                               [value]="customer.idCard "></app-property-text>

            <app-property-text *ngIf="instance.isIAM() && modeType === 'booking'"
                               [title]="'BirthCertNo' | translate"
                               [isEncryption]="dynamicEncryption('idCard')"
                               [value]="customer.hkIdCard "></app-property-text>
            <!-- 生日 -->
            <app-property-text [title]="'DateOfBirth' | translate"
                               [isEncryption]="dynamicEncryption('birthday')"
                               [value]="customer.birthdate"></app-property-text>

            <!-- 年龄 -->
            <app-property-text [title]="'Age' | translate"
                               [value]="birthdayDate"></app-property-text>

            <!-- 居住地址 -->
            <app-property-text [title]="instance.isIAM() ? ('PleaseAddress' | translate ) : ('ResidentialAddress' | translate)"
                               [isEncryption]="dynamicEncryption('residenceAddress')"
                               [value]="customer.address?.toAddressString()"></app-property-text>


            <!-- 戶籍地址 -->
            <app-property-text *ngIf="!instance.isFoneOrDev()"
                               [title]="'LegalAddress' | translate"
                               [isEncryption]="dynamicEncryption('residenceAddress')"
                               [value]="customer.residenceAddress?.toAddressString()"></app-property-text>

            <!-- 通訊地址 -->
            <app-property-text [title]="'PostalAddress' | translate"
                               [isEncryption]="dynamicEncryption('residenceAddress')"
                               [value]="customer.postalAddress?.toAddressString()"></app-property-text>

            <!-- 移动号码 -->
            <app-property-text [title]="'MobileNumber' | translate"
                               [isEncryption]="dynamicEncryption('phone')"
                               [value]="customer.phoneString()"></app-property-text>

            <!-- 邮箱 -->
            <app-property-text [title]="'Email' | translate"
                               [isEncryption]="dynamicEncryption('email')"
                               [value]="customer.email"></app-property-text>


            <!-- 职业信息 -->
            <app-property-text *ngIf="instance.isHarvest() && modeType === 'booking' && mode !== 'insured'"
                               [title]="'JobInformation' | translate"
                               [isEncryption]="dynamicEncryption('careerInformation')"
                               [value]="customer.careerInformation |metadataTranslate:'careerInformation'"></app-property-text>


            <!-- 公司名称 -->
            <app-property-text [title]="'CompanyName' | translate"
                               [isEncryption]="dynamicEncryption('innerCompanyName')"
                               [value]="customer.companyName"></app-property-text>

            <!-- 公司地址 -->
            <app-property-text [title]="'CompanyAddress' | translate"
                               [isEncryption]="dynamicEncryption('companyAddress')"
                               [value]="customer.companyAddress?.toAddressString()"></app-property-text>

            <!-- 行业 -->
            <app-property-text [title]="'Occupation' | translate"
                               [isEncryption]="dynamicEncryption('companyType')"
                               [value]="customer.companyType"></app-property-text>

            <!-- 职位 -->
            <app-property-text [title]="'Position' | translate"
                               [isEncryption]="dynamicEncryption('position')"
                               [value]="customer.position"></app-property-text>

            <!-- 風險偏好 -->
            <app-property-text *ngIf="!instance.isBetterOrDev()"
                               [title]="'RiskAppetite' | translate"
                               [isEncryption]="dynamicEncryption('riskAppetite')"
                               [value]="customer.riskAppetite | metadataTranslate:'riskAppetite'"></app-property-text>

            <!--VIP-->
            <app-property-text [title]="'VIP'"
                               [value]="isVIP(customer.vip.vip)"></app-property-text>

            <!-- VIP备注 -->
            <app-property-text *ngIf="customer.vip.vip" [title]="'Remark' | translate"
                               [value]="customer.vip.remark"></app-property-text>
          </div>

          <div style="height: 10px"></div>
        </div>
        <div *ngIf="!((instance.isWDF() ? workbench.groupDesensitization : true) && modeType === 'underwriting')" class="section-wrapper">
          <!-- 稱謂 -->
          <app-property-text [title]="'Title' | translate"
                             [isEncryption]="dynamicEncryption('title')"
                             [value]="customer.title | metadataTranslate:'title'"></app-property-text>

          <!-- 是否吸烟 -->
          <app-property-text [title]="'SmokeCondition' | translate"
                             [isEncryption]="dynamicEncryption('smoke')"
                             [value]="customer.smoke | metadataTranslate:'smoke'"></app-property-text>

          <app-property-text *ngIf="customer.smoke === 'S'" [title]="'SmokingVolume' | translate"
                             [isEncryption]="dynamicEncryption('smoke')"
                             nz-tooltip [nzTooltipTitle]="smokeQuantityTemplate"
                             nzTooltipPlacement="topRight"
                             [value]="customer.smokeQuantity"></app-property-text>
          <ng-template #smokeQuantityTemplate><div>{{'AD' | translate}}</div></ng-template>

          <!-- 出生地（省市） -->
          <app-property-text [title]="'Birthplace' | translate"
                             [isEncryption]="dynamicEncryption('birthplace')"
                             [value]="customer.birthplace"></app-property-text>

          <!-- 国籍（国家地区） -->
          <app-property-text [title]="instance.isFone() ? ('Nationality' | translate) : ('Country' | translate)"
                             [isEncryption]="dynamicEncryption('country')"
                             [value]="customer.country | metadataTranslate:'countries'"></app-property-text>

          <!--          <app-property-select [title]="isFone ? ('Nationality' | translate) : ('Country' | translate)"-->
          <!--                               [placeholder]="('PleaseSelect' | translate) + (isFone ? ('Nationality' | translate) : ('Country' | translate))"-->
          <!--                               [options]="regionOptions"-->
          <!--                               [(value)]="customer.country"></app-property-select>-->
          <!-- 是否有香港身份證 -->
          <app-property-text *ngIf="instance.isBaylightOrDev() && modeType == 'booking' && mode === 'holder'"
                             [title]="'HaveHongKongIdentityCard' | translate"
                             [value]="isVIP(customer.haveHKIdCard)"></app-property-text>


          <!-- 是否香港永居 -->
          <app-property-text *ngIf="customer.haveHKIdCard"
                             [title]="'HongKongPermanentIdentity' | translate"
                             [value]="isHKIdentity(customer.permanentResidentHK)"></app-property-text>


          <!-- 护照号码 -->
          <app-property-text [title]="'PassportNo' | translate"
                             [isEncryption]="dynamicEncryption('passport')"
                             [value]="customer.passport"></app-property-text>

          <!-- 通行证号码 -->
          <app-property-text [title]="'EepCode' | translate"
                             [isEncryption]="dynamicEncryption('eepCode')"
                             [value]="customer.eepCode"></app-property-text>

          <!-- 身高 -->
          <app-property-text [title]="'Height' | translate"
                             [value]="customer.height"
                             [isEncryption]="dynamicEncryption('height')"
                             nz-tooltip [nzTooltipTitle]="contactHeight"
                             nzTooltipPlacement="topRight"></app-property-text>
          <ng-template #contactHeight><div>cm</div></ng-template>

          <!-- 体重 -->
          <app-property-text [title]="'Weight' | translate"
                             [value]="customer.weight"
                             [isEncryption]="dynamicEncryption('weight')"
                             nz-tooltip [nzTooltipTitle]="contactWeight"
                             nzTooltipPlacement="topRight"></app-property-text>
          <ng-template #contactWeight><div>kg</div></ng-template>

          <!-- 平均每月支出 -->
          <app-property-text *ngIf="mode === 'holder'" [title]="'AverageMonthlyExpenses' | translate"
                             [isEncryption]="dynamicEncryption('averageMonthlyExpenses')"
                             [value]="customer.averageMonthlyExpenses"></app-property-text>

          <!-- 平均每月收入 -->
          <app-property-text *ngIf="mode === 'holder'" [title]="'AverageMonthlyIncome' | translate"
                             [isEncryption]="dynamicEncryption('averageMonthlyIncome')"
                             [value]="customer.averageMonthlyIncome"></app-property-text>

          <!-- 受雇于现职年期 -->
          <app-property-input-number [title]="'YearInCurrentJob' | translate"
                                     [placeholder]="('PleaseEnter' | translate) + ('YearInCurrentJob' | translate)"
                                     [(value)]="customer.yearInCurrentJob"></app-property-input-number>

          <!-- 总流动资产 -->
          <app-property-text [title]="'TotalLiquidAssets' | translate"
                             [isEncryption]="dynamicEncryption('totalLiquidAssets')"
                             [value]="customer.totalLiquidAssets"></app-property-text>

          <!-- 总债务 -->
          <app-property-text [title]="'TotalLiabilities' | translate"
                             [isEncryption]="dynamicEncryption('totalLiabilities')"
                             [value]="customer.totalLiabilities"></app-property-text>


          <!-- 受供养人数目 -->
          <app-property-text [title]="'NoOfDependents' | translate"
                             [isEncryption]="dynamicEncryption('noOfDependents')"
                             [value]="customer.noOfDependents"></app-property-text>

          <!-- 婚姻状态 -->
          <app-property-text [title]="'Marriage' | translate"
                             [isEncryption]="dynamicEncryption('marriage')"
                             [value]="customer.marriage | metadataTranslate:'marriageStatus'"></app-property-text>

          <!-- 教育程度 -->
          <app-property-text [title]="'Education' | translate"
                             [isEncryption]="dynamicEncryption('education')"
                             [value]="customer.education | metadataTranslate:'education'"></app-property-text>

          <!-- 总工作年期 -->
          <app-property-text [title]="'WorkYear' | translate"
                             [isEncryption]="dynamicEncryption('workYear')"
                             [value]="customer.workYear"></app-property-text>

          <!-- 月薪 -->
          <app-property-text [title]="'Salary' | translate"
                             [isEncryption]="dynamicEncryption('salary')"
                             [value]="customer.salary"></app-property-text>

          <!-- 公司電話 -->
          <app-property-text [title]="'OfficePhoneNumber' | translate"
                             [isEncryption]="dynamicEncryption('companyTelephone')"
                             [value]="customer.officePhoneNumberString()"></app-property-text>

          <!-- 固定電話 -->
          <app-property-text [title]="'ResidentialPhoneNumber' | translate"
                             [isEncryption]="dynamicEncryption('residenceTelephone')"
                             [value]="customer.residentialPhoneNumberString()"></app-property-text>
          <!-- 其他電話 -->
          <!-- 過往一年是否所屬國家以外地區居住超過182日 -->
          <app-property-text [title]="'LongtimeAbroad' | translate"
                             [isEncryption]="dynamicEncryption('longtimeAbroad')"
                             [value]="customer.longtimeAbroad ? ('Yes' | translate): ('No' | translate) "></app-property-text>


          <!-- 其他国家身份 -->
          <app-property-text [title]="'OtherNationalID' | translate"
                             [value]="customer.citizenship ? ('Yes' | translate) : ('No' | translate)"></app-property-text>

          <!-- OptOutRequest -->
          <app-property-text *ngIf="!instance.isBetterOrDev()" [title]="'OptOutRequest' | translate"
                             [isEncryption]="dynamicEncryption('optOut')"
                             [value]="customer.optOut ? ('Yes' | translate) : ('No' | translate)"></app-property-text>

          <app-property-text *ngIf="instance.isFone() ? false : mode === 'holder'" [title]="'VulnerableCustomer' | translate"
                             [isEncryption]="dynamicEncryption('vulnerableCustomer')"
                             [value]="customer.vulnerableCustomer ? ('Yes' | translate) : ('No' | translate)"></app-property-text>


          <!-- 投保人邮编 -->
          <app-property-text *ngIf="modeType === 'booking' && mode === 'holder'"
                             [title]="'PostCode' | translate"
                             [isEncryption]="dynamicEncryption('postcode')"
                             [value]="customer.postcode"></app-property-text>
          <div style="height: 10px"></div>
        </div>
        <div *ngIf="!(instance.isFoneOrDev() || ((instance.isWDF() ? workbench.groupDesensitization : true) && modeType === 'underwriting'))" class="section-wrapper">
          <!-- 备注 -->
          <app-property-text [title]="'Remark' | translate"
                             [isEncryption]="dynamicEncryption('remark')"
                             [value]="customer.remark"></app-property-text>

          <div style="height: 10px"></div>
        </div>
        <div *ngIf="isBetterOrWDF" class="section-wrapper">
          <div
            *ngIf="!instance.isFone()"
            style="display: flex;justify-content: space-between;align-items: center;padding: 10px 0;border-bottom: 1px solid #E2EBF6;cursor: pointer;"
            (click)="showBtn = !showBtn"
          >
            <div>
              {{'auxiliary_information' | translate}}
            </div>
            <div style="color: #0078D4;">
              <i nz-icon [nzType]="showBtn ? 'up' : 'down'" nzTheme="outline"></i>
            </div>
          </div>
          <div *ngIf="!instance.isFone() ? showBtn : true">
            <!-- 旅行 -->
            <app-property-text *ngIf="!instance.isWanson()" [title]="'Travel' | translate"
                               [isEncryption]="dynamicEncryption('travel')"
                               [value]="customer.travel"></app-property-text>
            <div *ngIf="!instance.isWanson()">
              <!-- 運動 -->
              <app-property-text [title]="'Sports' | translate"
                                 [isEncryption]="dynamicEncryption('sports')"
                                 [value]="customer.sports | metadataTranslate:'sports'"></app-property-text>

              <!-- 運動（其他） -->
              <app-property-text *ngIf="containsOther(customer.sports)" [title]="'SportsOther' | translate"
                                 [isEncryption]="dynamicEncryption('sportsOther')"
                                 [value]="customer.sportsOther"></app-property-text>
            </div>
            <!-- 游戏 -->
            <div *ngIf="!instance.isWanson()">
              <app-property-text [title]="'Games' | translate"
                                 [isEncryption]="dynamicEncryption('games')"
                                 [value]="customer.games | metadataTranslate:'games'"></app-property-text>

              <!-- 游戏（其他） -->
              <app-property-text *ngIf="containsOther(customer.games)"
                                 [title]="'GamesOther' | translate"
                                 [isEncryption]="dynamicEncryption('gamesOther')"
                                 [value]="customer.gamesOther"></app-property-text>

            </div>
            <!-- 電影 -->
            <div *ngIf="!instance.isWanson()">
              <app-property-text [title]="'Movie' | translate"
                                 [isEncryption]="dynamicEncryption('movie')"
                                 [value]="customer.movie | metadataTranslate:'movie'"></app-property-text>

              <!-- 電影（其他） -->
              <app-property-text *ngIf="containsOther(customer.movie)"
                                 [isEncryption]="dynamicEncryption('movieOther')"
                                 [title]="'MovieOther' | translate" [value]="customer.movieOther"></app-property-text>

            </div>
            <!-- 美食 -->
            <div *ngIf="!instance.isWanson()">
              <app-property-text [title]="'Cuisine' | translate"
                                 [isEncryption]="dynamicEncryption('cuisine')"
                                 [value]="customer.cuisine | metadataTranslate:'cuisine'"></app-property-text>
              <!-- 美食（其他） -->
              <app-property-text *ngIf="containsOther(customer.cuisine)"
                                 [title]="'CuisineOther' | translate"
                                 [isEncryption]="dynamicEncryption('cuisineOther')"
                                 [value]="customer.cuisineOther"></app-property-text>
            </div>
          </div>
          <div style="height: 10px"></div>
        </div>
      </div>
      <!-- 公司 -->
      <div *ngSwitchCase="'CORPORATE'">
        <div class="section-wrapper">
          <!-- 公司名称 -->
          <app-property-text [title]="'CorporateName' | translate"
                             [isEncryption]="dynamicEncryption('innerCompanyName')"
                             [value]="customerCorporate.companyName"></app-property-text>

          <!-- 公司名称英文 -->
          <app-property-text [title]="'CorporateNameEn' | translate"
                             [isEncryption]="dynamicEncryption('innerCompanyName')"
                             [value]="customerCorporate.companyNameEn"></app-property-text>

          <div *ngIf="!((instance.isWDF() ? workbench.groupDesensitization : true) && modeType === 'underwriting')">
            <!-- 公司商业登记号码 -->
            <app-property-text [title]="'BusinessRegistrationNumber' | translate"
                               [isEncryption]="dynamicEncryption('businessRegistrationNumber')"
                               [value]="customerCorporate.businessRegistrationNumber"></app-property-text>
            <!-- 公司注册日期 -->
            <app-property-text [title]="'RegistrationDate' | translate"
                               [isEncryption]="dynamicEncryption('registrationDate')"
                               [value]="customerCorporate.registrationDate | date: 'yyyy-MM-dd'"></app-property-text>

            <!-- 公司电话 -->
            <app-property-text [title]="'OfficePhoneNumber' | translate"
                               [isEncryption]="dynamicEncryption('telephone')"
                               [value]="customerCorporate?.officePhoneNumberString()"></app-property-text>

            <!-- 公司注册地区 -->
            <app-property-text [title]="'RegionOfIncorporation' | translate"
                               [isEncryption]="dynamicEncryption('registrationCountry')"
                               [value]="customerCorporate.registrationCountry | metadataTranslate:'nationality'"></app-property-text>

            <!-- 公司邮箱 -->
            <app-property-text [title]="'CompanyEmail' | translate"
                               [isEncryption]="dynamicEncryption('email')"
                               [value]="customerCorporate.email"></app-property-text>

            <!-- 公司登记地址 -->
            <app-property-text [title]="'RegisteredAddress' | translate"
                               [isEncryption]="dynamicEncryption('location')"
                               [value]="customerCorporate.registeredAddress?.toAddressString()"></app-property-text>


            <!-- 通讯地址 -->
            <app-property-text [title]="'PostalAddress' | translate"
                               [isEncryption]="dynamicEncryption('location')"
                               [value]="customerCorporate.contactAddress?.toAddressString()"></app-property-text>

            <!-- 授权代表姓名中文-名字 -->
            <app-property-text [title]="'LegalPersonFirstName' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonFirstName')"
                               [value]="customerCorporate.legalPersonFirstName"></app-property-text>

            <!-- 授权代表姓名中文-姓氏 -->
            <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastName' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonLastName')"
                               [value]="customerCorporate.legalPersonLastName"></app-property-text>

            <!-- 授权代表姓名中文-名字 -->
            <app-property-text [title]="'LegalPersonFirstNameEn' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonFirstNameEn')"
                               [value]="customerCorporate.legalPersonFirstNameEn"></app-property-text>

            <!-- 授权代表姓名中文-姓氏 -->
            <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastNameEn' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonLastNameEn')"
                               [value]="customerCorporate.legalPersonLastNameEn"></app-property-text>

            <!-- 授权代表职称 -->
            <app-property-text [title]="'LegalPersonPosition' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonPosition')"
                               [value]="customerCorporate.legalPersonPosition"></app-property-text>

            <!-- 授权代表电话 -->
            <app-property-text [title]="'LegalPersonPhone' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonPhone')"
                               [value]="customerCorporate?.legalPersonPhoneNumberString()"></app-property-text>

            <!-- OptOutRequest -->
            <app-property-text [title]="'OptOutRequest' | translate"
                               [value]="(customerCorporate?.optOut ? ('Yes' | translate) : ('No' | translate))"></app-property-text>

            <app-property-text *ngIf="instance.isFone() ? false : mode === 'holder'" [title]="'VulnerableCustomer' | translate"
                               [bottomLineVisible]="false"
                               [isEncryption]="dynamicEncryption('vulnerableCustomer')"
                               [value]="customerCorporate.vulnerableCustomer ? ('Yes' | translate) : ('No' | translate)"></app-property-text>

          </div>

        </div>
        <div style="height: 10px"></div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
<!--      <div nz-col nzSpan="12" class="footer-button">-->
<!--        <a nz-button nzType="link" nzBlock nzSize="large"-->
<!--           appThrottleClick (throttleClick)="onReset()">-->
<!--          <em nz-icon nzType="reload" nzTheme="outline"></em>-->
<!--          <span>{{'Reset' | translate}}</span>-->
<!--        </a>-->
<!--      </div>-->
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onEdit()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Edit' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
