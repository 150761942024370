import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyDisplayMode } from '../../shared';

@Component({
  selector: 'app-property-input-number',
  templateUrl: './property-input-number.component.html',
  styleUrls: ['./property-input-number.component.less']
})
export class PropertyInputNumberComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() placeholder: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() borderless = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() bottomLineVisible = true;
  @Input() max: number;
  @Input() min: number;
  @Input() titleColor: string;
  @Input() textColor: string;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  innerValue: any;

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(val: any) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }
}
