<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="drawer-title-small" style="margin-bottom: 30px">
      {{'Detail' | translate}}
    </div>
    <div *ngIf=" !contractDetails">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="contractDetails" style="margin-top: 30px">
      <nz-table
        #rowSelectionTable
        [nzBordered]="false"
        [nzOuterBordered]="true"
        [nzShowPagination]="true"
        [nzShowSizeChanger]="true"
        [nzLoading]="loading"
        [nzLoadingIndicator]="indicatorTemplate"
        [nzData]="contractDetails.details"
        [nzTitle]="tableHeader"
        [nzScroll]="{ x: '1800'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ContractDetail' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex;justify-content: flex-end;align-items: center">
                <div class="list-header-title">{{'ContractName' | translate}}: {{ title }}</div>
                <button *ngIf="contractMode !== 'TIER_COMMISSION_RATE'"
                        nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_filter.svg">
                </button>

                <button *ngIf="workbench.salesDownloadContract" nz-button nzType="default"
                        nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'ExportExcel' | translate"  style=" margin-left: 20px;"
                        appThrottleClick (throttleClick)="onExportExcel()">
                  <img style="margin-bottom: 4px;" alt="" width="22" src="assets/images/ic_export_excel.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <thead>
        <tr>
          <th *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft nzWidth="145px">
            <span nz-tooltip>{{'ProductName' | translate}}</span>
          </th>

          <th *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft nzWidth="145px">
            <span nz-tooltip>{{'InsuranceType' | translate}}</span>
          </th>

          <th *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft nzWidth="145px">
            <span nz-tooltip>{{'Insurer' | translate}}</span>
          </th>

          <th *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft nzWidth="145px">
            <span nz-tooltip>{{'Condition' | translate}}</span>
          </th>

          <th *ngIf="contractMode === 'TIER_COMMISSION_RATE'" nzLeft nzWidth="145px">
            <span nz-tooltip>{{'CommissionType' | translate}}</span>
          </th>

          <th *ngIf="contractMode === 'TIER_COMMISSION_RATE'" nzLeft nzWidth="145px">
            <span nz-tooltip>{{'Level' | translate}}</span>
          </th>

          <th *ngFor="let row of contractDetails.details[contractDetails.maxIndexOfRates()]?.grossRates;let i = index" nzWidth="115px">
            {{ instance.isWDF() ? ('IntroductionFees' | translate:  {condition: i + 1} ) : ('Commission' | translate:  {condition: i + 1})}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contract of rowSelectionTable.data;let i = index">
          <td *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft>
            {{contract.product.productName}}
          </td>
          <td *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft>
            {{contract.categoryName}}
          </td>
          <td *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft>
            {{contract.companyName }}
          </td>
          <td *ngIf="contractMode === 'TIER_COMMISSION_RATE'" nzLeft>
            {{contract.tierCommissionType }}
          </td>
          <td *ngIf="contractMode === 'TIER_COMMISSION_RATE'" nzLeft>
            {{ contract.tier ? ('Layer' | translate: {condition: contract.tier}) : 'N/A' }}
          </td>
          <td *ngIf="contractMode !== 'TIER_COMMISSION_RATE'" nzLeft>
            <div *ngIf="contract.conditions ">
              <div *ngFor="let i of contract.conditions">
                <span [ngSwitch]="i.factor">
                  <span *ngSwitchCase="'FREQUENCY'">
                    {{i.factor | metadataTranslate:'factor'}}{{i.operation | metadataTranslate:'operation'}} {{i.value | metadataTranslate:'frequency'}}
                  </span>
                  <span *ngSwitchDefault>
                    {{i.factor | metadataTranslate:'factor'}}{{i.operation | metadataTranslate:'operation'}} {{i.value }}
                  </span>
                </span>
              </div>
            </div>
            <div *ngIf="!contract.conditions">
              ALL
            </div>
          </td>
          <td *ngFor="let row of contract?.grossRates;let i = index">
            {{row || 0}} %
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
<!--  <app-water-mark></app-water-mark>-->
</div>
<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <app-property-pick [displayMode]="'vertical'"
                       [title]="'InsuranceProduct' | translate"
                       appThrottleClick (throttleClick)="onProductSelection()"
                       [placeholder]="'ProductSelect' | translate"
                       [(value)]="primaryProduct.productName"></app-property-pick>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch()">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
