<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'UnderwritingInformation' | translate}}
  </div>
  <div style="font-size: 12px">
    {{'PolicyNumber' | translate}}
  </div>
  <div style="font-size: 16px;color:#1D64C4">
    {{underwriting.policyNo || 'N/A'}}
  </div>

  <div class="section-wrapper">

    <!-- 保险种类 -->
    <app-property-text [title]="'InsuranceType' | translate"
                       [value]="underwriting.categoryName"></app-property-text>

    <app-property-text [title]="'SubmissionDate' | translate"
                       [value]="underwriting.submissionDate | date: 'yyyy-MM-dd'"></app-property-text>

    <app-property-text [title]="'UserSignDate' | translate"
                       [value]="underwriting.signDate | date: 'yyyy-MM-dd'"></app-property-text>
    <!--  缮发日期  -->
    <app-property-text [title]="'ApprovalDate' | translate"
                       [value]="underwriting.approvalDate | date: 'yyyy-MM-dd'"></app-property-text>
    <!--  保单日期  -->
    <app-property-text [title]="instance.isFone() ? ('PolicyEffectiveDate' | translate) : ('PolicyDate' | translate)"
                       [value]="underwriting.policyDate | date: 'yyyy-MM-dd'"></app-property-text>

    <!-- 冷静期结束日-->
    <app-property-text *ngIf="!instance.isFoneOrDev()" [title]="'CoolingOffDate' | translate"
                       [value]="underwriting.coolingOffDate | date: 'yyyy-MM-dd'"></app-property-text>
    <!-- 是否办理自动转账-->
    <app-property-text [title]="'DDA' | translate"
                       *ngIf="isShowDDA(underwriting)"
                       [value]="underwriting.autoTransfer === null ? null : underwriting.autoTransfer  ? ('Yes'| translate) :('No' | translate)"></app-property-text>
    <!-- 是否预缴保费-->
    <app-property-text [title]="'Prepay' | translate"
                       [value]="underwriting.prepay === null ? null : underwriting.prepay ? ('Yes'| translate) :('No' | translate)"></app-property-text>
    <!-- 预付额-->
    <app-property-text [title]="'PrepayPremium' | translate"
                       [value]="underwriting.prepayPremium | number"></app-property-text>

    <!-- 折扣后预付额-->
    <app-property-text [title]="'DiscountPrepayPremium' | translate"
                       [value]="underwriting.discountPrepayPremium | number"></app-property-text>
    <!-- 保单截止日期-->
    <app-property-text [title]="'PolicyDeadline' | translate"
                       [value]="underwriting.policyDeadline | date: 'yyyy-MM-dd'"></app-property-text>
    <!-- 保险种类 -->
    <app-property-text [title]="'PaymentNo' | translate"
                       [bottomLineVisible]="false"
                       [value]="underwriting.paymentNumber"></app-property-text>

  </div>


</div>
