<div class="drawer-container">

  <div class="drawer-title" style="margin-bottom: 30px">
    {{'PasswordUpdate' | translate}}
  </div>

  <div>
    <div>
      <div><span class="required-icon">*</span>{{'OldPassword' | translate}}</div>
      <nz-input-group [nzSuffix]="oldPwd" class="input-group">
        <input required [(ngModel)]="editPwd.oldPassword" id="oldPwd" name="oldPwd"
               [type]="oldPwdVisible ? 'text' : 'password'"
               [placeholder]="('PleaseEnter' | translate) + ('OldPassword' | translate)" class="login-input-change"/>
      </nz-input-group>
      <ng-template #oldPwd>
        <i nz-icon [nzType]="oldPwdVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="oldPwdVisible = !oldPwdVisible"></i>
      </ng-template>
    </div>
    <div>
      <div style="margin-top: 10px"><span class="required-icon">*</span>{{'NewEditPassword' | translate}}</div>
      <nz-input-group [nzSuffix]="suffixPassword" class="input-group">
        <input required [(ngModel)]="editPwd.newPassword" id="phonePwd" name="phonePwd"
               [type]="passwordVisible ? 'text' : 'password'"
               [placeholder]="('PleaseEnter' | translate) + ('NewEditPassword' | translate)" class="login-input-change"/>
      </nz-input-group>
      <ng-template #suffixPassword>
        <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="passwordVisible = !passwordVisible"></i>
      </ng-template>
    </div>
    <div>
      <div style="margin-top: 10px"><span class="required-icon">*</span>{{'ConfirmEditPassword' | translate}}</div>
      <nz-input-group [nzSuffix]="newPwd" class="input-group">
        <input required [(ngModel)]="confirmPass" id="confirm" name="confirm"
               [type]="pwdVisible ? 'text' : 'password'"
               [placeholder]="'ConfirmPasswordAgain' | translate" class="login-input-change"/>
      </nz-input-group>
      <ng-template #newPwd>
        <i nz-icon [nzType]="pwdVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="pwdVisible = !pwdVisible"></i>
      </ng-template>
    </div>
    <div *ngIf="pwdConfig.complexPasswordConfig"
         style="margin-top: 10px;font-size: 12px;color: #BF7000;line-height: 16px;white-space: pre-line;">
      {{'PwdLength' | translate: {length: pwdConfig.passwordLengthConfig} }}
    </div>
  </div>
  <div style="text-align: center" class="footer">
    <button nz-button nzType="default" appThrottleClick (throttleClick)="cancelPass()">{{'Cancel' | translate}}</button>
    <button nz-button nzType="primary" appThrottleClick
            style="margin-left: 20px"
            [disabled]="!canConfirm"
            (throttleClick)="confirmPas()">{{'Confirm' | translate}}</button>
  </div>
</div>
