import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country, DataDesensitization, DataMode, UploadToken } from '../../api/types';
import { InviteInfo, InviteInfoRes } from '../invitation-types';
import { MetadataService } from '../../shared/service/metadata.service';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { TranslateService } from '@ngx-translate/core';
import { WorkbenchService } from '../../workbench/workbench.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../account/account.service';
import { Product } from '../../product/product-types';
import {
  ProductMultiSelectionComponent
} from '../../shared/component/product-multi-selection/product-multi-selection.component';
import { ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { deduplication } from '../../shared/utils/collections';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { InvitationService } from '../invitation.service';
import { ProductService } from '../../product/product.service';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { Workbench } from '../../workbench/workbench-types';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-invitation-create',
  templateUrl: './invitation-create.component.html',
  styleUrls: ['./invitation-create.component.less']
})
export class InvitationCreateComponent implements OnInit {
  // drawerRef: NzDrawerRef<InvitationCreateComponent, string>;
  @Input()
  mode = DataMode.OWN;
  @Input()
  type = '';
  loading: boolean;
  genderOptions: PropertySelectOption[] = [];

  formParams: InviteInfoRes = new InviteInfoRes();
  selectedProducts: Product[] = [];
  companyOptions: PropertySelectOption[] = [];

  @Output()
  invitationDrawerClose: EventEmitter<any> = new EventEmitter<any>();
  workbench: Workbench;
  dataDesensitization: DataDesensitization = new DataDesensitization();

  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private translate: TranslateService,
              private workbenchService: WorkbenchService,
              private toastr: ToastrService,
              private productService: ProductService,
              private invitationService: InvitationService,
              private accountService: AccountService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.dataDesensitization = this.accountService.dataDesensitization;
    if (this.type === 'create') {
      this.formParams.accountName = this.accountService.passport.accountName;
      this.formParams.name = this.accountService.passport.name;
    }
    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
    if (this.type === 'detail') {
      this.getProducts();
    }
    this.productService.companies()
      .subscribe(
        data => {
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });
        },
        error => {
        });
  }

  getProducts(): void {
    if (this.formParams.productCodes && this.formParams.productCodes.length > 0) {
      this.productService.productsByCodes(this.formParams.productCodes)
        .subscribe(
          data => {
            this.selectedProducts = data;
          },
          error => {
            console.log(error);
          }
        );
    }
  }


  onSummit(): void {
    console.log(this.formParams);
    console.log('onSummit');
    const formParams = plainToInstance(InviteInfo, this.formParams);
    formParams.meetingTime = formParams.meetingTime ? formatDate(formParams.meetingTime, 'yyyy-MM-dd HH:mm', 'en-US') + ':00' : formParams.meetingTime;
    let observable: Observable<boolean>;

    observable = this.type === 'create' ? this.invitationService.create(formParams) : this.invitationService.updateInfo(formParams);
    observable.subscribe(
        res => {
          this.invitationDrawerClose.emit('');
          this.type === 'create' ? this.toastr.success(this.translate.instant('CreateSuccess')) : this.toastr.success(this.translate.instant('ModifySuccess'));
        },
        () => {
          // this.toastr.error(this.translate.instant('invitation.create.fail'));
        }
      );
  }

  viewProduct(): string {
    if (this.selectedProducts.length > 1) {
      return this.selectedProducts[0].productName + ' + ' + (this.selectedProducts.length - 1);
    } else if (this.selectedProducts.length) {
      return this.selectedProducts[0].productName;
    }
    return '';
  }

  viewCompany(): string {
    if (this.formParams.companyNames?.length > 1) {
      return this.formParams.companyNames[0] + ' + ' + (this.formParams.companyNames.length - 1);
    } else if (this.formParams.companyNames?.length) {
      return this.formParams.companyNames[0];
    }
    return '';
  }

  onProductSelection(): void {
    if (this.formParams.status === 'PROCESSED') {
      return;
    }
    const drawerRef = this.drawerService.create<ProductMultiSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductMultiSelectionComponent,
      nzMask: true,
      nzContentParams: {
        type: ProductSelectionType.COMPARISON,
        hideCategory: true,
        selectedProducts: this.selectedProducts
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.drawerRef = drawerRef;
      selectionComponent.productsSelected
        .subscribe(
          products => {
            this.selectedProducts = products;
            this.selectedProducts = deduplication(this.selectedProducts, v => {
              return v.productCode;
            });
            this.formParams.productCodes = this.selectedProducts.map(item => item.productCode);
            const res = this.formParams.companyCodes || [];
            this.selectedProducts.map(item => res.push(item.companyCode));
            this.formParams.companyCodes = [...new Set(res)];
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }


  mobileChange(result: Country | null): void {
    this.formParams.basicInformation.areaCode = result?.areaCode || null;
  }

  parserValue(value: string): string {
    return value.replace(/\$\s?|(,*)/g, '');
  }

  formatterValue(value: number): string {
    if (parseFloat(String(value)).toString() === 'NaN') {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

  onProductCompanyChange(companyCode: string): void {
    console.log(companyCode, 'companyCode');
    // if (this.search.product.companyCode !== companyCode) {
    //   this.search.productCode = null;
    //   this.search.product = new Product();
    // }
  }

}
