<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{ 'ProductPlan' | translate }}
    <!--    {{'UnderwritingOverview' | translate}}-->
  </div>
  <div class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{ 'MainInsurance' | translate }}</span>
  </div>
  <div class="section-wrapper">

    <!-- 险种 -->
    <app-property-text [title]="'ProductCategory' | translate"
                       [value]="underwriting.categoryName"></app-property-text>
    <!-- 保险公司 -->
    <app-property-text [title]="'Insurer' | translate"
                       [value]="underwriting.companyName"></app-property-text>
    <!-- 地区 -->
    <app-property-text [title]="'Region' | translate"
                       style="cursor: no-drop;"
                       [value]="underwriting?.region | metadataTranslate:'productCompanyRegion'"></app-property-text>

    <!--  主险名称  -->
    <app-property-text [title]="'InsuranceName' | translate"
                       [value]="underwriting.productName"></app-property-text>
    <!--  供款年期  -->
    <app-property-text *ngIf="!underwriting.custom"
                       [title]="'PaymentTerm' | translate"
                       [value]="underwriting.productPlan.productYear"></app-property-text>
    <!-- 资管产品供款年期 -->
    <app-property-text *ngIf="underwriting.custom" [title]="'CustomPaymentTerm' | translate"
                       [value]="underwriting.productPlan?.customPaymentTerm | yearConversion"></app-property-text>
    <!-- 货币 -->
    <app-property-text [title]="'Currency' | translate"
                       [value]="underwriting.productPlan.currency | metadataTranslate:'currency'"></app-property-text>
    <!-- 保額 -->
    <app-property-text [title]="'SumAssured' | translate"
                       [value]="underwriting.productPlan.sumAssured | number "></app-property-text>
    <!-- 保費 -->
    <app-property-text [title]="'Premium' | translate"
                       [value]="underwriting.productPlan.premium | number "></app-property-text>

    <!-- 征费-->
    <app-property-text [title]="'Levy' | translate"
                       [value]="(underwriting.levy | number)">
    </app-property-text>

    <app-property-text *ngIf="!instance.isCMGHK()" [title]="'Target Premium' | translate"
                       [value]="underwriting.productPlan.targetPremium | number "></app-property-text>

    <app-property-text *ngIf="!instance.isCMGHK()" [title]="'Excess Premium' | translate"
                       [value]="underwriting.productPlan.excessPremium | number ">
    </app-property-text>

      <!-- 首期付款方式 -->
      <app-property-text [title]="'InitialPaymentMethod' | translate"
                         [value]="underwriting.productPlan.firstPaymentType | metadataTranslate:'paymentType'">
      </app-property-text>

      <!-- 续期付款方式 -->
      <app-property-text [title]="'RenewalPaymentMethod' | translate"
                         [value]="underwriting.productPlan.restPaymentType | metadataTranslate:'paymentType'"></app-property-text>

    <app-property-text [title]="'DividendDistributionMethod' | translate"
                       [bottomLineVisible]="false"
                       [value]="underwriting.productPlan.dividends | metadataTranslate:'dividends'">
    </app-property-text>

  </div>

  <div class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{ 'UnderwritingInfo' | translate }}</span>
  </div>

  <div class="section-wrapper">
    <!-- 服务代表 -->
    <app-property-text [title]="('ServiceAgent' | translate) + '1'"
                       [value]="underwriting.serviceAgentName"></app-property-text>
    <!-- 服务代表 -->
    <app-property-text [title]="('ServiceAgent' | translate) + '2'"
                       [value]="underwriting.serviceAgent2Name"></app-property-text>
    <!-- Branch -->
    <app-property-text [title]="'Branch'" [value]="underwriting.branch"></app-property-text>
    <!-- Consultant -->
    <app-property-text [title]="'Consultant'" [value]="underwriting.consultant"></app-property-text>
    <!-- RM -->
    <app-property-text [title]="'RM'" [value]="underwriting.relationshipManager"></app-property-text>
    <!-- BPO -->
    <app-property-text [title]="'BPO' | translate"
                       [value]="underwriting.guarantee ? ('Yes' | translate) : ('No' | translate)">
    </app-property-text>

    <app-property-text *ngIf="!instance.isFone()"
                       [title]="'EnjoyPolicyDiscounts' | translate"
                       [value]="underwriting.enjoyPolicyDiscount ? ('Yes' | translate) : ('No' | translate)">
    </app-property-text>

    <app-property-text *ngIf="!instance.isFone()"
                       [title]="'PromotionExpirationDate' | translate"
                       [value]="underwriting.discountEventsDeadlinesDate"></app-property-text>

    <app-property-text *ngIf="!instance.isFone()"
                       [title]="'EPolicy' | translate" [bottomLineVisible]="false"
                       [value]="underwriting.electronicPolicy ? ('Yes' | translate) : ('No' | translate)"></app-property-text>


  </div>
  <div *ngIf="underwriting.riders?.length" class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{ 'Rider' | translate }}</span>
  </div>

  <div *ngFor="let item of underwriting.riders" class="section-wrapper">
    <app-property-text [title]="'RiderName'| translate"
                       [value]="item.productName"></app-property-text>
    <app-property-text [title]="'Premium'| translate"
                       [value]="(item.premium | number) + underwriting.productPlan.currency"></app-property-text>
    <app-property-text [title]="'SumAssured'| translate"
                       [value]="(item.sumAssured | number )+ underwriting.productPlan.currency"></app-property-text>
    <app-property-text [title]="'ProductYear'| translate"
                       [value]="(item.productYear | yearConversion )"></app-property-text>
    <div *ngIf="item.categoryCode === 'MEDICAL'">
      <app-property-text [title]="'Deductible'| translate"
                         [value]="(item.medicalPlan?.deductible || 0  | number)+ underwriting.productPlan.currency"></app-property-text>
      <app-property-text [title]="'MedicalLevel'| translate"
                         [value]="(item.medicalPlan?.medicalLevel | metadataTranslate: 'medicalLevel')"></app-property-text>
      <app-property-text [title]="'GuaranteedArea'| translate"
                         [value]="(item.medicalPlan?.region | metadataTranslate: 'medicalRegion')"></app-property-text>
      <app-property-text [title]="'SupplementaryBenefits'| translate"
                         [value]="addSafe(item.medicalPlan?.supplementaryBenefits)"></app-property-text>
    </div>
  </div>

  <div class="section-wrapper" *ngIf="underwriting.trAccountName && !underwriting.hideTr">
    <!-- 签单员1 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('Account' | translate)"
                       [value]="underwriting.trAccountName"></app-property-text>
    <!-- 姓名 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('FullName' | translate)"
                       [value]="underwriting.trName"></app-property-text>
    <!-- 手机号 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('PhoneNumber' | translate)"
                       [value]="'+'+underwriting.trAreaCode +' '+ underwriting.trPhone"></app-property-text>
    <!-- 邮箱 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('Email' | translate)"
                       [bottomLineVisible]="false"
                       [value]="underwriting.trEmail"></app-property-text>
  </div>
  <div class="section-wrapper" *ngIf="underwriting.tr2AccountName  && !underwriting.hideTr">
    <!-- 签单员2 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('Account' | translate)"
                       [value]="underwriting.tr2AccountName"></app-property-text>
    <!-- 姓名 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('FullName' | translate)"
                       [value]="underwriting.tr2Name"></app-property-text>
    <!-- 电话 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('PhoneNumber' | translate)"
                       [value]="'+'+underwriting.tr2AreaCode +' '+ underwriting.tr2Phone"></app-property-text>
    <!-- 邮箱 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('Email' | translate)"
                       [bottomLineVisible]="false"
                       [value]="underwriting.tr2Email"></app-property-text>
  </div>
  <div class="section-wrapper" *ngIf="underwriting.tr3AccountName  && !underwriting.hideTr">
    <!-- 签单员3 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('Account' | translate)"
                       [value]="underwriting.tr3AccountName"></app-property-text>
    <!-- 姓名 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('FullName' | translate)"
                       [value]="underwriting.tr3Name"></app-property-text>
    <!-- 手机号 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('PhoneNumber' | translate)"
                       [value]="'+'+underwriting.tr3AreaCode +' '+ underwriting.tr3Phone"></app-property-text>
    <!-- 邮箱 -->
    <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('Email' | translate)"
                       [bottomLineVisible]="false"
                       [value]="underwriting.tr3Email"></app-property-text>
  </div>


</div>
