<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!companies">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="companies" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="companies.list"
                [nzPageIndex]="companies.pageNum"
                [nzPageSize]="companies.pageSize"
                [nzTotal]="companies.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1250px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'CompanyList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_filter.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ companies.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="80px"><span>{{'Rank' | translate}}</span></th>
          <th nzWidth="180px"><span>{{'CompanyName' | translate}}</span></th>
          <th nzWidth="180px"><span>{{'Region' | translate}}</span></th>
          <th><span>{{'CompanyDesc' | translate}}</span></th>
          <th nzRight nzWidth="100px"><span>{{'Action' | translate}}</span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let company of companies.list; let i = index" appThrottleClick (throttleClick)="onCompanyDetail(company.code)">
          <td nzLeft>
            <span style="font-weight: bold; font-size: 12px">
              {{ company.rank }}
            </span>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="company.logo" alt=""/>
              <span>{{ company.name }}</span>
            </div>
          </td>
          <td>{{ company.region }}</td>
          <td nzEllipsis><span style="color: #888888">{{ company.desc}}</span></td>
<!--          <td><span style="color: #888888">{{ company.desc?.slice(0, 100) + '...'}}</span></td>-->
          <td nzRight>
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 appThrottleClick (throttleClick)="onCompanyDetail(company.code)">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>


<!-- 多语言气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 255px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>

    <!-- 產品名稱 -->
    <app-property-input [title]="'CompanyName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('CompanyName' | translate)"
                        [(value)]="search.name">
    </app-property-input>

    <!-- 所在地區 -->
    <app-property-select [title]="'Region' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Region' | translate)"
                         [options]="regionOptions"
                         [(value)]="search.region"></app-property-select>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
