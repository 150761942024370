<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 33px;font-size: 18px">
      {{'Browsing' | translate}}
    </div>
    <div  appThrottleClick
          (throttleClick)="onBrowseInfo(shareDetail)">
      <div class="flex">
        <div>
          <p style="font-weight: 500;color: #404040;">{{shareDetail.shareSources | metadataTranslate:'shareSources'}}
            &nbsp;|&nbsp;{{shareDetail.title}}</p>
          <span *ngFor="let item of shareDetail.tags">
          <nz-tag [nzColor]="'blue'">{{ item }}</nz-tag>
        </span>
          <p style="font-size: 14px;font-weight: 400;color: #A6A6A6;">
            {{shareDetail.addTime | date:'yyyy-MM-dd HH:mm:ss'}}
          </p>
        </div>
        <div style="margin-left: auto">
          <img [src]="shareDetail.cover" width="135" alt="">
        </div>
      </div>
      <div style="display: flex;margin-top: 12px">
        <img src="assets/images/ic_browseNumber.svg" width="20" alt="">
        <span
          style="margin-left:10px; font-size: 14px;font-weight: 400;color: #A6A6A6"> {{shareDetail.browseNumber}} {{"Person"| translate}}</span>
        <img style="margin-left: 32px" alt="" width="20" src="assets/images/ic_eyes.svg">
        <span
          style="margin-left:10px; font-size: 14px;font-weight: 400;color: #A6A6A6"> {{shareDetail.browseFrequency}} {{"Count"| translate}}</span>
        <img style="margin-left: 32px" width="20" src="assets/images/ic_frequency.svg" alt="">
        <span
          style="margin-left:10px; font-size: 14px;font-weight: 400;color: #A6A6A6"> {{shareDetail.frequency}} {{ "Count" | translate}}</span>
        <div style="margin-left: auto;">
          <span style="color: #4B84E5;font-size: 14px;cursor: pointer;">  {{"ViewMore"| translate}}</span>
          <span><i nz-icon nzType="right" nzTheme="outline" style="color: #4B84E5"></i></span>
        </div>
      </div>
    </div>
    <div class="section-title" style="margin-top: 54px;">
      <span style="margin-left: 8px">{{'ViewActivities' | translate}}</span>
    </div>
    <div *ngFor="let browseDynamic of shareDetail.browseDynamics"
         style="margin-top: 20px;border: 1px solid #E5EDF7;background: #ffffff;box-shadow: 0px 4px 24px 0px #F8F8F9;border-radius: 12px;padding: 20px 24px"
         appThrottleClick
         (throttleClick)="onConnectionsDetail(browseDynamic.id)">
      <div class="flex">
        <div style="margin-right: 18px">
          <img style="border-radius: 50%" width="35" [src]="browseDynamic.headImgUrl" alt="">
        </div>
        <div style="flex: 1">
          <p>{{browseDynamic.nickname}}
            <span style="display:inline-block; padding: 2px 11px;color: #FFFFFF;background: #1D64C4;border-radius: 4px">{{browseDynamic.type | metadataTranslate:'connectionsType'}}</span>
          </p>
          <p style="font-size: 16px;font-weight: 400;color: #A6A6A6">{{'Source' | translate}}:{{browseDynamic.platform | metadataTranslate:'platformType'}}</p>
          <p class="flex" style="justify-content: space-between">
            <span style="font-size: 14px">
              <span style="color: #A6A6A6;">{{'NumberOfViews'| translate}}:</span>{{browseDynamic.browseFrequency}}{{'Count'| translate}}
            </span>
            <span>
              <span style="color: #A6A6A6;">{{'BrowseTime'| translate}}:</span>{{onMinute(browseDynamic.browseDuration)}}{{'Minute'| translate}}
            </span>
            <span>
              <span style="color: #A6A6A6;">{{'BrowseLengths'| translate}}:</span>{{browseDynamic.browseLength}}%
            </span>
          </p>
        </div>
      </div>
      <nz-divider style="margin: 20px 0"></nz-divider>
      <div class="flex" style="margin-top: 20px">
        <span>{{browseDynamic.latestBrowsingTime | date:'yyyy-MM-dd HH:mm:ss'}}</span>
        <div style="margin-left: auto;">
          <span style="color: #4B84E5;font-size: 14px;cursor: pointer;">  {{"ViewMore"| translate}}</span>
          <span><i nz-icon nzType="right" nzTheme="outline" style="color: #4B84E5"></i></span>
        </div>
      </div>
    </div>
  </nz-spin>
</div>
