import { Component, EventEmitter, OnInit } from '@angular/core';
import { Passport, PasswordConfig } from '../../api/types';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ContactInfoRes } from '../../contact/contact-types';

@Component({
  selector: 'app-deit-password',
  templateUrl: './deit-password.component.html',
  styleUrls: ['./deit-password.component.less']
})
export class DeitPasswordComponent implements OnInit {
  pwdConfig: PasswordConfig = new PasswordConfig();
  confirmPass: string;
  editPwd = {
    oldPassword: undefined,
    newPassword: undefined,
  };
  oldPwdVisible = false;
  passwordVisible = false;
  pwdVisible = false;

  returnFlag: EventEmitter<any> = new EventEmitter<any>();

  constructor(private authService: AuthService,
              private translate: TranslateService,
              private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getTips();
  }


  cancelPass(): void {
    console.log('cancel');
    this.returnFlag.emit('');
  }

  confirmPas(): void {
    console.log('cancel');
    if (this.confirmPass === this.editPwd.newPassword) {
      this.authService.changePwd(this.editPwd)
        .subscribe(
          data => {
            let passport = new Passport();
            passport = JSON.parse(localStorage.getItem('LIFEBEE-SALES-PASSPORT'));
            passport.token = data;
            localStorage.setItem('LIFEBEE-SALES-PASSPORT', JSON.stringify(passport));
            if (data) {
              this.translate.get('ChangePasswordSuccess').subscribe(
                (res: any) => {
                  this.toastr.success(res);
                });
              this.returnFlag.emit('');
            }
          },
          error => {
            console.log(error);
          });
    } else {
      this.translate.get('SecondaryPasswordInconsistent').subscribe(
        (res: any) => {
          this.toastr.warning(res);
        });
    }
  }

  get canConfirm(): boolean {
    return !!(this.confirmPass && this.editPwd.oldPassword && this.editPwd.newPassword);
  }

  getTips(): void {
    this.authService.getSignTips()
      .subscribe(
        data => {
          this.pwdConfig = data;
        },
        error => {
          console.log(error);
        });
  }

}
