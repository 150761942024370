<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'Rider' | translate}}
  </div>

  <div class="proposal-apply-section">
    <div class="solid-box">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="12">
          <app-property-select [title]="'InsuranceProduct' | translate"
                               [displayMode]="'vertical'"
                               [placeholder]="('PleaseSelect' | translate) + ('InsuranceProduct' | translate)"
                               [options]="productCodeOptions"
                               [required]="false"
                               [(value)]="applyReq.productCode"
                               (valueChange)="onProduct($event)"></app-property-select>
        </nz-col>

        <nz-col *ngIf="selectProduct.categoryCode != 'MEDICAL'" [nzSpan]="12">
          <app-property-select [title]="'PaymentTerm' | translate"
                               [displayMode]="'vertical'"
                               [placeholder]="('PleaseSelect' | translate) + ('PaymentTerm' | translate)"
                               [options]="paymentTermOptions"
                               [(value)]="applyReq.productYear"></app-property-select>
        </nz-col>
        <nz-col *ngIf="selectProduct.categoryCode != 'MEDICAL'" [nzSpan]="12">
          <app-property-input-number [title]="'Premium' | translate"
                                     [displayMode]="'vertical'"
                                     [placeholder]="'Premium' | translate"
                                     [(value)]="applyReq.premium"></app-property-input-number>
        </nz-col>
        <nz-col *ngIf="selectProduct.categoryCode != 'MEDICAL'" [nzSpan]="12">
          <app-property-input-number [title]="'SumAssured' | translate"
                                     [displayMode]="'vertical'"
                                     [placeholder]="'SumAssured' | translate"
                                     [(value)]="applyReq.sumAssured"></app-property-input-number>
        </nz-col>
        <nz-col *ngIf="selectProduct.categoryCode === 'MEDICAL'" [nzSpan]="12">
          <!-- 保障地區 -->
          <app-property-select [title]="'GuaranteedArea' | translate"
                               [displayMode]="'vertical'"
                               [required]="false"
                               [placeholder]="('PleaseSelect' | translate) + ('GuaranteedArea' | translate)"
                               [options]="guaranteedAreaOptions"
                               [(value)]="applyReq.medicalPlan.region"></app-property-select>
        </nz-col>
        <nz-col *ngIf="selectProduct.categoryCode === 'MEDICAL'" [nzSpan]="12">
          <!-- 医疗险等级 -->
          <app-property-select [title]="'MedicalLevel' | translate"
                               [displayMode]="'vertical'"
                               [placeholder]="('PleaseSelect' | translate) + ('MedicalLevel' | translate)"
                               [options]="medicalLevelOptions"
                               [required]="false"
                               [(value)]="applyReq.medicalPlan.medicalLevel"></app-property-select>
        </nz-col>
        <nz-col *ngIf="selectProduct.categoryCode === 'MEDICAL'" [nzSpan]="12">
          <!-- 自付額 -->
          <app-property-select [title]="'Deductible' | translate"
                               [displayMode]="'vertical'"
                               [required]="false"
                               [placeholder]="('PleaseSelect' | translate) + ('Deductible' | translate)"
                               [options]="deductibleOptions"
                               [(value)]="applyReq.medicalPlan.deductible"></app-property-select>
        </nz-col>
        <nz-col *ngIf="selectProduct.categoryCode === 'MEDICAL'" [nzSpan]="12">
          <!-- 附加保障 -->
          <app-property-select [title]="'SupplementaryBenefits' | translate"
                               [displayMode]="'vertical'"
                               [mode]="'multiple'"
                               [placeholder]="('PleaseSelect' | translate) + ('SupplementaryBenefits' | translate)"
                               [options]="supplementaryBenefitOptions"
                               [(value)]="applyReq.medicalPlan.supplementaryBenefits"></app-property-select>
        </nz-col>
      </nz-row>
      <div *ngIf="selectProduct.dynamicFields && selectProduct.dynamicFields.length && applyReq.dynamicFields && applyReq.dynamicFields.length">
        <div *ngFor="let dynamicField of applyReq.dynamicFields; let i = index">
          <div [ngSwitch]="dynamicField.fieldType">
            <app-property-input *ngSwitchCase="'string'"
                                [title]="dynamicField.fieldName"
                                [displayMode]="'vertical'"
                                [placeholder]="dynamicField.fieldName"
                                [required]="dynamicField.required"
                                [disabled]="dynamicField.isDisabled"
                                [(value)]="dynamicField.value"
                                nz-tooltip [nzTooltipTitle]="dynamicField.remark"
                                nzTooltipPlacement="bottomRight"></app-property-input>
            <app-property-select *ngSwitchCase="'array'"
                                 [displayMode]="'vertical'"
                                 [title]="dynamicField.fieldName"
                                 [placeholder]="dynamicField.fieldName"
                                 [options]="dynamicField.options"
                                 [disabled]="dynamicField.isDisabled"
                                 [required]="dynamicField.required"
                                 [(value)]="dynamicField.value"
                                 (valueChange)="onDynamicFieldChange($event, dynamicField)"
                                 nz-tooltip [nzTooltipTitle]="dynamicField.remark"
                                 nzTooltipPlacement="bottomRight"></app-property-select>
            <app-property-input-number *ngSwitchCase="'number'"
                                       [displayMode]="'vertical'"
                                       [title]="dynamicField.fieldName"
                                       [required]="dynamicField.required"
                                       [disabled]="dynamicField.isDisabled"
                                       [placeholder]="dynamicField.fieldName"
                                       [(value)]="dynamicField.value"
                                       nz-tooltip [nzTooltipTitle]="dynamicField.remark"
                                       nzTooltipPlacement="bottomRight"></app-property-input-number>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
