import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BvModuleComponent } from './bv-module.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { BvModuleMainComponent } from './bv-module-main/bv-module-main.component';
import { BvModuleListComponent } from './bv-module-list/bv-module-list.component';
import { NewFnaModule } from '../new-fna/new-fna.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { BvSearchComponent } from './bv-search/bv-search.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    BvModuleComponent,
    BvModuleMainComponent,
    BvModuleListComponent,
    BvSearchComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    NewFnaModule,
    NzTabsModule,
    NzButtonModule,
    NzGridModule,
    NzIconModule,
    NzPaginationModule,
    NzPopoverModule,
    NzSkeletonModule,
    NzSpinModule,
    NzTableModule,
    NzToolTipModule,
    NzAvatarModule,
    NzSelectModule,
    FormsModule,
  ]
})
export class BvModuleModule { }
