import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Beneficiary, Customer, DataDesensitization, DataMode, Instance } from '../../../api/types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BeneficiaryEditComponent } from '../beneficiary-edit/beneficiary-edit.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { WorkbenchService } from '../../../workbench/workbench.service';
import {Booking} from '../../../booking/booking-types';
import { Tool } from '../../utils/tool';
import { AccountService } from '../../../account/account.service';
import { BeneficiariesReadComponent } from '../beneficiaries-read/beneficiaries-read.component';
import { Workbench } from '../../../workbench/workbench-types';

export class BeneficiaryIndexEntity {
  beneficiary: Beneficiary;
  index: number;
}

@Component({
  selector: 'app-beneficiaries-edit',
  templateUrl: './beneficiaries-edit.component.html',
  styleUrls: ['./beneficiaries-edit.component.less']
})
export class BeneficiariesEditComponent implements OnInit {

  title: string;
  beneficiaries: Beneficiary[] = [];
  @Input()
  mode = DataMode.OWN;
  insured: Customer;
  @Input()
  modeType: string;
  @Input()
  bookingDetail: Booking = new Booking();
  @Input()
  type: string;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  workbench: Workbench;
  instance: Instance;
  @Output()
  beneficiariesSaved: EventEmitter<Beneficiary[]> = new EventEmitter<Beneficiary[]>();

  constructor(private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private accountService: AccountService,
              private translate: TranslateService,
              public workbenchService: WorkbenchService,
  ) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.workbenchService.mockInstance;
    this.dataDesensitization = this.accountService.dataDesensitization;
    console.log(this.type);
  }

  onAddBeneficiary(): void {
    const drawerRef = this.drawerService.create<BeneficiaryEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: BeneficiaryEditComponent,
      nzContentParams: {
        modeType: this.modeType,
        insured: this.insured,
        beneficiaries: this.beneficiaries,
        type: this.type,
        booking: this.bookingDetail
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.beneficiarySaved.subscribe(
        data => {
          drawerRef.close();
          if (!this.beneficiaries) {
            this.beneficiaries = [];
          }
          this.beneficiaries.push(data);
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }


  onEditBeneficiary(beneficiary: Beneficiary, index: number): void {
    const drawerRef = this.drawerService.create<BeneficiariesReadComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: BeneficiariesReadComponent,
      nzContentParams: {
        title: 'Beneficiary',
        moduleSource: 'booking',
        modeType: this.mode,
        insured: this.insured,
        beneficiaries: [beneficiary],
        beneficiariesCopy: this.beneficiaries,
      },
    });
  }

  onDelete(beneficiary: Beneficiary, index: number, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {

    const entity = new BeneficiaryIndexEntity();
    entity.beneficiary = beneficiary;
    entity.index = index;

    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: entity,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, entity: BeneficiaryIndexEntity): void {
    if (this.beneficiaries.length > entity.index) {
      this.beneficiaries.splice(entity.index, 1);
    }
    ref.close();
  }

  onSave(): void {
    console.log(this.beneficiaries);
    this.beneficiariesSaved.emit(this.beneficiaries);
  }

  dynamicEncryption(keyName, val): string {
    let isShow = val;
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
    }
    return isShow;
  }

}
