<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!policies">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="policies" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzShowSizeChanger]="true"
                [nzPageSizeOptions]="search.pageSizeOptions"
                [nzData]="policies.list"
                [nzPageIndex]="policies.pageNum"
                [nzPageSize]="policies.pageSize"
                [nzTotal]="policies.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                (nzPageSizeChange)="onPageSizeChange($event)"
                [nzScroll]="{ x: instance.isFone() ? '1315' : '1415'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="18">
              <div class="list-header-title">{{'PolicyPremiumReminder' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   (click)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>

                <span *ngIf="instance.isFone()"
                      style="font-size: 12px;color: #ffa24a;">
                  若對 ‘保費到期日’ 有疑問，請於服務諮詢頁面中創建問題向行政發出查詢，謝謝。
                </span>
              </div>
            </nz-col>
            <nz-col [nzSpan]="6">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-tooltip [nzTooltipTitle]="'ExportExcel' | translate"
                        nz-popover [nzPopoverContent]="exportTemplate"
                        [(nzPopoverVisible)]="exportPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img style="margin-bottom: 4px;" alt="" width="22" src="assets/images/ic_export_excel.svg">
                </button>
                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'PolicyPremiumReminder' | translate" style=" margin-left: 10px;"
                        (click)="openSearch()"
                >
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ policies.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'PolicyNo' | translate">{{'PolicyNo' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'ClientName' | translate">{{'ClientName' | translate}}</span>
          </th>

          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
          </th>

          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Frequency' | translate">{{'Frequency' | translate}}</span>
          </th>
          <th *ngIf="!instance.isFone()" nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'PremiumsDueDate' | translate">{{'PremiumsDueDate' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let policy of policies.list; let i = index"
        >
          <td nzLeft>
            <nz-badge [nzDot]="policy.reminder">
            <span style="font-weight: bold; font-size: 12px">
              {{ policy.policyNo }}
            </span>
            </nz-badge>
          </td>
          <td>
            <p>{{ dynamicEncryption('name', policy.ownerName) || 'N/A' }}</p>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="policy.companyLogo" alt=""/>
              <span>{{ policy.productName || 'N/A' }} <span
                *ngIf="policy.productYear">&nbsp;({{ policy.productYear | yearsOrMonthOrAge }})</span></span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ dynamicEncryption('salesName', policy.salesName) }}</span>
              <span>{{ dynamicEncryption('accountName', policy.accountName) }}</span>
            </div>
            <div *ngIf="workbench.secondAccountName">
              <span>{{ dynamicEncryption('salesName', policy.secondSalesName) }}</span>
              <span>{{ dynamicEncryption('accountName', policy.secondAccountName) }}</span>
            </div>
          </td>

          <td>
            <span>{{ policy.frequency | metadataTranslate: 'frequency'}}</span>
          </td>
          <td *ngIf="!instance.isFone()">
            <div [ngSwitch]="policy.paymentStatus">
              <div>
                <span *ngSwitchCase="'PAID'" class="text-success">●</span>
                <span *ngSwitchCase="'UNPAID'" class="text-warning">●</span>
                <span *ngSwitchCase="'OVERDUE'" class="text-danger">●</span>
                <span> {{ policy.paymentStatus | metadataTranslate: 'paymentStatus' }}</span>
              </div>
            </div>
          </td>
          <td>
            {{ (policy.time | date:'yyyy-MM-dd') || 'N/A' }}
          </td>
          <td nzRight>
            <div class="functions">
              <!--              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'LeaveMessage' | translate"-->
              <!--                 (click)="onLeaveMessage(policys)">-->
              <!--                <img alt="" width="20" src="../../../assets/images/ic_leave_message.svg">-->
              <!--              </a>-->
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate"
                 (click)="onPolicyDetail(policy.policyId)"
              >
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>

              <a *ngIf="!instance.isFoneOrDev()" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'LeaveMessage'| translate"
                 (click)="onPolicyDialog(policy.policyId)"
              >
                <img alt="" width="20" src="assets/images/ic_leave_message.svg">
              </a>

              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'PremiumReminderTemplate'| translate"
                 (click)="onDrawer(policy.id)"
              >
                <img alt="" width="20" src="assets/images/copy-text.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzWidth]="'500px'"
  [nzTitle]="drawerTitle"
  [nzFooter]="drawerFooter"
  (nzOnClose)="onDrawerClose()"
>
  <ng-template #drawerTitle>
    <div class="common-title">
      {{ 'PremiumReminderTemplate' | translate }}
    </div>
  </ng-template>

  <ng-template #drawerFooter>
    <div class="footer">
      <div nz-row style="width: 100%; height: 100%;">
        <div nz-col nzSpan="12" class="footer-button">
          <a nz-button nzType="link" nzBlock nzSize="large"
             appThrottleClick (throttleClick)="onDrawerClose()">
            <span>{{'Cancel' | translate}}</span>
          </a>
        </div>
        <div nz-col nzSpan="12" class="footer-button-primary">
          <a nz-button nzType="link" nzBlock nzSize="large"
             [disabled]="!templateContent"
             [style]="!templateContent ? 'opacity: 0.5' : ''"
             appThrottleClick (throttleClick)="onCopyToClipboard(false)">
            <span>{{'Copy' | translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container *nzDrawerContent>
    <div style="padding: 10px 24px 60px;">
      <app-property-select [displayMode]="'vertical'"
                           [title]="'TemplateSelect' | translate"
                           [placeholder]="('TemplateSelect' | translate)"
                           [options]="templateOptions"
                           [(value)]="templateId"
                           (valueChange)="onTemplateChange($event)"></app-property-select>

      <nz-spin [nzSpinning]="isSpinning">
        <div *ngIf="templateContent"
             style="border: 1px solid #E5EDF7;border-radius: 12px;width: 100%;margin-top: 32px;padding: 16px;">
          <div [innerHTML]="templateContent" id="templateContent"></div>
        </div>
      </nz-spin>
    </div>
  </ng-container>
</nz-drawer>

<ng-template #exportTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'ExportExcel' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 缴费状态 -->
    <app-property-select [displayMode]="'vertical'"
                         [mode]="'multiple'"
                         [title]="'Status' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                         [options]="statusOptions"
                         [(value)]="exportCondition.paymentStatusList"></app-property-select>

    <!--缴费日期-->
    <app-property-datetime-range [displayMode]="'vertical'"
                                 [required]="true"
                                 [title]="'PremiumsDueDate' | translate"
                                 [value]="premiumsDueDates" (valueChange)="onPremiumDueDateChange($event)"></app-property-datetime-range>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onResetExportCondition()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;"
                [disabled]="!canExport"
                appThrottleClick (throttleClick)="onExport()">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
