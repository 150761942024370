<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!fnas">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="fnas" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzShowSizeChanger]="true"
                [nzPageSizeOptions]="fnas.pageSizeOptions"
                [nzData]="fnas.list"
                [nzPageIndex]="fnas.pageNum"
                [nzPageSize]="fnas.pageSize"
                [nzTotal]="fnas.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                (nzPageSizeChange)="onPageSizeChange($event)"
                [nzScroll]="{ x: '1250px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{('FnaList' | translate)}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate" style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button *ngIf="mode === 'OWN'"
                        nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'FnaCreate' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onFnaCreate()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

<!--                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip-->
<!--                        [nzTooltipTitle]="'ProposalSearch' | translate" style=" margin-left: 10px;"-->
<!--                        appThrottleClick (throttleClick)="onProposalSearch()">-->
<!--                  <img alt="" width="22" src="assets/images/ic_search.svg">-->
<!--                </button>-->

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ fnas.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="150px"><span>{{'FnaId' | translate}}</span></th>
          <th nzWidth="150px"><span>{{'BookingNo' | translate}}</span></th>
          <th nzWidth="150px"><span>{{'UnderwritingNumber' | translate}}</span></th>
          <th nzWidth="150px"><span>{{'PolicyNo' | translate}}</span></th>
          <th><span>{{'ClientName' | translate}}</span></th>
          <th><span>{{'ProductName' | translate}}</span></th>
          <th><span>{{'Status' | translate}}</span></th>
          <th><span>{{'CreateTime' | translate}}</span></th>
          <th nzRight nzWidth="160px"><span>{{'Functions' | translate}}</span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let fna of fnas.list; let i = index" appThrottleClick (throttleClick)="onDetail(fna.id)">
          <td nzLeft>
            <span style="font-weight: bold; font-size: 12px">
              {{ fna.id }}
            </span>
          </td>
          <td><span>{{ fna.bookingNo }}</span></td>
          <td><span>{{ fna.underwritingNo }}</span></td>
          <td><span>{{ fna.policyNo }}</span></td>
          <td><span>{{ fna.name }}</span></td>
          <td>
            <div style="display: flex; align-items: center;">
              <span>{{ fna.productName }}</span>
            </div>
          </td>
          <td>
            <div [ngSwitch]="fna.status">
              <div>
                <span *ngSwitchCase="'UNCOMPLETED'" class="text-warning">●</span>
                <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                <span> {{ fna.status | metadataTranslate:'fnaStatus'}}</span>
              </div>
            </div>
          </td>
          <td><span>{{ fna.addTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></td>
          <td nzRight>
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 appThrottleClick (throttleClick)="onDetail(fna.id); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
              <a *ngIf="!fna.policyNo" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick (throttleClick)="onDelete(fna, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>


<!-- 气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 销售渠道 -->
    <app-property-input *ngIf="mode == 'FOLLOWUP'"
                        [title]="'RefereeAccount' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('RefereeAccount' | translate)"
                        [(value)]="search.accountName"></app-property-input>

    <app-property-select *ngIf="mode !== 'OWN' && mode !== 'FOLLOWUP'"
                         [title]="'RefereeAccount' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('RefereeAccount' | translate)"
                         [options]="accountNameOptions"
                         [(value)]="search.accountName"></app-property-select>
    <!-- 名字 -->
    <app-property-input [title]="'ClientName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                        [(value)]="search.name"></app-property-input>
    <!-- 预约 -->
    <app-property-input [title]="'BookingNo' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('BookingNo' | translate)"
                        [(value)]="search.bookingNo"></app-property-input>
    <!-- 新单 -->
    <app-property-input [title]="'UnderwritingNumber' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('UnderwritingNumber' | translate)"
                        [(value)]="search.underwritingNo"></app-property-input>
    <!-- 保单编号 -->
    <app-property-input [title]="'PolicyNo' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('PolicyNo' | translate)"
                        [(value)]="search.policyNo"></app-property-input>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>

<ng-template #tplTitle>
  <span>{{'DeleteFna' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToDeleteFna' | translate}} :「{{ params.id }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()" [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)" [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>
