<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!toDoList">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="toDoList" style="margin-top: 30px;">
      <nz-table #rowSelectionTable
                [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzShowSizeChanger]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzData]="toDoList"
                [nzTotal]="toDoList.length"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                [nzScroll]="{ x: '1580'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{instance.isFone() ? ('CheckList' | translate) : ('PendingList' | translate)}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ toDoList.length }}</span>
          </div>
        </ng-template>
        <thead>
        <tr>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'ToDoNo' | translate">{{'ToDoNo' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'ClientName' | translate">{{'ClientName' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'UnderwritingNumber' | translate">{{'UnderwritingNumber' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'PolicyNo' | translate">{{'PolicyNo' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="(instance.isFone() ? ('MatterName' | translate) : ('PendingName' | translate))">
              {{instance.isFone() ? ('MatterName' | translate) : ('PendingName' | translate)}}
            </span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'ApplicationTime' | translate">{{'ApplicationTime' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'State' | translate">{{'State' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let list of rowSelectionTable.data; let i = index" appThrottleClick (throttleClick)="onDetail(list); $event.stopPropagation();">
          <td><span>#{{ list.pendingNo }}</span></td>
          <td><span>{{ list.clientName }}</span></td>
          <td><span>{{ list.underwritingNo }}</span></td>
          <td><span>{{ list?.policyNo }}</span></td>
          <td><span>{{ list.project }}</span></td>
          <td><span>{{ list.applyTime | date:'yyyy-MM-dd'}}</span></td>
          <td><span class="text-danger">●</span><span> {{ list.status}}</span></td>
          <td nzRight>
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 appThrottleClick (throttleClick)="onDetail(list); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
