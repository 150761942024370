import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Fna, FnaDraft, FnaStatus, RecommendProduct} from '../fna-types';
import {ProposalService} from '../../proposal/proposal.service';
import {BookingService} from '../../booking/booking.service';
import {ProposalDetailComponent} from '../../proposal/proposal-detail/proposal-detail.component';
import {NzDrawerRef, NzDrawerService} from 'ng-zorro-antd/drawer';
import {BookingDetailComponent} from '../../booking/booking-detail/booking-detail.component';
import {ProposalApplyComponent} from '../../proposal/proposal-apply/proposal-apply.component';
import {FnaClientComponent} from '../fna-client/fna-client.component';
import {FnaFormComponent} from '../fna-form/fna-form.component';
import {Booking} from '../../booking/booking-types';
import {FnaService} from '../fna.service';
import {FnaProductComponent} from '../fna-product/fna-product.component';
import {FnaSignatureComponent} from '../fna-signature/fna-signature.component';
import {ProductService} from '../../product/product.service';
import {BookingCreateComponent} from '../../booking/booking-create/booking-create.component';
import {Router} from '@angular/router';
import {Product} from '../../product/product-types';
import {Customer, DataMode, Instance, Underwriting} from '../../api/types';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { UnderwritingDetailComponent } from '../../underwriting/underwriting-detail/underwriting-detail.component';
import { UnderwritingService } from '../../underwriting/underwriting.service';
import { PolicyDetailComponent } from '../../policy/policy-detail/policy-detail.component';
import { Policy } from '../../policy/policy-types';
import { PolicyService } from '../../policy/policy.service';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-fna-detail',
  templateUrl: './fna-detail.component.html',
  styleUrls: ['./fna-detail.component.less']
})
export class FnaDetailComponent implements OnInit {

  drawerRef: NzDrawerRef<FnaDetailComponent, string>;

  fna: Fna;

  loading = false;
  isBookingInfo = false;
  radioValue = 'OWNER';
  productsForBookingVisible = false;
  productsForProposalVisible = false;
  reusable = true;
  instanceClass: Instance = new Instance();
  @Input()
  mode = DataMode.OWN;

  workbench: Workbench;

  @Output()
  fnaReused: EventEmitter<FnaDraft> = new EventEmitter<FnaDraft>();

  constructor(private router: Router,
              private proposalService: ProposalService,
              private bookingService: BookingService,
              private drawerService: NzDrawerService,
              private fnaService: FnaService,
              private productService: ProductService,
              private workbenchService: WorkbenchService,
              private underwritingService: UnderwritingService,
              private policyService: PolicyService,
  ) {
  }

  ngOnInit(): void {
    this.instanceClass = this.workbenchService.mockInstance;

    this.workbench = this.workbenchService.workbench;
  }

  loadDetail(): void {
    this.loading = true;
    this.fnaService.info(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.fna = fna;
        },
        error => {
          this.loading = false;
        });
  }

  onProposalApply(): void {
    if (this.fna.endProduct?.length > 1) {
      this.productsForProposalVisible = !this.productsForProposalVisible;
      return;
    } else if (this.fna.endProduct?.length === 1) {
      this.proposalApplyWithProduct(this.fna.endProduct[0]);
    } else {
      this.proposalApply();
    }

  }

  proposalApplyWithProduct(recommendProduct: RecommendProduct): void {
    this.productsForProposalVisible = false;
    this.loading = true;
    this.productService.detail(recommendProduct.productCode)
      .subscribe(
        product => {
          this.loading = false;
          this.proposalApply(product);
        },
        error => {
          this.loading = false;
        });
  }

  proposalApply(product: Product = null): void {
    const drawerRef = this.drawerService.create<ProposalApplyComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: ProposalApplyComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.applyReq.fnaId = this.fna.id; // 关联FNA和计划书
      if (product != null) {
        component.productSelected(product);
      }
      component.proposalApplied
        .subscribe(data => {
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onProposalDetail(): void {
    if (!this.fna.proposalId) {
      return;
    }
    this.loading = true;
    this.proposalService.info(this.fna.proposalId)
      .subscribe(
        proposal => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onBookingDetail(): void {
    if (!this.fna.bookingNo) {
      return;
    }
    this.loading = true;
    this.bookingService.detail(this.fna.bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  onUnderwritingDetail(): void {
    if (!this.fna.underwritingNo) {
      return;
    }
    this.loading = true;
    this.underwritingService.detail(this.fna.underwritingNo)
      .subscribe(
        underwritingDetail => {

          this.loading = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: this.mode,
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onPolicyDetail(): void {
    if (!this.fna.policyId) {
      return;
    }
    this.loading = true;
    this.policyService.detail(this.fna.policyId)
      .subscribe(
        policyDetail => {

          this.loading = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policyDetail,
              policy,
              mode: this.mode,
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onFnaClient(): void {
    const drawerRef = this.drawerService.create<FnaClientComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaClientComponent,
      nzContentParams: {
        fna: this.fna,
        customerShot: this.fna.customerShot,
        eyewitness: this.fna.eyewitness,
        mode: this.mode
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onFnaForm(): void {
    const drawerRef = this.drawerService.create<FnaFormComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaFormComponent,
      nzContentParams: {
        fna: this.fna
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
    });

    drawerRef.afterClose.subscribe(data => {
      this.loadDetail();
    });
  }


  onFnaProduct(): void {
    const drawerRef = this.drawerService.create<FnaProductComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaProductComponent,
      nzContentParams: {
        fna: this.fna
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.saved.subscribe(
        data => {
          this.loadDetail();
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onFnaSignature(): void {
    const drawerRef = this.drawerService.create<FnaSignatureComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaSignatureComponent,
      nzContentParams: {
        fna: this.fna
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.submitted.subscribe(
        data => {
          this.loadDetail();
        });
      component.resigned.subscribe(
        data => {
          this.loadDetail();
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onReuse(): void {
    if (!this.reusable) {
      return;
    }
    this.loading = true;
    this.fnaService.reuse(this.fna.id)
      .subscribe(data => {
        this.loading = false;
        this.fnaReused.emit(data);
        this.drawerRef.close();
      }, error => {
        this.loading = false;
      });
  }

  get canBooking(): boolean {
    if (this.loading) {
      return false;
    }
    return this.isFone ? this.fna.endProduct?.length > 0 : this.fna.status === FnaStatus.COMPLETED;
  }

  onBooking(): void {
    if (!this.canBooking) {
      return;
    }
    console.log(this.fna.endProduct);
    if (this.fna.endProduct?.length > 1) {
      this.productsForBookingVisible = !this.productsForBookingVisible;
      return;
    } else if (this.fna.endProduct?.length === 1) {
      this.onProductBooking(this.fna.endProduct[0]);
    }
  }

  onProductBooking(recommendProduct: RecommendProduct): void {
    this.handleClose();
    const res = new Booking();
    res.fnaRelationship = this.radioValue;
    for (const key in this.fna.customerShot) {
      if (this.fna.customerShot[key]) {
        if (this.radioValue === 'OWNER') {
          res.owner[key] = this.fna.customerShot[key];
        } else {
          res.insured[key] = this.fna.customerShot[key];
        }
      }
    }
    this.productsForBookingVisible = false;
    this.loading = true;
    this.productService.detail(recommendProduct.productCode)
      .subscribe(
        product => {
          this.loading = false;

          const drawerRef = this.drawerService.create<BookingCreateComponent, { value: string }, string>({
            nzWidth: 800,
            nzContent: BookingCreateComponent,
            nzContentParams: {
              draftReq: res
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
            component.assignFromFna(product, this.fna);
            component.bookingDraftSaved.subscribe(
              data => {
                this.drawerRef.close();
                this.router.navigate(['/user/booking']).then();
              }
            );
            component.bookingApplied.subscribe(
              data => {
                this.drawerRef.close();
                this.router.navigate(['/user/booking']).then();
              }
            );
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  onViewFna(): void {
    this.loading = true;
    this.fnaService.viewFna(this.fna.id)
      .subscribe(
        data => {
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
          window.open(url);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

  get isFone(): boolean {
    const instance = this.fnaService.mockInstance;
    return instance.groupKey === 'fone' || instance.groupKey === 'dev';
  }

  handleClose(): void {
    this.isBookingInfo = false;
    this.productsForBookingVisible = false;
  }

  bookingNow(): void {
    if (this.canBooking) {
      this.isBookingInfo = true;
    }
  }

}
