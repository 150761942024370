<div class="login-container">

  <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
    <div nz-col [nzSpan]="12" class="login-column">
      <form #loginForm="ngForm" style="width: 100%" class="login-column">
        <img width="150" src="assets/images/logo.png" alt="">

        <div *ngIf="modeType === 'EDIT'" class="tips-top">
          <img width="16" src="assets/images/pwd_warning.png" alt="">
          <span style="padding-left: 3px;">{{'PwdEd' | translate}}</span>
        </div>

        <div *ngIf="modeType === 'EXP'" class="tips-top-error">
          <img width="16" src="assets/images/pwd_error.png" alt="">
          <span style="padding-left: 3px;">{{'PwdExpired' | translate}}</span>
        </div>
<!--        <div style="width: 50%; margin-top: 10px;">-->
<!--          <nz-input-group class="input-group">-->
<!--            <input required [(ngModel)]="editPwd.accountName" id="ant" name="ant"-->
<!--                   [placeholder]="('PleaseEnter' | translate) + ('Account' | translate)" class="login-input"/>-->
<!--          </nz-input-group>-->
<!--        </div>-->

        <div style="width: 50%; margin-top: 10px;">
          <nz-input-group [nzSuffix]="oldPwd" class="input-group">
            <input required [(ngModel)]="editPwd.oldPassword" id="oldPwd" name="oldPwd" [type]="oldPwdVisible ? 'text' : 'password'"
                   [placeholder]="'OldPassword' | translate" class="login-input-change"/>
          </nz-input-group>
          <ng-template #oldPwd>
            <i nz-icon [nzType]="oldPwdVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="oldPwdVisible = !oldPwdVisible"></i>
          </ng-template>
        </div>
        <div style="width: 50%; margin-top: 10px;">
          <nz-input-group [nzSuffix]="suffixPassword" class="input-group">
            <input required [(ngModel)]="newPassword" id="pwd" name="pwd" [type]="passwordVisible ? 'text' : 'password'"
                   [placeholder]="'NewPassword' | translate" class="login-input-change"/>
          </nz-input-group>
          <ng-template #suffixPassword>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="passwordVisible = !passwordVisible"></i>
          </ng-template>
        </div>
        <div style="width: 50%; margin-top: 10px;">
          <nz-input-group [nzSuffix]="newPwd" class="input-group">
            <input required [(ngModel)]="editPwd.newPassword" id="phonePwd" name="phonePwd" [type]="pwdVisible ? 'text' : 'password'"
                   [placeholder]="'ConfirmPasswordAgain' | translate" class="login-input-change"/>
          </nz-input-group>
          <ng-template #newPwd>
            <i nz-icon [nzType]="pwdVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="pwdVisible = !pwdVisible"></i>
          </ng-template>
        </div>

        <div *ngIf="pwdConfig.complexPasswordConfig"
             style="width: 50%; margin-top: 17px;font-size: 12px;color: #BF7000;line-height: 16px;white-space: pre-line;">
          {{'PwdLength' | translate: {length: pwdConfig.passwordLengthConfig} }}
        </div>

        <div style="margin-top: 30px;width: 30%;display: flex;justify-content: space-around;">
          <!--  上一步-->
          <div>
            <button style="width: 50px; height: 50px;box-shadow: 0 4px 24px 0 #F8F8F9;" type="button"
                    nz-tooltip [nzTooltipTitle]="'Back' | translate" [disabled]="logging"
                    nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onBack()">
              <i nz-icon nzType="arrow-left"></i>
            </button>
          </div>

          <div>
            <button style="width: 50px; height: 50px; color: #13263f;box-shadow: 0 4px 24px 0 #F8F8F9;" [nzLoading]="logging"
                    [disabled]="!isPwd"
                    appThrottleClick (throttleClick)="verifyAccount()"
                    nz-tooltip [nzTooltipTitle]="'Confirm' | translate"
                    nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="login"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
