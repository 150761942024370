import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Instance, InstanceAccount, LoginByAccountReq } from '../../api/types';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AccountSwitchComponent } from '../account-switch/account-switch.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Constant } from '../../base/constant';
import { InstancesService } from '../../shared/service/instances.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-by-account',
  templateUrl: './login-by-account.component.html',
  styleUrls: ['./login-by-account.component.less']
})
export class LoginByAccountComponent implements OnInit, AfterViewInit {

  loginReq: LoginByAccountReq = {accountName: '', password: ''};

  logging = false;

  @ViewChild('disclaimerTitle')
  disclaimerTitle: TemplateRef<{}>;

  @ViewChild('disclaimerContent')
  disclaimerContent: TemplateRef<{}>;

  @ViewChild('disclaimerFooter')
  disclaimerFooter: TemplateRef<{}>;

  @ViewChild('agreeButton')
  agreeButton: TemplateRef<any>;
  passwordVisible = false;
  isBack = true;
  isVisible = false;

  accounts: InstanceAccount[];
  instance: Instance = new Instance();
  checked = false;
  checkedDisclaimer = false;
  disclaimerText: string;
  rememberAccount: object;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private modal: NzModalService,
    private drawerService: NzDrawerService,
    private instancesService: InstancesService,
    private translate: TranslateService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.rememberAccount = localStorage.getItem('rememberAccount') ? JSON.parse(localStorage.getItem('rememberAccount')) : {};
    this.checked = !!localStorage.getItem('isCheckBox');
    console.log(this.checked);

    if (this.checked){
      this.loginReq.accountName = this.rememberAccount && this.rememberAccount[this.authService?.instance?.key] ? this.rememberAccount[this.authService?.instance?.key] : null;
    }
    this.accounts = this.authService.accounts;
    if (window.location.href.includes('sales.hkbaylight.com')) {
      localStorage.setItem(Constant.LOCAL_STORAGE_KEY_INSTANCE, JSON.stringify({
        code: 'BIB',
        groupKey: 'baylight',
        key: 'baylight',
        logo: ''
      }));
      this.checkInstance('BIB');
      this.isBack = false;
    }
  }

  checkInstance(code): void {
    this.authService.checkInstance(code)
      .subscribe(
        data => {
          this.authService.instance = data;
          this.instance = data;
          this.instancesService.setUp();
          // this.router.navigate(['/auth/guide-gallery']).then();
        },
        error => {
          // 组织代码错误，提示错误
        });
  }

  ngAfterViewInit(): void {
    this.authService
      .disclaimer()
      .subscribe(
        data => {
          this.disclaimerText = data;
        },
        () => {
        });
  }
  checkedChange(e): void {
    console.log(e);
    this.checked = e;
  }
  loginByAccount(): void {
    if (!this.checkedDisclaimer) {
      this.isVisible = true;
    } else {
      this.loginAccount();
    }
  }

  loginAccount(): void {
    this.logging = true;
    localStorage.setItem('ACC', this.loginReq.accountName);
    this.authService
      .loginByAccount(this.loginReq)
      .subscribe(
        data => {
          console.log(this.checked, 'this.checked');
          localStorage.setItem('isCheckBox', String(Number(this.checked)));

          if (!!this.checked){
            this.rememberAccount[this.authService?.instance?.key] = this.loginReq.accountName;
            localStorage.setItem('rememberAccount', JSON.stringify(this.rememberAccount));
          }
          this.logging = false;
          this.authService.passport = data;
          this.router.navigate(['/user/workbench']).then();
        },
        () => {
          this.logging = false;
        });
  }

  onAccounts(): void {

  }

  onLoginByCode(): void {
    this.router.navigate(['/auth/login-by-code']).then();
  }

  onBack(): void {
    this.router.navigate(['/auth/instance-code']).then();
  }

  createDisclaimerModal(disclaimerText: string, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    const ref = this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: null,
      nzMaskClosable: true,
      nzClosable: true,
      nzComponentParams: {
        value: disclaimerText
      }
    });
    ref.afterOpen.subscribe(() => {
        // this.agreeButton.elementRef.nativeElement.focus();
      }
    );
  }

  onDisagree(modelRef: NzModalRef): void {
    modelRef.destroy();
    this.router.navigate(['/auth/guide-gallery']).then();
  }

  onAgree(modelRef: NzModalRef): void {
    modelRef.destroy();
  }

  onChangePwd(): void {
    this.router.navigate(['/auth/forgot-password']).then();
  }

  onPhoneLogin(): void {
    this.router.navigate(['/auth/phone-number-login']).then();
  }

  get isFone(): boolean {
    const instance = this.authService?.instance;
    return instance?.isFone();
  }

  onAccountSwitch(): void {
    const drawerRef = this.drawerService.create<AccountSwitchComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: AccountSwitchComponent,
      nzContentParams: {
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.loginAccount();
    this.isVisible = false;
  }

}
