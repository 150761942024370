import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnowledgeComponent } from './knowledge.component';
import { KnowledgeMainComponent } from './knowledge-main.component';
import { SharedModule } from '../shared/shared.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { KnowledgeListComponent } from './knowlegde-list/knowledge-list.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { KnowledgeDetailComponent } from './knowledge-detail/knowledge-detail.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';



@NgModule({
  declarations: [
    KnowledgeComponent,
    KnowledgeMainComponent,
    KnowledgeListComponent,
    KnowledgeDetailComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        NzLayoutModule,
        RouterModule,
        TranslateModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzPopoverModule,
        NzButtonModule,
        NzTagModule,
        NzTabsModule,
        NzIconModule
    ]
})
export class KnowledgeModule { }
