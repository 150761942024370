<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 33px;font-size: 18px">
      {{'ClientDetail' | translate}}
    </div>
    <div class="flex">
      <div>
        <img style="border-radius: 50%;margin-right: 18px" width="35" [src]="connectionsInfo.headImgUrl">
      </div>
      <div>
        <p>
          {{connectionsInfo.nickname}}
          <span
            style="display:inline-block; padding: 2px 11px;color: #FFFFFF;background: #1D64C4;border-radius: 4px">{{connectionsInfo.type | metadataTranslate:'connectionsType'}}</span>
        </p>
        <p style="color: #A6A6A6">
          <span>{{connectionsInfo.country}}</span>&nbsp;
          <span>{{connectionsInfo.province}}</span>&nbsp;
          <span>{{connectionsInfo.city}}</span>&nbsp;
        </p>
      </div>
    </div>
    <nz-divider style="margin: 8px 0 20px"></nz-divider>
    <div>
      <span>
        {{'BrowseLengths'| translate}}:&nbsp;{{connectionsInfo.browseArticle}}{{'Articles' | translate}}
      </span>
      <span style="margin-left:60px ">
        {{'NumberOfViews'| translate}}:&nbsp;{{connectionsInfo.browseFrequency}} {{'Count'| translate}}
      </span>
    </div>
    <div>
      <span style="color:#a6a6a6">{{'NewsPreferences'| translate}}:</span>&nbsp;
      &nbsp;<span>{{ showList(connectionsInfo.articleTypeInfos)}}</span>
    </div>
    <div>
      <span style="color:#a6a6a6">{{'ContentKeywords'| translate}}:</span>&nbsp;
      <span>{{showTagsInfos(connectionsInfo.tagsInfos)}}</span>
    </div>
    <div>
      <span style="color:#a6a6a6">{{'ActiveTime' | translate}}:</span>&nbsp;<span>
      {{browseHabit(connectionsInfo.browseHabitInfo)}}
    </span>
    </div>
    <div class="section-title" style="margin-top: 54px;margin-bottom: 31px">
      <span style="margin-left: 8px">{{'ViewActivities' | translate}}</span>
    </div>
    <div style="color: #1D64C4;font-size: 16px;font-weight: 500">
      {{'MostLiked' | translate}}
    </div>
    <nz-divider style="margin: 8px 0"></nz-divider>
    <div>
      <span>{{ 'Preferences' | translate }}</span>
      <span class="mark">"{{showList(connectionsInfo.articleTypeInfos)?.split('、')[0]}}"</span>
      <span *ngIf="showList(connectionsInfo.articleTypeInfos)?.split('、')[1]">
        {{'TagAnd' | translate}} <span class="mark">"{{showList(connectionsInfo.articleTypeInfos)?.split('、')[1]}}
        "</span>
      </span>
      <span>{{'TagInfoContent' | translate}}</span>
    </div>
    <div>
      <div echarts [options]="articleTypeOption" style="height: 400px" [loading]="loading"></div>
    </div>
    <div style="color: #1D64C4;font-size: 16px;font-weight: 500">
      {{'MostLiked' | translate}}
    </div>
    <nz-divider style="margin: 8px 0"></nz-divider>
    <div>
      <span>{{ 'HeBaseLike' | translate }}</span>
      <span class="mark">"{{showTagsInfos(connectionsInfo.tagsInfos)?.split('、')[0]}}"</span>
      <span *ngIf="showTagsInfos(connectionsInfo.tagsInfos)?.split('、')[1]">
       、<span class="mark">"{{showTagsInfos(connectionsInfo.tagsInfos)?.split('、')[1]}}
        "</span>+
      </span>
      <span>{{'KeyContent' | translate}}</span>
    </div>
    <nz-row style="margin-top: 8px" [nzGutter]="30">
      <nz-col *ngFor="let tag of connectionsInfo.tagsInfos; let i = index">
        <div class="tag" [style]="i <=1 ? 'background: #1D64C4;color:#FFFFFF' : 'background: #F3F8FD;color:#404040'">
          {{tag.tag + '(' + tag.frequency + ')'}}
        </div>
      </nz-col>
    </nz-row>
    <div style="color: #1D64C4;font-size: 16px;font-weight: 500;margin-top: 23px">
      {{'MostActive' | translate}}
    </div>
    <nz-divider style="margin: 8px 0"></nz-divider>
    <div *ngIf="connectionsInfo.browseHabitInfo" echarts [options]="browseHabitOption" style="height: 400px"
         [loading]="loading"></div>
    <div class="section-title" style="margin-top: 54px;margin-bottom: 31px">
      <span style="margin-left: 8px">{{'ConnectionsDynamics' | translate}}</span>
    </div>
    <nz-timeline *ngIf="connectionsInfo.connectionsDynamics">
      <nz-timeline-item *ngFor="let dynamic of connectionsInfo.connectionsDynamics;let i = index" nzColor="gray">
        <div>
          {{ dynamic.updateTime ? (dynamic.updateTime | date: 'yyyy-MM-dd HH:mm:ss') : (dynamic.addTime | date: 'yyyy-MM-dd HH:mm:ss') }}
        </div>
        <div class="tag-item">
          <span *ngIf="dynamic.action == 'BROWSE'">
            {{dynamic.action | metadataTranslate:'connectionsDynamicAction'}}
            <span class="mark">"{{dynamic.content}}"</span>
            <span>{{dynamic.frequency}}{{'Count'| translate}}</span>
          </span>
          <span *ngIf="dynamic.action == 'SHARE'">
            {{dynamic.action | metadataTranslate:'connectionsDynamicAction'}}
            <span class="mark">"{{dynamic.content}}"</span>
            <span>{{dynamic.frequency}}{{'Count'| translate}}</span>

          </span>
          <span *ngIf="dynamic.action == 'BECOME'">
            {{dynamic.action | metadataTranslate:'connectionsDynamicAction'}}
            {{connectionsInfo.type | metadataTranslate:'connectionsType'}}
          </span>
        </div>
      </nz-timeline-item>
    </nz-timeline>
  </nz-spin>
</div>
