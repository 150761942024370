import { Component, OnInit } from '@angular/core';
import { ChangePassword, ChangePasswordRes, ChangePwdMode, PasswordConfig } from '../../api/types';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {
  pwdConfig: PasswordConfig = new PasswordConfig();

  t: any; // 定时器
  timeVal: any;
  isOk = false;
  changePwd: ChangePassword = new ChangePassword();
  changePwdType: ChangePwdMode;
  changeInfo: ChangePasswordRes = new ChangePasswordRes();
  logging = false;
  passwordVisible = false;
  constructor(private router: Router,
              private authService: AuthService,
              private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.changePwdType = ChangePwdMode.DEFAULT;
    this.getTips();
  }


  verifyAccount(): void {
    // console.log('下一步', this.changePwd);
    this.authService.getPhoneEmail(this.changePwd)
      .subscribe(
        data => {
          // console.log(data);
          this.changeInfo = data;
          this.changePwdType = ChangePwdMode.PHONE;
        },
        error => {
          console.log(error);
        });
  }

  onPhoneChange(): void {
    // console.log('修改密码');
    this.changePwd.type = this.changePwdType;
    // console.log(this.changePwd);
    this.authService.updatePwd(this.changePwd)
      .subscribe(
        data => {
          // console.log(data);
          if (data) {
            this.translate.get('ModifySuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
            if (this.isOk) {
              clearInterval(this.t);
            }
            this.router.navigate(['auth/login-by-account']).then();
          }
        },
        error => {
          console.log(error);
        });
  }

  onBack(): void {
    switch (this.changePwdType) {
      case ChangePwdMode.DEFAULT:
        if (this.isOk) {
          clearInterval(this.t);
        }
        this.router.navigate(['auth/login-by-account']).then();
        break;
      case ChangePwdMode.PHONE:
        this.changePwd.newPassword = undefined;
        this.changePwd.code = undefined;
        this.changePwdType = ChangePwdMode.DEFAULT;
        break;
      default:
        this.changePwd.newPassword = undefined;
        this.changePwd.code = undefined;
        this.changePwdType = ChangePwdMode.PHONE;
        break;
    }
  }

  isValue(): boolean {
    return !(this.changePwd.newPassword && this.changePwd.code);
  }

  onSend(): void {
    // console.log('获取验证码', this.changePwdType);
    this.changePwd.type = this.changePwdType;
    // console.log(this.changePwd);
    if (!this.isOk) {
      this.countdown();
      // console.log('send,this.isOk= false ');
      this.authService.getCode(this.changePwd)
        .subscribe(
          data => {
            console.log(data, '验证码发送成功');
            if (data) {
              this.translate.get('SendSuccessfullyTips').subscribe(
                (res) => {
                  this.toastr.success(res);
                }
              );
            }
          },
          error => {
            console.log(error);
          });
    }
  }

  toggleEmail(): void {
    // console.log('换邮箱验证');
    this.changePwd.newPassword = undefined;
    this.changePwd.code = undefined;
    this.changePwdType = ChangePwdMode.EMAIL;
  }

  countdown(): void {
    // console.log('计时器');
    clearInterval(this.t);
    let times = 60;
    this.t = setInterval(() => {
      if (--times > 0) {
        this.timeVal = times + 'S';
        this.isOk = true;
        // console.log(times, this.isOk);
      }else {
        this.isOk = false;
        // console.log('关闭', this.isOk);
        clearInterval(this.t);
      }
    }, 1000);
  }

  getTips(): void {
    this.authService.getSignTips()
      .subscribe(
        data => {
          this.pwdConfig = data;
        },
        error => {
          console.log(error);
        });
  }
}
