import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  AnswerInfo,
  BookingAssociateFnaReq,
  BookingAssociateProposalReq,
  BookingBeneficiaryReq,
  BookingInfoReq,
  BookingInsuredReq,
  BookingOwnerReq,
  ContingentOwner,
  Customer,
  CustomerCorporate,
  CustomerType,
  DataMode,
  HealthInfoAnswer,
  HealthType,
  HeathInfo, Instance, Material, OtherAnswer,
  ProductPlanReq,
  QuestionInfo, Relationship
} from '../../api/types';
import { BookingProductPlanComponent } from '../components/booking-product-plan/booking-product-plan.component';
import { CustomerEditComponent } from '../../shared/component/customer-edit/customer-edit.component';
import { BeneficiariesEditComponent } from '../../shared/component/beneficiaries-edit/beneficiaries-edit.component';
import { BookingInfoEditComponent } from '../components/booking-info-edit/booking-info-edit.component';
import { ProposalSelectionComponent } from '../../proposal/proposal-selection/proposal-selection.component';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ProductService } from '../../product/product.service';
import { BookingService } from '../booking.service';
import { Expose, plainToClass, plainToInstance } from 'class-transformer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProposalService } from '../../proposal/proposal.service';
import { ProposalDetailComponent } from '../../proposal/proposal-detail/proposal-detail.component';
import { EventService } from '../../shared/service/event.service';
import { Booking, BookingDetailResp, BookingSignatureUpdateReq, Other } from '../booking-types';
import { Proposal, ProposalSearch, ProposalStatus } from '../../proposal/proposal-types';
import { Product } from '../../product/product-types';
import { DomainMetadataEditComponent } from '../../shared/component/domain-metadata-edit/domain-metadata-edit.component';
import { SecondHolderEditComponent } from '../../shared/component/second-holder-edit/second-holder-edit.component';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { BusinessFormReadComponent } from '../../shared/component/business-form-read/business-form-read.component';
import { HealthInfoComponent } from '../../shared/component/health-info/health-info.component';
import { Fna, FnaSignatureUpdateReq, FnaSignedStatus } from '../../fna/fna-types';
import { FnaSelectionComponent } from '../../fna/fna-selection/fna-selection.component';
import { FnaService } from '../../fna/fna.service';
import { FnaDetailComponent } from '../../fna/fna-detail/fna-detail.component';
import { CommonInputEditComponent } from '../../shared/component/common-input-edit/common-input-edit.component';
import { BookingCreateComponent } from '../booking-create/booking-create.component';
import { Router } from '@angular/router';
import { ReinsuranceComponent } from '../../shared/component/reinsurance/reinsurance.component';
import {
  BookingCustomerReadComponent
} from '../../shared/component/booking-customer-read/booking-customer-read.component';
import { SecondHolderReadComponent } from '../../shared/component/second-holder-read/second-holder-read.component';
import { SignaturePadOptions } from '../../fna/fna-signature-pad/fna-signature-pad.component';
import { SignaturePadComponent } from '../../shared/component/signature-pad/signature-pad.component';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../shared/service/storage.service';
import { estimateLabelUnionRect } from 'echarts/types/src/coord/axisHelper';

@Component({
  selector: 'app-booking-overview',
  templateUrl: './booking-overview.component.html',
  styleUrls: ['./booking-overview.component.less']
})
export class BookingOverviewComponent implements OnInit {

  loading = false;

  @Input()
  bookingDetail: BookingDetailResp;

  @Output()
  bookingDetailChange: EventEmitter<BookingDetailResp> = new EventEmitter<BookingDetailResp>();

  @Input()
  booking: Booking;

  @Input()
  mode = DataMode.OWN;

  product: Product;

  workbench: Workbench;

  heathInformation: HeathInfo;

  instance: Instance;

  isVisible = false;

  signaturePadOptions: SignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 550,
    canvasHeight: 400,
    backgroundColor: 'rgba(0,0,0,0)'
  };

  signatureUrl = '';

  @ViewChild(SignaturePadComponent)
  signaturePad: SignaturePadComponent;

  constructor(private router: Router,
              private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private productService: ProductService,
              private bookingService: BookingService,
              private proposalService: ProposalService,
              private fnaService: FnaService,
              private eventService: EventService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private storageService: StorageService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    // 加载产品信息
    if (this.booking.productCode) {
      this.productService.detail(this.booking.productCode)
        .subscribe(
          data => {
            this.product = data;
          },
          error => {
          });
    }

    this.workbench = this.workbenchService.workbench;

    this.loadingHeathInformation(this.booking.bookingNo);

  }

  onBookingProductPlan(): void {

    const drawerRef = this.drawerService.create<BookingProductPlanComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BookingProductPlanComponent,
      nzContentParams: {
        booking: this.booking,
        product: this.product,
        isDetail: true
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.productPlanSaved
        .subscribe(
          productPlanEntity => {
            this.booking = productPlanEntity.booking;
            this.product = productPlanEntity.product;
            this.saveProductPlan(this.booking, drawerRef);
            // drawerRef.close();
          });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onBookingOwner(): void {
    const instance = this.bookingService.mockInstance;
    const isOpenBankAccountRequired = this.booking.ownerType === CustomerType.INDIVIDUAL && instance.isWanson();
    this.translate.get('Holder').subscribe(
      (holder: any) => {
        const drawerRef = this.drawerService.create<BookingCustomerReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: BookingCustomerReadComponent,
          nzContentParams: {
            title: holder,
            mode: 'holder',
            modeType: 'booking',
            booking: this.booking,
            customerType: this.booking.ownerType,
            customer: this.booking.owner ?? new Customer(),
            customerCorporate: this.booking.companyOwner ?? new CustomerCorporate(),
            otherCustomer: this.booking.insured,
            otherCustomerCorporate: this.booking.companyInsured,
            isOpenBankAccountRequired
          },
        });
        drawerRef?.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.booking.ownerType = customerEntity.customerType;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.booking.owner = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.booking.companyOwner = customerEntity.corporateCustomer;
                    break;
                }
                this.saveOwner(this.booking, drawerRef);
              });
        });
        drawerRef?.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });

  }

  onBookingInsured(): void {
    this.translate.get('Insured').subscribe(
      (insured: any) => {
        const drawerRef = this.drawerService.create<BookingCustomerReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: BookingCustomerReadComponent,
          nzContentParams: {
            title: insured,
            mode: 'insured',
            modeType: 'booking',
            booking: this.booking,
            customerType: this.booking.insuredType,
            relationship: this.booking.relationship,
            customer: this.booking.insured ?? new Customer(),
            customerCorporate: this.booking.companyInsured ?? new CustomerCorporate(),
            otherCustomer: this.booking.owner,
            otherCustomerCorporate: this.booking.companyOwner
          },
        });
        drawerRef?.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.booking.insuredType = customerEntity.customerType;
                this.booking.relationship = customerEntity.relationship;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.booking.insured = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.booking.companyInsured = customerEntity.corporateCustomer;
                    break;
                }
                this.saveInsured(this.booking, drawerRef);
              });
        });
        drawerRef?.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onBookingBeneficiaries(): void {
    this.translate.get('Beneficiaries').subscribe(
      (title: any) => {

        const drawerRef = this.drawerService.create<BeneficiariesEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: BeneficiariesEditComponent,
          nzContentParams: {
            title,
            modeType: 'booking',
            mode: this.mode,
            beneficiaries: this.booking.beneficiaries,
            insured: this.booking.insured,
            bookingDetail: this.booking
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.beneficiariesSaved
            .subscribe(
              data => {
                this.booking.beneficiaries = data;
                this.saveBeneficiaries(this.booking, drawerRef);
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onSecondHolder(): void {
    this.translate.get('SecondHolder').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderReadComponent,
          nzContentParams: {
            title,
            moduleSource: 'booking',
            objectType: 'SecondHolder',
            booking: this.booking,
            contingentOwner: this.booking.contingentOwner ? this.booking.contingentOwner : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.secondHolderSaved
            .subscribe(
              secondHolderInfo => {
                this.loadBookingDetail(this.booking.bookingNo);
                // this.booking.contingentOwner = JSON.parse(JSON.stringify(secondHolderInfo));
                // const bookingOwnerReq = plainToClass(BookingOwnerReq, this.booking, {excludeExtraneousValues: true});
                // this.loading = true;
                // this.bookingService.owner(bookingOwnerReq)
                //   .subscribe(
                //     progress => {
                //       drawerRef.close();
                //       this.loading = false;
                //       this.loadBookingDetail(this.booking.bookingNo);
                //     },
                //     error => {
                //       this.loading = false;
                //     });
              }
            );
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onSecondInsured(): void {
    this.translate.get('SecondInsured').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderReadComponent,
          nzContentParams: {
            title,
            moduleSource: 'booking',
            objectType: 'SecondInsured',
            booking: this.booking,
            contingentOwner: this.booking.contingentInsured ? this.booking.contingentInsured : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.secondHolderSaved
            .subscribe(
              secondInsuredInfo => {
                this.loadBookingDetail(this.booking.bookingNo);

                // this.booking.contingentInsured = JSON.parse(JSON.stringify(secondInsuredInfo));
                // const bookingInsuredReq = plainToClass(BookingInsuredReq, this.booking, {excludeExtraneousValues: true});
                // this.loading = true;
                // this.bookingService.insured(bookingInsuredReq)
                //   .subscribe(
                //     progress => {
                //       drawerRef.close();
                //       this.loading = false;
                //       this.loadBookingDetail(this.booking.bookingNo);
                //     },
                //     error => {
                //       this.loading = false;
                //     });
              }
            );
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onOther(): void {
    const drawerRef = this.drawerService.create<DomainMetadataEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: DomainMetadataEditComponent,
      nzContentParams: {
        domainMetadata: this.booking.domainMetadataVOS,
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.domainSaved
        .subscribe(
          data => {
            const res = {
              bookingNo: this.booking.bookingNo,
              domainMetadata: data
            };
            this.bookingService.domain(res)
              .subscribe(
                info => {
                  this.loadBookingDetail(this.booking.bookingNo);
                }, () => {}
              );
            drawerRef.close();
          });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onStatement(): void {
    const drawerRef = this.drawerService.create<ReinsuranceComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: ReinsuranceComponent,
      nzContentParams: {
        other: this.booking.other ? this.booking.other : new Other(),
        bookingDetail: this.bookingDetail,
        replacementDeclaration: this.bookingDetail.replacementDeclaration
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.reinsuranceSaved
        .subscribe(
          data => {
            const res = {
              bookingNo: this.booking.bookingNo,
              other: undefined,
              replacementDeclaration: undefined
            };
            if (this.bookingDetail.showReplacementDeclaration) {
              res.replacementDeclaration = data;
            } else {
              res.other = data;
            }
            this.bookingService.transfer(res)
              .subscribe(
                info => {
                  this.loadBookingDetail(this.booking.bookingNo);
                }, () => {}
              );
            drawerRef.close();
          });
    });
    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onBookingInfo(): void {
    this.translate.get('BookingInfo').subscribe(
      (title: any) => {

        const booking = plainToInstance(Booking, this.booking);

        const drawerRef = this.drawerService.create<BookingInfoEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: BookingInfoEditComponent,
          nzContentParams: {
            title,
            booking,
            isEdit: true
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.bookingSaved
            .subscribe(
              value => {
                this.booking = value;
                console.log(value);
                this.saveBookingInfo(this.booking, drawerRef);
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onAssociateProposal(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {

    if (this.booking.proposalId) {
      // 換成Dropdown Menu
      // this.showAssociateProposalModal(tplTitle, tplContent, tplFooter);
      return;
    }

    this.showProposalSelection();

  }

  showProposalSelection(): void {

    // 只能关联已完成的计划书
    const search = new ProposalSearch();
    search.status = ProposalStatus.COMPLETED;
    // 2022-12-05 17：00 修改需求 客户要求放开限制
    // search.productCode = this.booking.productCode;
    search.categoryCode = this.product?.categoryCode;
    search.companyCode = this.product?.companyCode;

    const drawerRef = this.drawerService.create<ProposalSelectionComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: ProposalSelectionComponent,
      nzContentParams: {
        search,
        enableOptions: true
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.proposalSelected
        .subscribe(
          value => {
            const proposal: Proposal = value as Proposal;
            this.booking.proposalId = proposal.proposalId;
            this.associateProposal(this.booking, drawerRef);
          });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  showAssociateProposalModal(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
      }
    });
  }

  onProposalDetail(): void {
    if (!this.booking.proposalId) {
      return;
    }
    this.loading = true;
    this.proposalService.info(this.booking.proposalId)
      .subscribe(
        proposal => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onProposalReAssociate(): void {
    this.showProposalSelection();
  }

  onProposalDisassociate(): void {
    this.loading = true;
    this.bookingService.disassociateProposal(this.booking.bookingNo)
      .subscribe(
        progress => {
          this.loading = false;
          this.loadBookingDetail(this.booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  loadBookingDetail(bookingNo: string): void {
    this.loading = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          const booking = bookingDetail.toBooking();
          this.bookingDetail = bookingDetail;
          this.bookingDetailChange.emit(bookingDetail);
          this.booking = booking;
          // 通知列表頁面刷新
          this.eventService.bookingsReload.next();
        },
        error => {
          this.loading = false;
        });
  }

  saveProductPlan(booking: Booking, drawerRef: NzDrawerRef<BookingProductPlanComponent, string>): void {
    const productPlanReq = plainToClass(ProductPlanReq, booking, {excludeExtraneousValues: true});
    this.loading = true;
    this.bookingService.productPlan(productPlanReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  saveBookingInfo(booking: Booking, drawerRef: NzDrawerRef<BookingInfoEditComponent, string>): void {
    const bookingInfoReq = plainToClass(BookingInfoReq, booking, {excludeExtraneousValues: true});
    console.log('bookingInfoReq');
    console.log(bookingInfoReq);
    this.loading = true;
    this.bookingService.book(bookingInfoReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  saveOwner(booking: Booking, drawerRef: NzDrawerRef<CustomerEditComponent | BookingCustomerReadComponent, string>): void {
    drawerRef.close();
    this.loadBookingDetail(booking.bookingNo);
    // const bookingOwnerReq = plainToClass(BookingOwnerReq, booking, {excludeExtraneousValues: true});
    // this.loading = true;
    // this.bookingService.owner(bookingOwnerReq)
    //   .subscribe(
    //     progress => {
    //       drawerRef.close();
    //       this.loading = false;
    //     },
    //     error => {
    //       this.loading = false;
    //     });
  }

  saveInsured(booking: Booking, drawerRef: NzDrawerRef<CustomerEditComponent | BookingCustomerReadComponent, string>): void {
    drawerRef.close();
    this.loadBookingDetail(booking.bookingNo);
    // const bookingInsuredReq = plainToClass(BookingInsuredReq, booking, {excludeExtraneousValues: true});
    // this.loading = true;
    // this.bookingService.insured(bookingInsuredReq)
    //   .subscribe(
    //     progress => {
    //       drawerRef.close();
    //       this.loading = false;
    //       this.loadBookingDetail(booking.bookingNo);
    //     },
    //     error => {
    //       this.loading = false;
    //     });
  }

  saveBeneficiaries(booking: Booking, drawerRef: NzDrawerRef<BeneficiariesEditComponent, string>): void {
    const beneficiaryReq = plainToClass(BookingBeneficiaryReq, booking, {excludeExtraneousValues: true});
    this.loading = true;
    this.bookingService.beneficiary(beneficiaryReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  associateProposal(booking: Booking, drawerRef: NzDrawerRef<ProposalSelectionComponent, string>): void {
    const associateProposalReq = plainToClass(BookingAssociateProposalReq, booking, {excludeExtraneousValues: true});
    this.loading = true;
    this.bookingService.associateProposal(associateProposalReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  openBusinessForm(type: string): void {
    const drawerRef = this.drawerService.create<BusinessFormReadComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BusinessFormReadComponent,
      nzContentParams: {
        policyNo: this.bookingDetail.bookingNo,
        formType: type,
        moduleType: 'booking',
        isRead: true
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.successTitle.subscribe(
        data => {
          drawerRef.close();
        });
    });
  }

  get isShowItem(): boolean {
    const instance = this.bookingService.mockInstance;
    return (instance?.groupKey === 'cash' || instance?.groupKey === 'dev') && (this.bookingDetail.ownerType !== 'CORPORATE' && this.bookingDetail.insuredType !== 'CORPORATE');
  }

  // 获取健康问卷
  loadingHeathInformation(bookingNo: string): void {
    this.loading = true;
    this.bookingService.question(bookingNo)
      .subscribe(
        health => {
          this.loading = false;
          const that = this;
          const healthDetail = plainToInstance(HeathInfo, health, {excludeExtraneousValues: true});
          // 初始化
          that.heathInformation = new HeathInfo();
          if (!healthDetail.ownerOtherAnswer?.otherDiseases) {
            healthDetail.ownerOtherAnswer.otherDiseases = [];
          }
          if (!healthDetail.insuredOtherAnswer?.otherDiseases) {
            healthDetail.insuredOtherAnswer.otherDiseases = [];
          }
          // 基础信息
          that.heathInformation = Object.assign(that.heathInformation, {
            id: healthDetail.id,
            answerOwnerId: healthDetail.answerOwnerId,
            answerInsuredId: healthDetail.answerInsuredId,
            ownerTotal: 0,
            insuredTotal: 0,
            ownerOtherAnswer: healthDetail.ownerOtherAnswer || new OtherAnswer(),
            insuredOtherAnswer: healthDetail.insuredOtherAnswer || new OtherAnswer(),
            // answerOwner: JSON.parse(JSON.stringify(healthDetail.answerOwner)),
            // answerInsured: JSON.parse(JSON.stringify(healthDetail.answerInsured))
          });
          if (healthDetail.questionOwner && healthDetail.questionOwner.length > 0) {
            healthDetail.questionOwner.forEach(question => {
              that.heathInformation.ownerTotal += question.questionnaire.length;
            });
            that.heathInformation.questionOwner = that.getHealthInformationQuestion(healthDetail.questionOwner, healthDetail.answerOwner);
          }
          if (healthDetail.questionInsured && healthDetail.questionInsured.length > 0) {
            healthDetail.questionInsured.forEach((item) => {
              that.heathInformation.insuredTotal = that.heathInformation.insuredTotal + item.questionnaire.length;
            });
            // 处理受保人问题答案数据
            that.heathInformation.questionInsured = that.getHealthInformationQuestion(healthDetail.questionInsured, healthDetail.answerInsured);
          }
          // console.log(this.heathInformation);
        },
        error => {
          this.loading = false;
        }
      );

  }

  onHealthInfo(): void {
    this.translate.get('HealthInformation').subscribe(
      (title: any) => {
        const healthType: HealthType = {
          ownerType: this.booking.ownerType,
          relationship: this.booking.relationship,
          edit: this.bookingDetail.edit
        };
        const drawerRef = this.drawerService.create<HealthInfoComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: HealthInfoComponent,
          nzContentParams: {
            heathInformation: this.heathInformation,
            healthType,
            modeType: 'booking'
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.healthSaved.subscribe(
            param => {
              console.log(param);
              let folist = [];
              if (healthType.ownerType === CustomerType.CORPORATE) {
                folist = [{
                  id: param.answerInsuredId,
                  role: 'INSURED',
                  answer: JSON.stringify(param.questionInsured),
                  otherAnswer: param.insuredOtherAnswer
                }];
              }
              if (healthType.relationship === Relationship.OWN) {
                folist = [{
                  id: param.answerOwnerId,
                  role: 'OWNER',
                  answer: JSON.stringify(param.questionOwner),
                  otherAnswer: param.ownerOtherAnswer
                }];
              }
              let params: HealthInfoAnswer = new HealthInfoAnswer();
              params = {
                bookingNo: this.bookingDetail.bookingNo,
                policyNo: undefined,
                policyId: undefined,
                underwritingNo: undefined,
                questionId: this.heathInformation.id,
                foList: folist.length > 0 ? folist : [{
                  id: this.heathInformation.answerOwnerId,
                  role: 'OWNER',
                  answer: JSON.stringify(param.questionOwner),
                  otherAnswer: param.ownerOtherAnswer
                },
                  {
                    id: this.heathInformation.answerInsuredId,
                    role: 'INSURED',
                    answer: JSON.stringify(param.questionInsured),
                    otherAnswer: param.insuredOtherAnswer
                  }
                ]
              };
              this.bookingService.bookingAnswer(params).subscribe(
                data => {
                  drawerRef.close();
                  this.loading = false;
                  this.loadBookingDetail(this.booking.bookingNo);
                  this.loadingHeathInformation(this.booking.bookingNo);
                }, error => {
                  this.loading = false;
                }
              );

            }
          );
        });


        drawerRef.afterClose.subscribe(() => {

        });
      },
      error => {
      }
    );
  }

  getHealthInformationQuestion(qusetionEntity: QuestionInfo[], answerEntity: AnswerInfo[]): QuestionInfo[] {
    const that = this;
    if (!answerEntity) {
      answerEntity = [];
    }
    let questionTotal = 0;
    // 循环问题种类
    qusetionEntity.forEach((item) => {
      // 循环具体问题
      item.questionnaire.forEach((questionnaireItem) => {
        // 记录当前问题条数
        questionnaireItem.index = ++questionTotal;
        // 查找问题数组和答案数组，根据id关联
        // const answerItem = _.find(answerEntity, function(o) {
        //   return o.questionId == questionnaireItem.questionId;
        // });

        const answerItem = answerEntity.find(o => {
          return o.questionId === questionnaireItem.questionId;
        });
        // 判空
        questionnaireItem.value = answerItem ? answerItem.value : null;
        questionnaireItem.answer.forEach((answer) => {
          if (questionnaireItem.value === answer.answerId && answer.attachment != null) {
            if (answer.attachment.type === 'form') {
              const headers = [];
              answer.attachment.headers.forEach((headerItem) => {
                // const inItem = _.find(answer.attachment.headers, function(o) {
                //   return o.attachmentId == headerItem.attachmentId;
                // });
                const inItem = answerItem.attachment.headers.find(o => {
                  return o.attachmentId === headerItem.attachmentId;
                });
                if (!!inItem) {
                  const params = that.getParams(headerItem, inItem);
                  headers.push(params);
                }
              });
              answer.attachment.headers = headers;
            } else {
              const inValue = [];
              answerItem.attachment.values.forEach((valueItem) => {
                const inValueSub = [];
                valueItem.forEach((vItem) => {
                  const headerItem = answer.attachment.headers.find(o => {
                    return o.attachmentId === vItem.attachmentId;
                  });
                  if (headerItem) {
                    const params = that.getParams(headerItem, vItem);
                    inValueSub.push(params);
                  }
                });
                inValue.push(inValueSub);
              });
              if (inValue.length < 1) {
                inValue.push(answer.attachment.headers);
              }
              answer.attachment.values = inValue;
            }
          } else {
            if (answer.attachment != null && answer.attachment.type === 'table') {
              answer.attachment.values = [];
              answer.attachment.values.push(answer.attachment.headers);
            }
          }
        });
      });
    });
    return qusetionEntity;
  }

  getParams(header, item): { inputValue: any; startTime: any; attachmentId: any; label: any; endTime: any; type: any; value: any } {
    const params = {
      attachmentId: header.attachmentId || null,
      label: header.label || null,
      value: header.value || null,
      type: header.type || null,
      required: header.required || false,
      inputValue: item.inputValue || null,
      startTime: item.startTime || null,
      endTime: item.endTime || null
    };
    return params;
  }

  onAssociateFNA(): void {
    if (this.booking.fnaId) {
      return;
    }
    this.showFNASelection(false);
  }

  showFNASelection(isReAssociate: boolean): void {

    const drawerRef = this.drawerService.create<FnaSelectionComponent, { value: any }, string>({
      nzWidth: 1024,
      nzMaskClosable: true,
      nzContent: FnaSelectionComponent,
      nzContentParams: {
        mode: this.mode,
        accountName: this.booking.accountName
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.fnaSelected
        .subscribe(
          value => {
            const fna: Fna = value as Fna;
            if (isReAssociate) {
              this.onFnaDisassociate(() => {
                this.associateFna(this.booking.bookingNo, fna.id, drawerRef);
              });
            } else {
              this.associateFna(this.booking.bookingNo, fna.id, drawerRef);
            }
          });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  associateFna(bookingNo: string, fnaId: number, drawerRef: NzDrawerRef<FnaSelectionComponent, string>): void {
    const associateFnaReq = new BookingAssociateFnaReq();
    associateFnaReq.bookingNo = bookingNo;
    associateFnaReq.fnaId = fnaId;
    this.loading = true;
    this.bookingService.associateFNA(associateFnaReq)
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.loadBookingDetail(bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  onFnaDetail(): void {
    if (!this.booking.fnaId) {
      return;
    }
    this.loading = true;
    this.fnaService.info(this.booking.fnaId)
      .subscribe(
        fna => {
          this.loading = false;
          const drawerRef = this.drawerService.create<FnaDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: FnaDetailComponent,
            nzContentParams: {
              fna,
              reusable: false
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onFnaReAssociate(): void {
    this.showFNASelection(true);
  }

  onFnaDisassociate(disassociateSuccess?: () => void): void {
    if (!this.booking.fnaId) {
      return;
    }
    this.loading = true;
    this.bookingService.disassociateFNA(this.booking.bookingNo)
      .subscribe(
        progress => {
          this.loading = false;
          this.loadBookingDetail(this.booking.bookingNo);
          disassociateSuccess?.();
        },
        error => {
          this.loading = false;
        });
  }

  async onBookingRemark(): Promise<void> {
    const title = await this.translate.get('BookingRemark').toPromise();
    const drawerRef = this.drawerService.create<CommonInputEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: CommonInputEditComponent,
      nzContentParams: {
        type: 'textarea',
        title,
        value: this.booking.special
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.saved.subscribe(
        text => {
          this.booking.special = text;
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onChange(flag): void {
    this.bookingService.putPaperContracts({bookingNo: this.booking.bookingNo, isPaperContracts: flag})
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
        });
  }

  onEmpireChange(flag): void {
    this.bookingService.putPaperContracts({bookingNo: this.booking.bookingNo, authorizeCollect: flag})
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
        });
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  onRewriteConfirm(): void {
    if (!this.canRewrite) {
      return;
    }
    this.bookingDetail.signatureInformation = [];
    this.signatureUrl = null;
    this.signaturePad?.clear();
  }

  get canRewrite(): boolean {
    if (!this.bookingDetail.edit) {
      return false;
    }
    if (this.signatureUrl) {
      return true;
    }
    // if (!!this.fna.underwritingNo) {
    //   return false;
    // }
    return true;
  }
  get canConfirm(): boolean {
    if (!this.bookingDetail.edit) {
      return false;
    }
    if (this.signatureUrl) {
      return false;
    }
    // return !(this.itemSignatureInfo.signatureStatus === 'SIGNED' || !!this.fna.underwritingNo);
    return true;
  }

  onOpenSignature(): void {
    console.log(this.booking.signatureInformation);
    console.log(this.bookingDetail.signatureInformation);
    this.bookingDetail.signatureInformation = JSON.parse(JSON.stringify(this.bookingDetail.signatureInformation));
    this.imgPath();
    this.isVisible = true;
  }

  onConfirm(): void {
    if (!this.canConfirm) {
      return;
    }
    const updateReq = new BookingSignatureUpdateReq();
    updateReq.bookingNo = this.bookingDetail.bookingNo;
    updateReq.imageBase64 = this.signaturePad.toDataURL();
    updateReq.signatureType = 'OWNER';
    console.log(updateReq, 'updateReq');
    if (!this.signaturePad.toData().length) {
      this.translate.get('UnsignedTip').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      return;
    }
    this.bookingService.signature(updateReq)
      .subscribe(
        data => {
          this.isVisible = false;
          this.signatureUrl = '';
          this.signaturePad.clear();
          this.loadBookingDetail(updateReq.bookingNo);
        },
        error => {
        });

  }


  imgPath(): void {
    let material: Material = new Material();
    if (this.booking.signatureInformation && this.booking.signatureInformation.length) {
      material = this.booking.signatureInformation[0].signatureUrl;
    }
    if (material && material.filePath) {
      this.storageService.accessToken(material.filePath)
        .subscribe(
          data => {
            this.signatureUrl = data.accessToken;
          },
          error => {
          });
    } else {
      this.signatureUrl = '';
    }
  }

}
