import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../core/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  PaymentTemplate, PaymentTemplateParam,
  PolicyInfo,
  PolicyListResp,
  PolicyReminderExportCondition,
  PolicySearch
} from './policy-reminder-types';
import { ApiResponse, DataMode, DialogReply, HeathInfo, PagedResp, PendingReply } from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class PolicyReminderService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }


  list(search: PolicySearch, type: DataMode): Observable<PagedResp<PolicyListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.policyNo != null) {
      httpParams = httpParams.set('policyNo', search.policyNo.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.paymentStatus != null) {
      httpParams = httpParams.set('paymentStatus', search.paymentStatus.toString());
    }
    if (search.month != null) {
      httpParams = httpParams.set('month', search.month.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    if (search.autoTransfer != null) {
      httpParams = httpParams.set('autoTransfer', search.autoTransfer.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.time != null) {
      httpParams = httpParams.set('time', search.time.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/list';
        break;
      case DataMode.SUBORDINATE:
        uri = '/list-subordinate';
        break;
      case DataMode.SALESGROUP:
        uri = '/list-sales-group';
        break;
      case DataMode.FOLLOWUP:
        uri = '/list-follow-up';
        break;
    }
    return this.http.get<ApiResponse<PagedResp<PolicyListResp>>>(API.paymentV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  dialogList(policyId: string): Observable<PendingReply[]> {
    return this.http.get<ApiResponse<PendingReply[]>>(API.policyV3 + '/dialog-list/' + policyId, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingReply, data.body.data);
    }));
  }

  dialogReply(dialogRep: DialogReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.policyV3 + '/dialog-save', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 详情
   * @param policyId policyId
   */
  detail(policyId: string): Observable<PolicyInfo> {
    return this.http.get<ApiResponse<PolicyInfo>>(API.policyV3 + '/info/' + policyId, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PolicyInfo, data.body.data);
    }));
  }


  /**
   * 获取续保通知模板
   */
  templateDetail(): Observable<PaymentTemplate[]> {
    return this.http.get<ApiResponse<PaymentTemplate[]>>(API.paymentV3 + '/template', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 根据缴费记录编号获取续保提醒通知内容
   */
  templateContent(params: PaymentTemplateParam): Observable<any> {
    let httpParams = new HttpParams();
    if (params.paymentId != null) {
      httpParams = httpParams.set('paymentId', params.paymentId.toString());
    }
    if (params.templateId != null) {
      httpParams = httpParams.set('templateId', params.templateId.toString());
    }
    return this.http.get<ApiResponse<any>>(API.paymentV3 + '/get-content-payment', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 健康问卷
   * @param policyId 保单号
   */
  question(policyId: string): Observable<HeathInfo> {
    let httpParams = new HttpParams();
    if (policyId != null) {
      httpParams = httpParams.set('policyId', policyId);
    }
    // @ts-ignore
    return this.http.get<ApiResponse<HeathInfo>>(API.policyV3 + '/question', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(HeathInfo, data.body.data);
    }));
  }

  /**
   * 导出excel
   */
  downloadExcel(exportCondition: PolicyReminderExportCondition): Observable<any> {
    return this.httpClient
      .post(API.paymentV3 + '/export', exportCondition, {
        // params: httpParams,
        responseType: 'blob',
        observe: 'response'
      }).pipe(map((arrayBuffer: any) => arrayBuffer));
  }

}
