import { Component, OnInit } from '@angular/core';
import { ChangePassword, ChangePasswordRes, ChangePwdMode, PasswordConfig } from '../../api/types';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import { NewFnaDetail } from '../../new-fna/new-fna-types';

@Component({
  selector: 'app-cryptographic-strengt',
  templateUrl: './cryptographic-strengt.component.html',
  styleUrls: ['./cryptographic-strengt.component.less']
})
export class CryptographicStrengtComponent implements OnInit {
  modeType: string;
  logging = false;
  oldPwdVisible = false;
  passwordVisible = false;
  pwdVisible = false;
  newPassword: any;
  editPwd = {
    accountName: undefined,
    oldPassword: undefined,
    newPassword: undefined,
  };

  pwdConfig: PasswordConfig = new PasswordConfig();

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private routerService: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.getTips();
    this.routerService
      .queryParams
      .subscribe(params => {
        console.log(params);
        if (params['type']) {
          this.modeType = params['type'];
        }
      });
    this.editPwd.accountName = localStorage.getItem('ACC');
  }

  verifyAccount(): void {
    if (this.newPassword === this.editPwd.newPassword) {
      console.log('', this.editPwd);
      this.authService.changeSignPwd(this.editPwd)
        .subscribe(
          data => {
            this.translate.get('ModifySuccess').subscribe(
              (res: any) => {
                this.toastr.warning(res);
              });
            this.onBack();
          },
          error => {
            console.log(error);
          });
    } else {
      this.translate.get('SecondaryPasswordInconsistent').subscribe(
        (res: any) => {
          this.toastr.warning(res);
        });
    }
  }

  onBack(): void {
    this.router.navigate(['auth/login-by-account']).then();
  }

  onSend(): void {
    // console.log('获取验证码', this.changePwdType);
  }

  getTips(): void {
    this.authService.getSignTips()
      .subscribe(
        data => {
          this.pwdConfig = data;
        },
        error => {
          console.log(error);
        });
  }

  get isPwd(): boolean {
    return !!this.editPwd.accountName && !!this.newPassword && !!this.editPwd.newPassword;
  }

}
