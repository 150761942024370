<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div class="property-row" >
      <div class="property-title-row" *ngIf="title" [style]="titleColor ? ('color:' + titleColor) : ''">{{ title }}</div>
      <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      <div class="property-value-row">
        <div class="property-pick-value" [style]="{'color': valueColor, 'cursor': valueCursor}">
          <span *ngIf="value" style="flex: 1; margin-right: 4px;">{{value}}</span>
          <span *ngIf="!value" style="flex: 1; color: #DDDDDD; text-align: right; margin-right: 4px">{{placeholder}}</span>
          <span style="color: rgba(0,0,0,0.25);">
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="property-tip" style="font-size: 11px;"
         [style.color]="tipColor"
         *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
  <div *ngSwitchCase="'vertical'">
    <div class="property-column">
      <div class="property-title-column" *ngIf="title">
        <span [style]="titleColor ? ('color:' + titleColor) : ''">{{ title }}</span>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      </div>
      <div class="property-value-column">
        <div class="property-pick-value">
          <span *ngIf="value" style="flex: 1">{{value}}</span>
          <span *ngIf="!value" style="flex: 1; color: #DDDDDD; text-align: left">{{placeholder}}</span>
          <span style="color: rgba(0,0,0,0.25);">
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible"
         style="font-size: 11px;"
         [style.color]="tipColor">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
</div>
