import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../../shared/component/product-selection/product-selection.component';
import { Frequency, Instance, ProductPlan } from '../../../api/types';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {PropertySelectOption} from '../../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../../shared/service/metadata.service';
import {TranslateService} from '@ngx-translate/core';
import {Booking} from '../../booking-types';
import {Product} from '../../../product/product-types';

export class ProductPlanEntity {
  booking: Booking;
  product: Product;
}

@Component({
  selector: 'app-booking-product-plan',
  templateUrl: './booking-product-plan.component.html',
  styleUrls: ['./booking-product-plan.component.less']
})
export class BookingProductPlanComponent implements OnInit {

  booking = new Booking();
  copyBooking = new Booking();

  product: Product = new Product();

  currencyOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  customPaymentTermOptions: PropertySelectOption[] = [];
  paymentFrequencyOptions: PropertySelectOption[] = [];
  paymentTypeOptions: PropertySelectOption[] = [];
  dividendOptions: PropertySelectOption[] = [];
  prepayOptions: PropertySelectOption[] = [];
  @Input()
  isDetail = false;

  instance: Instance;

  @Output()
  productPlanSaved: EventEmitter<ProductPlanEntity> = new EventEmitter<ProductPlanEntity>();

  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;

    const paymentTypes = this.metadataService.values('paymentType');
    for (const paymentType of paymentTypes) {
      this.paymentTypeOptions.push(new PropertySelectOption(paymentType.value, paymentType.key));
    }
    const dividends = this.metadataService.values('dividends');
    for (const dividend of dividends) {
      this.dividendOptions.push(new PropertySelectOption(dividend.value, dividend.key));
    }

    setTimeout(() => {
        if (this.product) {
          this.productSelected(this.product);
        }
      },
      100);

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.prepayOptions.push(new PropertySelectOption(res.Yes, true));
        this.prepayOptions.push(new PropertySelectOption(res.No, false));
      });
    this.copyBooking = JSON.parse(JSON.stringify(this.booking));
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        copyProduct: this.product,
        isBasic: true
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.productSelected(product);
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  productSelected(product: Product): void {
    console.log(product, 'productproductproductproduct');
    this.product = product;

    this.currencyOptions = [];
    this.booking.custom = product.custom;
    this.booking.companyCode = product.companyCode;
    this.booking.companyName = product.companyName;
    this.product.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });
    if (!this.product.currencies?.includes(this.booking.productPlan.currency)){
      this.booking.productPlan.currency = undefined;
    }
    if (!this.booking.productPlan.currency) {
      this.booking.productPlan.currency = this.product.currencies ? this.product.currencies[0] : null;
    }

    this.paymentTermOptions = [];
    this.product.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });
    if (!this.product.paymentTerm?.includes(this.booking.productPlan.productYear)){
      this.booking.productPlan.productYear = undefined;
    }
    this.customPaymentTermOptions = [];
    this.product.customPaymentTerm?.forEach(term => {
      this.customPaymentTermOptions.push(new PropertySelectOption(this.getYearsConvert(term), term));
    });
    console.log(this.customPaymentTermOptions);
    if (!this.booking.productPlan.productYear) {
      this.booking.productPlan.productYear = this.product.paymentTerm ? this.product.paymentTerm[0] : null;
    }

    this.paymentFrequencyOptions = [];
    this.product.frequencies?.forEach(freuency => {
      this.paymentFrequencyOptions.push(new PropertySelectOption(this.paymentFrequencyTranslate(freuency), freuency));
    });
    if (!this.product.frequencies?.includes(this.booking.productPlan.frequency)){
      this.booking.productPlan.frequency = undefined;
    }
    if (!this.booking.productPlan.frequency) {
      this.booking.productPlan.frequency = this.product.frequencies ? this.product.frequencies[0] : null;
    }
    if (!this.booking.productPlan.sumAssured) {
      this.booking.productPlan.sumAssured = 0;
    }
    this.booking.productCode = product.productCode;
    console.log(this.booking);
  }

  paymentFrequencyTranslate(frequency: Frequency): string {
    return this.metadataService.translate('frequency', frequency);
  }

  getYearsConvert(text: string): string {
    let value = null;
    if (text.includes('~')) {
      this.translate.get('ProductAge', {condition: text.replace('~', '')}).subscribe(
        (res: any) => {
          value = res;
        });
    }
    if (text.includes('Y')) {
      this.translate.get('ProductYears', {condition: text.replace('Y', '')}).subscribe(
        (res: any) => {
          value = res;
        });
    }
    if (text.includes('M')) {
      this.translate.get('ProductMonth', {condition: text.replace('M', '')}).subscribe(
        (res: any) => {
          value = res;
        });
    }
    return value;
  }

  onReset(): void {
    this.booking.productPlan = new ProductPlan();
    if (this.isDetail) {
      this.booking.prepay = undefined;
      this.booking.prepayPremium = undefined;
    }
    this.booking.riders = [];
    this.booking.backtrack = undefined;
    this.booking.policyDate = undefined;
    this.booking.guarantee = undefined;
    // this.booking = new Booking();
    this.product = new Product();
    this.currencyOptions = [];
    this.paymentTermOptions = [];
    this.paymentFrequencyOptions = [];
  }

  onSave(): void {
    const productPlanEntity = new ProductPlanEntity();
    productPlanEntity.booking = this.booking;
    productPlanEntity.product = this.product;
    if (productPlanEntity.booking.custom) {
      productPlanEntity.booking.productPlan.productYear = '1';
    }
    this.productPlanSaved.emit(productPlanEntity);
  }

}
