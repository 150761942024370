import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  DataDesensitization,
  DataMode,
  Instance,
  PagedResp,
  PendingReply,
  Underwriting,
  UploadTokenReq,
  UploadType
} from '../../api/types';
import {
  PaymentTemplateParam,
  Policy,
  PolicyListResp,
  PolicyReminderExportCondition,
  PolicySearch
} from '../policy-reminder-types';
import { MetadataService } from '../../shared/service/metadata.service';
import { EventService } from '../../shared/service/event.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { MessageDialogComponent } from '../../shared/component/message-dialog/message-dialog.component';
import { PolicyReminderSearchComponent } from '../policy-reminder-search/policy-reminder-search.component';
import { PolicyReminderService } from '../policy-reminder.service';
import { PolicyDetailComponent } from '../../policy/policy-detail/policy-detail.component';
import { UnderwritingSearch } from '../../underwriting/underwriting-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { BaseComponent } from '../../base/base.component';
import { DatePipe } from '@angular/common';
import { MessageService } from '../../message/message.service';
import { AccountService } from '../../account/account.service';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';

@Component({
  selector: 'app-policy-reminders',
  templateUrl: './policy-reminders.component.html',
  styleUrls: ['./policy-reminders.component.less'],
  providers: [DatePipe]
})
export class PolicyRemindersComponent extends BaseComponent implements OnInit {


  @Input()
  mode = DataMode.OWN;
  loading = false;
  uploadTokenReq: UploadTokenReq = new UploadTokenReq();
  spinning = false;
  dialogList: PendingReply[];
  policies: PagedResp<PolicyListResp>;

  search: PolicySearch = new PolicySearch();

  statusOptions: PropertySelectOption[] = [];
  templateOptions: PropertySelectOption[] = [];

  premiumsDueDates: number[];

  exportCondition: PolicyReminderExportCondition = new PolicyReminderExportCondition();

  exportPopoverVisible = false;
  visible = false;
  isSpinning = false;
  rowPolicyId: any = '';
  templateId = '';
  templateContent = '';
  workbench: Workbench;

  instance: Instance;

  dataDesensitization: DataDesensitization = new DataDesensitization();
  constructor(private policyReminderService: PolicyReminderService,
              private metadataService: MetadataService,
              private eventService: EventService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private router: Router,
              private workbenchService: WorkbenchService,
              private datePipe: DatePipe,
              private el: ElementRef,
              private accountService: AccountService,
              private drawerService: NzDrawerService,
              private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;

    this.dataDesensitization = this.accountService.dataDesensitization;
    this.workbench = this.workbenchService.workbench;
    this.loading = true;

    this.policyReminderService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.policies = data;
        },
        error => {
          this.loading = false;
        });
    this.eventService.underwritingsReload
      .subscribe(
        data => {
          this.onReload(false);
        });

    const statusList = this.metadataService.values('paymentStatus');

    // this.translate.get('All').subscribe(
    //   data => {
    //     const option: PropertySelectOption = new PropertySelectOption(data, '');
    //     this.statusOptions = [option];
    //     for (const status of statusList) {
    //       this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    //     }
    //   }
    // );

    this.statusOptions = [];
    for (const status of statusList) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }

    this.getTemplate();

  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.policyReminderService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.policies = data;
        },
        error => {
          this.loading = false;
        });
  }

  onPageSizeChange(pageSize: number): void {
    this.search.pageSize = pageSize;
    this.loading = true;
    this.policyReminderService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.policies = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.policyReminderService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.policies = data;
        },
        error => {
          this.loading = false;
        });
  }

  openSearch(): void {
    const drawerRef = this.drawerService.create<PolicyReminderSearchComponent, { value: UnderwritingSearch }, string>({
      nzWidth: 800,
      nzContent: PolicyReminderSearchComponent,
      nzContentParams: {
        search: this.search,
        mode: this.mode
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.policySearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(1);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onPolicyDetail(policyId: string): void {
    this.spinning = true;
    this.policyReminderService.detail(policyId)
      .subscribe(
        policyDetail => {

          this.spinning = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policy,
              policyDetail,
              mode: this.mode,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.onReload(false);
            this.messageService.messageUnreadEvent.emit('');
          });
        },
        error => {
          this.spinning = false;
        });
  }

  onPolicyDialog(policyId: string): void {
    this.uploadTokenReq.uploadType = UploadType.POLICY;
    this.uploadTokenReq.middleName = policyId;
    this.translate.get('LeaveMessage').subscribe(
      title => {
        this.policyReminderService.dialogList(policyId)
          .subscribe(
            data => {
              this.dialogList = data;
              const drawerRef = this.drawerService.create<MessageDialogComponent, { value: Underwriting }, string>({
                nzWidth: 800,
                nzContent: MessageDialogComponent,
                nzContentParams: {
                  Title: title,
                  list: this.dialogList,
                  uploadTokenReq: this.uploadTokenReq,
                  moduleId: policyId
                }
              });
              drawerRef.afterOpen.subscribe(() => {
                const component = drawerRef.getContentComponent();
                component.dialogReplied.subscribe(
                  dialogRep => {
                    this.policyReminderService.dialogReply(dialogRep).subscribe(
                      () => {
                        this.policyReminderService.dialogList(policyId).subscribe(
                          list => {
                            this.dialogList = list;
                            component.loadList(this.dialogList);
                          }
                        );
                      }
                    );
                  }
                );
              });

              drawerRef.afterClose.subscribe(() => {
                this.messageService.messageUnreadEvent.emit('');
              });
            }
          ).disposedBy(this.disposeBag);

      }
    );
  }

  getTemplate(): void {
    this.policyReminderService.templateDetail()
     .subscribe(
        data => {
          if (data && data.length) {
            this.templateOptions = data.map(item => new PropertySelectOption(item.name, item.id));
          } else {
            this.templateOptions = [];
          }
        },
        error => {}
      );
  }

  getTemplateContent(): void {
    if (!this.templateId) {
      return;
    }
    const params: PaymentTemplateParam = {
      paymentId: this.rowPolicyId,
      templateId: this.templateId
    };
    this.isSpinning = true;
    this.policyReminderService.templateContent(params)
      .subscribe(
        data => {
          console.log(data);
          this.templateContent = data;
          this.isSpinning = false;
        },
        error => {
          this.isSpinning = false;
        }
      );
  }

  onTemplateChange(event: any): void {
    this.getTemplateContent();
  }

  onDrawer(id: number): void {
    console.log('onDrawer');
    this.visible = true;
    this.rowPolicyId = id;
    this.templateId = this.templateOptions[0].value;
    this.getTemplateContent();
  }

  onDrawerClose(): void {
    console.log('onDrawer');
    this.visible = false;
    this.templateId = null;
    this.templateContent = null;
  }

  async onCopyToClipboard(flag: boolean): Promise<void> {
    if (!this.templateContent) {
      return;
    }
    const content = document.getElementById('templateContent');
    // 不使用innerHTML 原因 会粘贴 <br> 标签
    const htmlContent = content.textContent;
    // 将 HTML 内容写入剪贴板
    await navigator.clipboard.writeText(htmlContent)
      .finally(() => {
        this.translate.get('CopySuccess').subscribe(
          data => {
            this.toastr.success(data);
          }
        );
    });
    this.visible = flag;
  }

  onCancel(): void {
    console.log('onDrawer');
    this.visible = false;
  }

  onPremiumDueDateChange(dates: number[]): void {
    this.premiumsDueDates = dates;
    if (dates) {
      const startTime = this.datePipe.transform(dates[0], 'shortDate');
      const endTime = this.datePipe.transform(dates[1], 'shortDate');
      // console.log( this.datePipe.transform(new Date(startTime).getTime(), 'yyyy-MM-dd HH:mm:ss') );
      // console.log( this.datePipe.transform(new Date(endTime).getTime() + 24 * 60 * 60 * 1000 - 1000, 'yyyy-MM-dd HH:mm:ss') );
      this.exportCondition.startTime = new Date(startTime).getTime();
      this.exportCondition.endTime = new Date(endTime).getTime() + 24 * 60 * 60 * 1000 - 1000;
    } else {
      this.exportCondition.startTime = null;
      this.exportCondition.endTime = null;
    }
  }

  // 加**
  dataMasking(str): string {
    if (!str) {
      return ;
    }
    if (str.toString().length === 1) {
      return '*';
    }
    str = str.toString();
    let frontLen = 0;
    let endLen = 0;
    switch (str.length) {
      case 2:
      case 3:
        frontLen = 1;
        endLen = 0;
        break;
      case 4:
      case 5:
        frontLen = 1;
        endLen = 1;
        break;
      case 6:
      case 7:
      case 8:
        frontLen = 2;
        endLen = 1;
        break;
      default:
        frontLen = 3;
        endLen = 3;
        break;
    }
    const len = str.length - frontLen - endLen;
    let xing = '';
    for (let i = 0; i < len; i++) {
      xing += '*';
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
  }

  dynamicEncryption(keyName, val): string {
    let mask = val;
    switch (this.mode) {
      case DataMode.OWN:
        this.dataDesensitization?.mines?.map(item => {
          if (item.name === keyName && item.mask) {
            mask = this.dataMasking(val);
          }
        });
        break;
      case DataMode.SUBORDINATE:
        this.dataDesensitization?.subordinates?.map(item => {
          if (item.name === keyName && item.mask) {
            mask = this.dataMasking(val);
          }
        });
        break;
    }
    return mask;
  }

  get canExport(): boolean {
    if (this.loading) {
      return false;
    }
    if (!this.exportCondition.startTime || !this.exportCondition.endTime) {
      return false;
    }
    return true;
  }

  onResetExportCondition(): void {
    this.premiumsDueDates = null;
    this.exportCondition = new PolicyReminderExportCondition();
  }

  onExport(): void {
    if (!this.canExport) {
      return;
    }
    this.loading = true;

    switch (this.mode) {
      case DataMode.OWN:
        this.exportCondition.type = 1;
        break;
      case DataMode.SUBORDINATE:
        this.exportCondition.type = 2;
        break;
      case DataMode.SALESGROUP:
        this.exportCondition.type = 3;
        break;
      case DataMode.FOLLOWUP:
        this.exportCondition.type = 4;
        break;
      default:
        this.exportCondition.type = 1;
        break;
    }

    this.policyReminderService.downloadExcel(this.exportCondition)
      .subscribe(
        data => {
          const link = document.createElement('a');
          const blob = new Blob([data.body], {type: 'application/vnd.ms-excel'});
          let fileName = data.headers.get('Content-Disposition').split(';')[1].split('filename=')[1];
          // const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
          // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
          if (fileName) {
            fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
          }
          console.log(fileName);
          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loading = false;
        }, error => {
          const reader = new FileReader();
          reader.readAsText(error.error);
          reader.onload = e => {
            const msg = JSON.parse(e.target['result'] as string);
            this.toastr.warning(msg.message ?? '');
          };
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }
}
