import {Component, OnInit} from '@angular/core';
import {ArticleTypeInfos, BrowseHabitInfo, ConnectionsInfo, SeriesOption, TagsInfos} from '../share-type';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import {TranslateService} from '@ngx-translate/core';
import { EChartsOption } from 'echarts/types/dist/shared';


@Component({
  selector: 'app-share-connections',
  templateUrl: './share-connections.component.html',
  styleUrls: ['./share-connections.component.less']
})
export class ShareConnectionsComponent implements OnInit {
  connectionsInfo: ConnectionsInfo;
  loading: false;
  regionOptions: PropertySelectOption[] = [];
  articleTypeOption: EChartsOption;
  browseHabitOption: EChartsOption;

  constructor(private metadataService: MetadataService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    const regions = this.metadataService.values('articleType');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }
    const dataList: any[] = [];
    const seriesList: any[] = [];
    const xAxisData: any[] = [];
    const borwdata: any[] = [];
    this.connectionsInfo.articleTypeInfos.sort((b: ArticleTypeInfos, a: ArticleTypeInfos): any => {
      return a.browseFrequency - b.browseFrequency;
    }).forEach((value, index, array) => {
      dataList.push(this.articleTypeKeyToValue(value.type));
      xAxisData.push(
        index
      );
      seriesList.push({
        name: this.articleTypeKeyToValue(value.type),
        symbolSize: 20,
        data: [[index, value.browseFrequency]],
        type: 'scatter'
      });
    });
    borwdata.push(this.connectionsInfo.browseHabitInfo['morning']);
    borwdata.push(this.connectionsInfo.browseHabitInfo['forenoon']);
    borwdata.push(this.connectionsInfo.browseHabitInfo['noon']);
    borwdata.push(this.connectionsInfo.browseHabitInfo['afternoon']);
    borwdata.push(this.connectionsInfo.browseHabitInfo['evening']);
    borwdata.push(this.connectionsInfo.browseHabitInfo['night']);
    const data = [
      this.timeTostring('morning'),
      this.timeTostring('forenoon'),
      this.timeTostring('noon'),
      this.timeTostring('afternoon'),
      this.timeTostring('evening'),
      this.timeTostring('night')
    ];
    this.articleTypeOption = this.getArticleTypeOption(dataList, seriesList, xAxisData);
    this.browseHabitOption = this.getBrowseHabitOption(borwdata, data);
  }

  articleTypeKeyToValue(key: string): string {
    for (const value of this.regionOptions) {
      if (key === value.value) {
        return value.label;
      }
    }
    return this.translate.instant('Empty');
  }

  showList(list: ArticleTypeInfos[]): any {
    if (!list || list.length === 0) {
      return this.translate.instant('Empty');
    }
    if (list.length === 1) {
      return this.translate.instant('Empty');
    } else {
      list = list.sort((a: ArticleTypeInfos, b: ArticleTypeInfos): any => {
        return b.browseDuration - a.browseDuration;
      });
      let key: string = null;
      list.slice(0, 2).forEach((e: ArticleTypeInfos) => {
        key = key ? key + '、' + this.articleTypeKeyToValue(e.type) : this.articleTypeKeyToValue(e.type);
      });
      return key;
    }
  }

  showTagsInfos(list: TagsInfos[]): string {
    if (!list || list.length === 0) {
      return this.translate.instant('Empty');
    }
    list = list.sort((a: TagsInfos, b: TagsInfos): any => {
      return b.frequency - a.frequency;
    });
    let key: string = null;
    list.slice(0, 2).forEach((e: TagsInfos) => {
      key = key ? key + '、' + `${e.tag}(${e.frequency})` : `${e.tag}(${e.frequency})`;
    });
    return key;
  }

  timeTostring(key: string): any {
    let result = '';
    this.translate.get(['Morning', 'Afternoon', 'Evening', 'Night', 'Noon', 'Forenoon']).subscribe(
      (res: any) => {
        switch (key) {
          case 'morning':
            result = res.Morning;
            break;
          case 'afternoon':
            result = res.Afternoon;
            break;
          case 'evening':
            result = res.Evening;
            break;
          case 'night':
            result = res.Night;
            break;
          case 'noon':
            result = res.Noon;
            break;
          case 'forenoon':
            result = res.Forenoon;
            break;
        }
      });
    return result;

  }

  browseHabit(list: BrowseHabitInfo): string {
    let browseHabitList: PropertySelectOption[] = [];
    for (const listKey in list) {
      if (list[listKey]) {
        browseHabitList.push(new PropertySelectOption(this.timeTostring(listKey), list[listKey]));
      }
    }
    browseHabitList = browseHabitList.sort((a: PropertySelectOption, b: PropertySelectOption): any => {
      return b.value - a.value;
    });
    let key = null;
    browseHabitList.slice(0, 2).forEach((e: PropertySelectOption) => {
      key = key ? key + '、' + `${e.label}(${e.value})` : `${e.label}(${e.value})`;
    });
    return key;
  }

  getArticleTypeOption(data: string[], seriesList: any[], xAxisData: any[]): EChartsOption {
    return {
      legend: {
        data,
        orient: 'horizontal',
        bottom: 0,
        left: 'center',
      },
      grid: {
        show: false,
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: xAxisData
      },
      yAxis: {},
      series: seriesList,
      label: {
        normal: {
          show: true,
          formatter: ((params) => {
            return params.data.showName;
          })
        }
      }
    };
  }

  getBrowseHabitOption(borwdata: any[], data: any[]): EChartsOption {
    return {
      xAxis: {
        type: 'category',
        data
      },
      grid: {
        show: false,
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      yAxis: {},
      series: [
        {
          data: borwdata,
          type: 'bar'
        }
      ]
    };
  }
}

// export class SeriesOption extends SeriesOption$1 {
//
// }
