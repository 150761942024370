import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DataMode, PagedResp, PendingReply, UploadTokenReq, UploadType } from '../../api/types';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ProposalService } from '../proposal.service';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../shared/service/storage.service';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { DownloadService } from '../../shared/service/download.service';
import { ProposalApplyComponent } from '../proposal-apply/proposal-apply.component';
import { ProposalSearchComponent } from '../proposal-search/proposal-search.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ProposalDetailComponent } from '../proposal-detail/proposal-detail.component';
import { MessageDialogComponent } from '../../shared/component/message-dialog/message-dialog.component';
import { BaseComponent } from '../../base/base.component';
import { DataService } from '../../shared/service/data.service';
import { ProposalListResp, ProposalSearch } from '../proposal-types';
import {WorkbenchService} from '../../workbench/workbench.service';
import { MessageService } from '../../message/message.service';
import { Workbench } from '../../workbench/workbench-types';

@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.less']
})
export class ProposalsComponent extends BaseComponent implements OnInit {

  @Input()
  mode = DataMode.OWN;

  loading = false;

  spinning = false;

  proposals: PagedResp<ProposalListResp>;

  dialogList: PendingReply[];
  uploadTokenReq: UploadTokenReq = new UploadTokenReq();

  search: ProposalSearch = new ProposalSearch();
  workbench: Workbench;
  constructor(
    private proposalService: ProposalService,
    private storageService: StorageService,
    private downloadService: DownloadService,
    private router: Router,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private toastr: ToastrService,
    public dataService: DataService,
    private workbenchService: WorkbenchService,
    private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;

    this.loading = true;
    this.proposalService.list(this.search, this.mode === DataMode.SUBORDINATE)
      .subscribe(
        data => {
          this.loading = false;
          this.proposals = data;
        },
        error => {
          this.loading = false;
        });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.proposalService.list(this.search, this.mode === DataMode.SUBORDINATE)
      .subscribe(
        data => {
          this.loading = false;
          this.proposals = data;
        },
        error => {
          this.loading = false;
        });
  }

  onProposalSearch(): void {
    const drawerRef = this.drawerService.create<ProposalSearchComponent, { value: ProposalSearch }, string>({
      nzWidth: 800,
      nzContent: ProposalSearchComponent,
      nzContentParams: {
        search: this.search,
        mode: this.mode
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.proposalSearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(this.search.pageNum);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onReload(resetPageNum = true): void {
    this.workbenchService.getWorkbench().subscribe(); // 获取是否清理计划书权限
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.proposalService.list(this.search, this.mode === DataMode.SUBORDINATE)
      .subscribe(
        data => {
          this.loading = false;
          this.proposals = data;
        },
        error => {
          this.loading = false;
        });
  }

  onPreview(proposal: ProposalListResp): void {
    if (proposal.url && proposal.url.filePath) {
      this.spinning = true;
      this.storageService.accessToken(proposal.url.filePath)
        .subscribe(
          data => {
            this.spinning = false;
            this.onPreviewPDF(data.accessToken);
          },
          error => {
            this.spinning = false;
          });
    }
  }

  onPreviewPDF(pdfUrl: string): void {

    const drawerRef = this.drawerService.create<PdfViewerComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: PdfViewerComponent,
      nzContentParams: {
        src: pdfUrl,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const pdfViewerComponent = drawerRef.getContentComponent();
      pdfViewerComponent.autoresize = true;
      pdfViewerComponent.showAll = true;
      pdfViewerComponent.originalSize = false;
      pdfViewerComponent.fitToPage = true;
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onDownload(proposal: ProposalListResp): void {
    if (proposal.url && proposal.url.filePath) {
      this.spinning = true;
      this.storageService.accessToken(proposal.url.filePath)
        .subscribe(
          data => {
            this.spinning = false;
            this.downloadService.download(data.accessToken, proposal.url.fileName);
          },
          error => {
            this.spinning = false;
          });
    }
  }

  onLeaveMessage(proposal: ProposalListResp): void {
    const proposalId = proposal.proposalId;
    this.uploadTokenReq.uploadType = UploadType.PROPOSAL;
    this.uploadTokenReq.middleName = proposalId;
    this.translate.get('ProposalDialog').subscribe(
      title => {
        this.proposalService.message(proposalId)
          .subscribe(
            data => {
              this.dialogList = data;
              const drawerRef = this.drawerService.create<MessageDialogComponent, { value: PendingReply }, string>({
                nzWidth: 800,
                nzContent: MessageDialogComponent,
                nzContentParams: {
                  Title: title,
                  list: this.dialogList,
                  uploadTokenReq: this.uploadTokenReq,
                  moduleId: proposalId
                }
              });
              drawerRef.afterOpen.subscribe(() => {
                const component = drawerRef.getContentComponent();
                component.dialogReplied.subscribe(
                  params => {
                    this.proposalService.reply(params).subscribe(
                      () => {
                        this.proposalService.message(proposalId).subscribe(
                          list => {
                            this.dialogList = list;
                            component.loadList(this.dialogList);
                          }
                        );
                      }
                    );
                  }
                );
              });

              drawerRef.afterClose.subscribe(() => {
                this.onReload();
                this.messageService.messageUnreadEvent.emit('');
              });
            }
          );
      }
    );
  }

  onRemarkConfirm(ref: NzModalRef, proposal: ProposalListResp): void {
    ref.destroy();
    // let p = new Proposal();
    // p.id = proposal.id;
    // p.remark = proposal.remark;
    // this.proposalService.update(p)
    //   .subscribe(
    //     data => {
    //       this.spinning = false;
    //       proposal.loading = false;
    //       this.onReload();
    //     },
    //     error => {
    //       proposal.loading = false;
    //       this.spinning = false;
    //     });

  }


  onProposalApply(): void {
    const drawerRef = this.drawerService.create<ProposalApplyComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: ProposalApplyComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.onProductSelection();
      component.drawerRef = drawerRef;
      component.proposalApplied
        .subscribe(data => {
          this.onReload();
        });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onDetail(proposalId: string): void {
    this.spinning = true;
    this.proposalService.info(proposalId)
      .subscribe(
        proposal => {
          this.spinning = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
            component.refreshEmitter.subscribe(
              () => {
                this.onReload(true);
              }
            );
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.onReload(false);
            this.messageService.messageUnreadEvent.emit('');
          });
        },
        error => {
          this.spinning = false;

        });

  }

  onRetry(proposalId: string): void {
    this.loading = true;
    this.proposalService.retry(proposalId)
      .subscribe(
        data => {
          this.loading = false;
          this.onReload(false);
        },
        error => {
          this.loading = false;
        });

  }

  onDelete(proposal: ProposalListResp, index: number, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: proposal,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, proposal: ProposalListResp): void {
    this.loading = true;
    this.proposalService.delete(proposal.proposalId)
      .subscribe(
        data => {
          this.loading = false;
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.onReload(false);
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
    ref.close();
  }

  onStatusHistory(proposal: ProposalListResp, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    const ref = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: true,
      nzClosable: true,
      nzComponentParams: proposal
    });
    ref.afterOpen.subscribe(() => {
      }
    );
  }

  toJson(params: any): string {
    return JSON.stringify(params);
  }
}
